import { API, graphqlOperation } from "aws-amplify"

import { listInstruments } from "../../graphql/queries"

export default async function getInstrumentName() {
  try {
    const result: any = await API.graphql(graphqlOperation(listInstruments))
    return result.data?.listInstruments || {}
  } catch (e) {
    const error = "Error al cargar los instrumentos."
    return error
  }
}
