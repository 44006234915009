import roundToAny from "./roundToAny"

export default function calculateSwapForexCommoditiesMarkets(
  pipValue: number,
  swap: number,
  lotSize: number,
  data: InstrumentData,
  usdValue: number,
  decimal: number
) {
  /**
   * @param  {number} pipValue Valor del pip.
   * @param  {number} swap Swap en instrumento.
   * @param  {number} lotSize Tamaño de lotes.
   * @param  {number} data Datos del instrumento
   * @param  {number} usdValue Valor equivalente para conversión a dolar.
   * @param  {number} decimal Numero de decimales a redondear.
   * @return {number} Valor del swap total.
   *
   * Formula: ( Valor del pip * Swap del instrumento )
   *
   * Calcula el valor del swap total y lo retorna redondeado a
   * un numero de decimales determinado.
   */

  const includeCases = ["GBPUSD", "NZDUSD"]

  if (data.base_currency === "USD" && data.margin_currency === "EUR") {
    return swap * pipValue * lotSize * usdValue
  }
  if (data.base_currency === "CLP" && data.margin_currency === "USD") {
    return swap * pipValue * lotSize
  }
  if (data.base_currency === "CLP" && data.margin_currency === "EUR") {
    return swap * pipValue * lotSize * usdValue
  }
  if (data.margin_currency === "USD" || includeCases.includes(data.nemo)) {
    return roundToAny(swap * pipValue * lotSize, decimal)
  }
  return roundToAny(swap * pipValue * lotSize * usdValue, decimal)
}
