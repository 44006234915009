import roundToAny from "./roundToAny"

export default function getUpDownPrice(
  instrumentPrice: string,
  inputCurrencyValue: string,
  upPrice = true
) {
  inputCurrencyValue = inputCurrencyValue.replace(",", ".").replace("$", "")
  const floatInputCurrencyValue = parseFloat(inputCurrencyValue)
  const floatInstrumentPrice = parseFloat(instrumentPrice)

  if (upPrice) {
    return roundToAny(floatInstrumentPrice + floatInputCurrencyValue, 5)
  } else {
    return roundToAny(floatInstrumentPrice - floatInputCurrencyValue, 5)
  }
}
