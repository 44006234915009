export default function calculateSwapStocksEtfsCrypto(contractValueUsd: number, swap: number) {
  /**
   * @param  {number} contractValueUsd Valor del contrato en USD.
   * @param  {number} swap Swap en instrumento.
   * @param  {number} decimal Numero de decimales a redondear.
   * @return {number} Valor del swap total.
   *
   * Formula: ( ( (Swap del instrumento / 100) * Valor del contrato en USD ) / 360 )
   *
   * Calcula el valor del swap total y lo retorna.
   */

  const totalSwap = ((swap / 100) * contractValueUsd) / 360

  return totalSwap
}
