import roundToAny from "../../helpers/roundToAny"

export default function winLoseMarginCurrecyUsd(
  takeProfitAmount: number,
  stopLossAmount: number,
  usdValue: number
) {
  /**
   * @param  {number} takeProfitAmount Monto de Take Profit.
   * @param  {number} stopLossAmount Monto de Stop Loss.
   * @param  {number} usdValue Valor equivalente en USD.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: TP / USD Value.
   * Formula: SL / USD Value.
   *
   * Calcula la ganancia o perdida de la operación por StopLoss o Take Profit, cuando la moneda
   * de margen es USD, y la retorna redondeada a 2 digitos.
   */
  const winByTakeProfit = roundToAny(takeProfitAmount / usdValue, 2)
  const lossByStopLoss = roundToAny(stopLossAmount / usdValue, 2)

  return { winByTakeProfit, lossByStopLoss }
}
