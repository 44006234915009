import roundToAny from "../../helpers/roundToAny"

export default function calculateDefaultRiskAmount(
  risk: number | undefined | string | string[] | number[],
  balance: string | undefined
) {
  /**
   * @param  {number | undefined | string | string[] | number[]} risk Porcentaje de riesgo.
   * @param  {string | undefined} balance Balance.
   * @return {number} Monto de riesgo.
   *
   * Formula: Riesgo % * Balance
   *
   * Calcula el monto de riesgo.
   */
  const riskLevel = parseFloat(String(risk)) / 100
  const floatBalance = parseFloat(balance || "0")
  const riskAmount = roundToAny(riskLevel * floatBalance, 2)
  return riskAmount
}
