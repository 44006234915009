import getSpreadByCfdValidations from "./getSpreadByCfdValidations"

export default function getSpreadByCfd(data: InstrumentData | undefined, usdClpValue: string) {
  const floatUsdClpValue = parseFloat(usdClpValue)
  let floatSpread = 0
  let floatProSpread = 0
  let floatPremiumSpread = 0

  if (data !== undefined) {
    if (data.spread !== null) {
      floatSpread = parseFloat(String(data.spread.match(/\d+/g)))
    }
    if (data.pro_spread !== null) {
      floatProSpread = parseFloat(String(data.pro_spread.match(/\d+/g)))
      floatPremiumSpread = parseFloat(String(data.premium_spread.match(/\d+/g)))
    }
  }

  const {
    spread,
    spreadClp,
    proSpread,
    proSpreadClp,
    premiumSpread,
    premiumSpreadClp,
  }: SpreadTypes = getSpreadByCfdValidations(
    data,
    floatSpread,
    floatProSpread,
    floatPremiumSpread,
    floatUsdClpValue
  )

  return {
    spread,
    proSpread,
    premiumSpread,
    spreadClp,
    proSpreadClp,
    premiumSpreadClp,
  }
}
