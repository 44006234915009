import getInstrumentData from "./getInstrumentData"

export default async function getInstrumentWhenBaseMarginCurrencyAreDistinctOfUsd(
  data: InstrumentData
) {
  const nemoOptions = ["EURAUD", "EURGBP", "GBPNZD"]
  if (nemoOptions.includes(data.nemo)) {
    return getInstrumentData(`${data.base_currency}USD`)
  } else {
    return getInstrumentData(`USD${data.base_currency}`)
  }
}
