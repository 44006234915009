import styled from "styled-components"

import BlockVector from "../../assets/blockVector"
import MoneyVector from "../../assets/MoneyVector"

const StopLossTakeProfitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 480px;
  margin-top: 32px;
  align-items: center;

  @media (max-width: 424px) {
    width: 100%;
    justify-content: flex-start;
  }
`

const Elipsis = styled.div`
  width: 59px;
  height: 59px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.lose-elipsis {
    background: #ffcac0;
  }
  &.win-elipsis {
    background: #b7ecec;
  }
`

const StopLossTakeProfitTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-left: 12px;
`

const StopLossTakeProfitText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #4e585c;

  &.win-text {
    color: #139392;
  }
  &.lose-text {
    color: #de3618;
  }
  @media (max-width: 424px) {
    font-size: 17px;
  }
`

type Props = {
  currency?: string
  hasErrors: boolean
  winByTakeProfit?: number | string
  lossByStopLoss?: number | string
}

const StopLossTakeProfitContainer = ({
  currency,
  hasErrors,
  winByTakeProfit,
  lossByStopLoss,
}: Props) => {
  let text = ""
  let textClass = ""
  let elipsisClassName = ""
  let vector: JSX.Element
  let value: number | string | undefined = 0
  let winLostMessage = ""
  let winLostText = ""
  const emptyMessage = `$ ${currency}`

  if (lossByStopLoss) {
    text = "Si el precio llega a tu Stop Loss, "
    winLostText = "perderías"
    textClass = "lose-text"
    elipsisClassName = "lose-elipsis"
    value = lossByStopLoss
    vector = <BlockVector />
  } else {
    text = "Si el precio llega a tu Take Profit, "
    winLostText = "ganarías"
    textClass = "win-text"
    elipsisClassName = "win-elipsis"
    value = winByTakeProfit
    vector = <MoneyVector />
  }

  if (Object.is(value, NaN) || value === undefined || value < 0) {
    winLostMessage = emptyMessage
  } else if (!hasErrors) {
    winLostMessage = `$${value} ${currency}`
  } else {
    winLostMessage = emptyMessage
  }

  return (
    <>
      <StopLossTakeProfitWrapper>
        <Elipsis className={elipsisClassName}>{vector}</Elipsis>
        <StopLossTakeProfitTextContainer>
          <StopLossTakeProfitText className={textClass}>{text}</StopLossTakeProfitText>
          <StopLossTakeProfitText>
            {winLostText} {winLostMessage}
          </StopLossTakeProfitText>
        </StopLossTakeProfitTextContainer>
      </StopLossTakeProfitWrapper>
    </>
  )
}

export default StopLossTakeProfitContainer
