const BlockVector = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M10.3974 0C4.80431 0 0.264954 4.48 0.264954 10C0.264954 15.52 4.80431 20 10.3974 20C15.9906 20 20.5299 15.52 20.5299 10C20.5299 4.48 15.9906 0 10.3974 0ZM2.29145 10C2.29145 5.58 5.91888 2 10.3974 2C12.272 2 13.9945 2.63 15.3624 3.69L4.00384 14.9C2.9298 13.55 2.29145 11.85 2.29145 10ZM10.3974 18C8.52293 18 6.80041 17.37 5.43252 16.31L16.791 5.1C17.8651 6.45 18.5034 8.15 18.5034 10C18.5034 14.42 14.876 18 10.3974 18Z"
      fill="#B42006"
    />
  </svg>
)

export default BlockVector
