export default function getInstrumentPrice(
  data: InstrumentData | undefined,
  selectedOperation: string
): string {
  if (data !== undefined) {
    if (selectedOperation === "Compra") {
      return data.sell_price
    }
    return data.buy_price
  }
  return ""
}
