import getInstrumentData from "./getInstrumentData"

export default async function instrumentForWinLoseValueWhenBaseMarginCurrencyAreDisctinctOfUsd(
  data: InstrumentData
) {
  const currencyOptions = ["CAD", "BRL", "JPY", "CLP"]
  if (currencyOptions.includes(data.margin_currency)) {
    return getInstrumentData(`USD${data.margin_currency}`)
  } else {
    return getInstrumentData(`${data.margin_currency}USD`)
  }
}
