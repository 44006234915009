import styled from "@emotion/styled"
import TextField from "@mui/material/TextField"

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#008060",
  },
  "& label.Mui-focused.errors": {
    color: "#de3618!important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#008060",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
    "&.errors fieldset": {
      border: "1px solid #fe836d!important",
    },
    "&:hover fieldset": {
      borderColor: "#008060",
    },
    "&:hover.errors fieldset": {
      borderColor: "#de3618!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#008060",
    },
    "&.Mui-focused.errors fieldset": {
      border: "2px solid #de3618!important",
    },
  },
})

export const StyledTextField = styled(TextField)({
  "& svg.MuiSvgIcon-root.empty-field": {
    color: "#de3618!important",
  },
  "& svg.MuiSvgIcon-root.focused-input": {
    color: "#008060",
  },
  "& label.Mui-focused": {
    color: "#008060",
  },
  "& label.Mui-focused.empty-field": {
    color: "#de3618!important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#008060",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
    "&.empty-field fieldset": {
      border: "1px solid #fe836d!important",
    },
    "&:hover fieldset": {
      borderColor: "#008060",
    },
    "&:hover.empty-field fieldset": {
      borderColor: "#de3618!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#008060",
    },
    "&.Mui-focused.empty-field fieldset": {
      border: "2px solid #de3618!important",
    },
  },
})
