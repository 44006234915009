import styled from "styled-components"
import calculateAmountToDeposit from "../helpers/calculateAmountToDeposit"
import returnedMarginClass from "../helpers/returnedMarginClass"

import calculateMarginLevel from "./helpers/calculateMarginLevel"
import calculateSupportedLoss from "./helpers/calculateSupportedLoss"
import renderStopOutText from "./helpers/renderStopOutText"

const SimulationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SubText = styled.div`
  display: flex;
  width: 235px;
  padding-top: 12px;
  align-items: center;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #637381;

  &.margin-text {
    width: 370px;
  }
  &.base-margin-class {
    justify-content: center;
    width: 370px;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
  }
  &.good-margin {
    color: #44d091;
  }
  &.bad-margin {
    color: #de3618;
  }
  &.regular-margin {
    color: #e6e600;
  }
`

type props = {
  formState: FormValues
}

const SimulationStopOutText = ({ formState }: props) => {
  const { equity, usedMargin } = formState
  const floatEquity = parseFloat(equity || "0")
  const floatUsedMargin = parseFloat(usedMargin || "0")

  const marginLevel = calculateMarginLevel(floatEquity, floatUsedMargin)
  const supportedLoss = calculateSupportedLoss(floatEquity, floatUsedMargin)
  const amountToDeposit = calculateAmountToDeposit(floatEquity, floatUsedMargin)
  const stopOutText = renderStopOutText(marginLevel, supportedLoss, amountToDeposit)
  const baseMarginClass = "base-margin-class"
  const marginClass = returnedMarginClass(marginLevel)

  return (
    <SimulationTextContainer>
      {!Object.is(marginLevel, NaN) ? (
        <SubText className={`${baseMarginClass} ${marginClass}`}>Margen: {marginLevel}%</SubText>
      ) : (
        <SubText className={`${baseMarginClass} ${marginClass}`}>Margen: Indeterminado</SubText>
      )}
      <SubText className="margin-text">{stopOutText}</SubText>
    </SimulationTextContainer>
  )
}

export default SimulationStopOutText
