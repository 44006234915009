import getInstrumentPrice from "./getInstrumentPrice"
import getWinLostClpValue from "./getWinLostClpValue"
import getWinLostUsdValue from "./getWinLostUsdValue"

export default function getWinLostValue(
  data: InstrumentData | undefined,
  inputCurrencyValue: string | undefined,
  currency: string,
  selectedOperation: string,
  contractSize: string | undefined,
  contractValueUSD: string | number,
  values: ContextValues
) {
  const instrumentPrice = getInstrumentPrice(data, selectedOperation)
  let functionExecute: CallableFunction

  if (currency === "USD") {
    functionExecute = getWinLostUsdValue
  } else {
    functionExecute = getWinLostClpValue
  }
  return functionExecute(
    data,
    inputCurrencyValue,
    contractSize,
    contractValueUSD,
    instrumentPrice,
    values
  )
}
