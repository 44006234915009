import calculateTotalSpread from "./calculateTotalSpread"

export default function getTotalSpread(
  spreadTypes: SpreadTypes,
  data: InstrumentData | undefined,
  lotSize: number | undefined
) {
  const specialCases = ["Flotante", "Solo Cierre"]
  let totalSpread: number | string = 0
  let totalProSpread: number | string = 0
  let totalPremiumSpread: number | string = 0
  let totalSpreadClp: number | string = 0
  let totalProSpreadClp: number | string = 0
  let totalPremiumSpreadClp: number | string = 0

  if (data !== undefined && spreadTypes !== undefined && lotSize !== undefined) {
    const volumenInCurrency = parseInt(data.volumen_in_currency.replace(".", ""), 10)
    const spread = Number(spreadTypes.spread)
    const proSpread = Number(spreadTypes.proSpread)
    const premiumSpread = Number(spreadTypes.premiumSpread)
    const spreadClp = Number(spreadTypes.spreadClp)
    const proSpreadClp = Number(spreadTypes.proSpreadClp)
    const premiumSpreadClp = Number(spreadTypes.premiumSpreadClp)

    totalSpread = calculateTotalSpread(spread, volumenInCurrency, lotSize, 4)
    totalSpread = Intl.NumberFormat("en-US").format(totalSpread)

    totalProSpread = calculateTotalSpread(proSpread, volumenInCurrency, lotSize, 4)
    totalProSpread = Intl.NumberFormat("en-US").format(totalProSpread)

    totalPremiumSpread = calculateTotalSpread(premiumSpread, volumenInCurrency, lotSize, 4)
    totalPremiumSpread = Intl.NumberFormat("en-US").format(totalPremiumSpread)

    totalSpreadClp = calculateTotalSpread(spreadClp, volumenInCurrency, lotSize, 0)
    totalSpreadClp = Intl.NumberFormat("en-US").format(totalSpreadClp)

    totalProSpreadClp = calculateTotalSpread(proSpreadClp, volumenInCurrency, lotSize, 0)
    totalProSpreadClp = Intl.NumberFormat("en-US").format(totalProSpreadClp)

    totalPremiumSpreadClp = calculateTotalSpread(premiumSpreadClp, volumenInCurrency, lotSize, 0)
    totalPremiumSpreadClp = Intl.NumberFormat("en-US").format(totalPremiumSpreadClp)

    if (specialCases.includes(spreadTypes.proSpread.toString())) {
      totalProSpread = spreadTypes.proSpread
      totalPremiumSpread = spreadTypes.premiumSpread
      totalProSpreadClp = spreadTypes.proSpreadClp
      totalPremiumSpreadClp = spreadTypes.premiumSpreadClp
    }
    if (specialCases.includes(spreadTypes.spread.toString())) {
      totalSpread = spreadTypes.spread
      totalSpreadClp = spreadTypes.spreadClp
    }
  }
  return {
    totalSpread: totalSpread,
    totalProSpread: totalProSpread,
    totalPremiumSpread: totalPremiumSpread,
    totalSpreadClp: totalSpreadClp,
    totalProSpreadClp: totalProSpreadClp,
    totalPremiumSpreadClp: totalPremiumSpreadClp,
  }
}
