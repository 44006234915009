import roundToAny from "../../helpers/roundToAny"
import getVariationPriceStopLoss from "./getVariationPriceStopLoss"

export default function calculateDefaultStopLoss(entryPrice: string) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @return {number} Valor de Stop Loss por defecto.
   *
   * Formula: Precio de entrada - Variacion de precio SL
   *
   * Calcula el valor por default del Stop Loss.
   */

  const floatEntryPrice = parseFloat(entryPrice.replace(/,/g, ""))
  const stopLossVariation = getVariationPriceStopLoss(entryPrice)

  const defaultStopLoss = roundToAny(floatEntryPrice - stopLossVariation, 4)
  return defaultStopLoss
}
