import styled from "@emotion/styled"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 818px;
  padding-bottom: 22px;
  @media (max-width: 424px) {
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: initial;
    padding-left: 16px;
  }
`

const Column = styled.div`
  text-align: center;
  padding-bottom: 1rem;
  min-width: 239px;
  border: 1px solid #ffffff;
  border-top: 0;
  border-left: 0;
  border-bottom: 0;
  padding-right: 5rem;

  &:last-child {
    border: 0px;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    padding-right: 0px;
  }

  @media (max-width: 424px) {
    display: flex;
    flex-direction: column;
    width: 300px;
    align-items: baseline;
    border: 0px !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    padding: 0px !important;
    margin-top: 38px;
  }
`

const Country = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #f9fafb;
  margin-bottom: 4px;
`

const Field = styled.div`
  display: block;
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  @media (max-width: 424px) {
    text-align: initial;
  }
`

const offices = [
  {
    street: "Av. Vitacura 3565, Oficina 101",
    city: "Santiago",
    region: "Vitacura, Santiago, Chile",
    phone: "+562 25926600",
  },
  {
    street: "Víctor Andrés Belaúnde 147",
    city: "Lima",
    region: "Torre Real 2, San Isidro",
    phone: "+519 4473 7651",
  },
  {
    street: "Av. Dr. Luis Alberto de Herrera 1248, Torre 3, Piso 12",
    city: "Montevideo",
    region: "Montevideo Uruguay",
    phone: "+598 2623 65 63 | +598 2623 66 60",
  },
]

const Offices = () => (
  <Container>
    {offices.map((o, idx) => (
      <Column key={`office-${idx}`}>
        <Country>{o.city}</Country>
        <Field>{o.street}</Field>
        <Field>{o.region}</Field>
        <Field>{o.phone}</Field>
      </Column>
    ))}
  </Container>
)

export default Offices
