export default function calculateContractSize(volumenInCurrency: number, lotSize: number) {
  /**
   * @param  {number} volumenInCurrency Valumen en la moneda.
   * @param  {number} lotSize Tamaño del lote.
   * @return {number} Tamaño del contrato
   *
   * Formula: ( Tamaño del lote * Volumen en la moneda )
   *
   * Calcula el tamaño del contrato y lo retorna redondeado.
   */

  let contractSize = lotSize * volumenInCurrency
  if (contractSize >= 10) {
    contractSize = Math.round(contractSize)
  }

  return contractSize
}
