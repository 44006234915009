import roundToAny from "../../helpers/roundToAny"
import getGainOnTakeProfit from "./getGainOnTakeProfit"

export default function calculateTakeProfitAmount(
  positionSize: number | undefined,
  volumenInCurrency: string,
  entryPrice: string,
  takeProfit: number | undefined
) {
  /**
   * @param  {number | undefined} positionSize Tamaño de Posición.
   * @param  {string} volumenInCurrency Volumen en la Moneda.
   * @param  {string} entryPrice Precio de Entrada.
   * @param  {number | undefined} takeProfit Take Profit.
   * @return {number | undefined} Monto de Take Profit.
   *
   * Formula: Tamaño Posición * Volumen en la moneda * Ganancia en TP

   *
   * Calcula el monto de Take Profit.
   */
  let takeProfitAmount = 0
  if (takeProfit && positionSize) {
    const gainOnTakeProfit = getGainOnTakeProfit(entryPrice, takeProfit)
    const intVolumenInCurrency = parseInt(volumenInCurrency, 10)
    takeProfitAmount = roundToAny(positionSize * intVolumenInCurrency * gainOnTakeProfit, 2)
  }
  return takeProfitAmount
}
