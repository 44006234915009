import roundToAny from "./roundToAny"

export default function calculateLeverage(margin: number) {
  /**
   * @param  {number} margin Margen del instrumento.
   * @return {number} Apalancamiento
   *
   * Formula: ( 100 / Margen del instrumento )
   *
   * Calcula el nivel de apalancamiento.
   */

  const leverage = roundToAny(100 / margin, 2)

  return leverage
}
