import styled from "@emotion/styled"
import Button from "@mui/material/Button"
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined"
import FooterSection from "../ui/Footer"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 1rem;
`

const Title = styled.h2`
  font-family: "Roboto";
  font-style: normal;
  text-align: center;
  font-size: 24px;
  line-height: 116%;
  font-weight: bold;
  margin: 0;
  // color: #ffffff;
`

const CodeError = styled.h1`
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  line-height: 116%;
  margin: 0;
  // color: #ffffff;
`

const Description = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 116%;
  text-align: center;
  // color: #ffffff;
`

const ButtonEm = styled(Button)`
  background-color: #2ba770;
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 320px;
  height: 50px;
  margin-top: 38px;
  padding: 13px, 8px;
  color: #f5f6fa;
  text-transform: none;

  &:hover {
    background-color: #2ba770;
    color: #ffffff;
  }
  &:disabled {
    color: #637381;
    background: #dfe3e8;
  }

  @media (max-width: 424px) {
    width: 250px;
  }
`

const NotFoundPage = () => {
  const onClickButton = () => {
    window.open("/calculadora-basica", "_self", "noopener")
  }
  return (
    <>
      <Container>
        <Title>Lo sentimos, no hay nada por acá.</Title>
        <CodeError>404</CodeError>
        <Description>Sigue navegando por nuestras calculadoras.</Description>
        <ButtonEm
          variant="contained"
          endIcon={<NavigateNextOutlinedIcon fontSize="small" />}
          onClick={onClickButton}
        >
          VOLVER A CALCULADORA BÁSICA
        </ButtonEm>
      </Container>
      <FooterSection />
    </>
  )
}

export default NotFoundPage
