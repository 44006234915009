import styled from "styled-components"
import returnedMarginClass from "../helpers/returnedMarginClass"

import calculateMarginLevel from "./helpers/calculateMarginLevel"
import renderMarginText from "./helpers/renderMarginText"

const SimulationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SubText = styled.div`
  display: flex;
  width: 235px;
  padding-top: 12px;
  align-items: center;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #637381;

  &.margin-text {
    width: 370px;
  }
  &.base-margin-class {
    justify-content: center;
    width: 370px;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
  }
  &.good-margin {
    color: #44d091;
  }
  &.bad-margin {
    color: #de3618;
  }
  &.regular-margin {
    color: #e6e600;
  }
`

type props = {
  formState: FormValues
}

const SimulationMarginText = ({ formState }: props) => {
  const { equity, usedMargin, deposit, withdrawal } = formState
  const floatEquity = parseFloat(equity || "0")
  const floatUsedMargin = parseFloat(usedMargin || "0")
  const floatDeposit = parseFloat(deposit || "0")
  const floatWithdrawal = parseFloat(withdrawal || "0")

  const marginVar: MarginVars = {
    equity: floatEquity,
    depositAmount: floatDeposit,
    withdrawalAmount: floatWithdrawal,
    usedMargin: floatUsedMargin,
  }

  const marginLevel = calculateMarginLevel(marginVar)
  const textInfo = renderMarginText(marginVar, marginLevel)
  const baseMarginClass = "base-margin-class"
  const marginClass = returnedMarginClass(marginLevel)

  return (
    <SimulationTextContainer>
      {!Object.is(marginLevel, NaN) ? (
        <SubText className={`${baseMarginClass} ${marginClass}`}>Margen: {marginLevel}%</SubText>
      ) : (
        <SubText className={`${baseMarginClass} ${marginClass}`}>Margen: Indeterminado</SubText>
      )}
      <SubText className="margin-text">{textInfo}</SubText>
    </SimulationTextContainer>
  )
}

export default SimulationMarginText
