import roundToAny from "./roundToAny"

export default function calculateTotalSpread(
  spreadByCfd: number,
  volumenInCurrency: number,
  lotSize: number,
  decimal: number
) {
  /**
   * @param  {number} spreadByCfd Valor del spread por CFD.
   * @param  {number} volumenInCurrency Volumen en instrumento.
   * @param  {number} lotSize Tamaño del lote.
   * @param  {number} decimal Numero de decimales a redondear.
   * @return {number} Valor total del spread.
   *
   * Formula: ( Valor del spread * Volumen del instrumento * Tamaño del lote )
   *
   * Calcula el valor del total del spread y lo retorna redondeado a
   * un numero de decimales determinado.
   */

  const totalSpread = roundToAny(spreadByCfd * volumenInCurrency * lotSize, decimal)

  return totalSpread
}
