export default function swapConversor(
  formatedSellSwap: number,
  formatedBuySwap: number,
  formatedSellSwapClp: number,
  formatedBuySwapClp: number
) {
  if (!Object.is(Number(formatedSellSwap), NaN) && Number(formatedSellSwap) < 0) {
    formatedSellSwap = Math.abs(Number(formatedSellSwap))
    formatedSellSwapClp = Math.abs(Number(formatedSellSwapClp))
  }
  if (!Object.is(Number(formatedBuySwap), NaN) && Number(formatedBuySwap) < 0) {
    formatedBuySwap = Math.abs(Number(formatedBuySwap))
    formatedBuySwapClp = Math.abs(Number(formatedBuySwapClp))
  }
  return { formatedSellSwap, formatedSellSwapClp, formatedBuySwap, formatedBuySwapClp }
}
