export default function calculateAmountToDeposit(
  equity: number,
  usedMargin: number,
  withdrawalAmount = 0
) {
  /**
   * @param  {number} equity Equidad.
   * @param  {number} withdrawalAmount Monto de rescate.
   * @param  {number} usedMargin Margen utilizado.
   * @constant {number} desiredMargin Margen deseado.
   * @return {number} Cantidad minima a depositar redondeada.
   *
   * Formula: (margen deseado * margen usado) - Equidad + Rescate
   *
   * Calcula la cantidad minima a depositar para mantener un nivel de margen saludable (300%)
   */
  const desiredMargin = 3
  const amountToDeposit = Math.abs(usedMargin * desiredMargin - (equity - withdrawalAmount))

  return amountToDeposit
}
