import calculateMargin from "./calculateMargin"

export default function getMargin(
  contractValueUSD: string | undefined | number,
  contractValueCLP: string | undefined | number,
  data: InstrumentData | undefined
) {
  let requiredMargin: string | number = ""

  if (data !== undefined) {
    const margin = parseFloat(data.margin)

    if (contractValueCLP === undefined && contractValueUSD !== undefined) {
      //eslint-disable-next-line
      contractValueUSD = String(contractValueUSD).replace(/\,/g, "")
      const floatContractValueUSD = parseFloat(contractValueUSD)
      requiredMargin = calculateMargin(floatContractValueUSD, margin, 2)

      if (parseInt(String(requiredMargin)) !== requiredMargin) {
        requiredMargin = Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
        }).format(requiredMargin)
      } else {
        requiredMargin = Intl.NumberFormat("en-US").format(requiredMargin)
      }
    }
    if (contractValueUSD === undefined && contractValueCLP !== undefined) {
      contractValueCLP = String(contractValueCLP).replace(/,/g, "")
      const floatContractValueClp = parseFloat(contractValueCLP)
      requiredMargin = calculateMargin(floatContractValueClp, margin, 0)
      requiredMargin = Intl.NumberFormat("en-US").format(requiredMargin)
    }
  }

  return requiredMargin
}
