import styled from "styled-components"
import RatioButton from "./RatioButton"

const RatioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const RatioText = styled.div`
  display: flex;
  gap: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #4e585c;

  &.good-ratio {
    color: #2ba770;
  }
  &.bad-ratio {
    color: #ffc453;
  }

  @media (max-width: 424px) {
    font-size: 17px;
  }
`

type props = {
  ratio: number | undefined
}

const Ratio = ({ ratio }: props) => {
  return (
    <RatioContainer>
      <RatioText>
        Ratio R/R{" "}
        <RatioText className={ratio && ratio >= 2 ? "good-ratio" : "bad-ratio"}>
          1 : {ratio}
        </RatioText>
      </RatioText>

      <RatioButton ratio={ratio} />
    </RatioContainer>
  )
}

export default Ratio
