import { useEffect, useState } from "react"
import styled from "styled-components"

import CustomToolTip from "../ui/CustomToolTip"

const RatioContainer = styled.div`
  display: flex;
  align-items: center;
`

const RatioComponent = styled.div`
  width: 120px;
  height: 28px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.good-ratio {
    background: #2ba770;
  }
  &.bad-ratio {
    background: #ffc453;
  }
  @media (max-width: 424px) {
  }
`

const RatioText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  &.good-ratio {
    color: #f9fafb;
  }
  &.bad-ratio {
    color: #454f5b;
  }
`

const ToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1px;
  cursor: pointer;
`

type Props = {
  ratio: number | undefined
}

const RatioButton = ({ ratio }: Props) => {
  const componentsProps = {
    popper: { style: { width: "160px" } },
    arrow: { style: { height: "8px", width: "12px", color: "#2ba770" } },
    tooltip: { style: { color: "#F9FAFB", backgroundColor: "#2ba770", opacity: "0.9" } },
  }

  const [ratioData, setRatioData] = useState<RatioData>({
    title: "",
    ratioClass: "",
    toolTipText: "",
    iconProps: {
      width: "",
      height: "",
      color: "",
      marginLeft: "",
    },
  })

  useEffect(() => {
    if (ratio && ratio >= 2) {
      setRatioData({
        title: "Buen ratio",
        ratioClass: "good-ratio",
        toolTipText: `
            El Ratio Risk Reward es la relación entre lo que estas dispuesto a perder y
            lo que quieres ir a ganar. Por ejemplo un ratio 1:2 implica que quieres ganar el
            doble de lo que estas dispuesto a perder, lo cual se considera un buen ratio.
        `,
        iconProps: {
          width: "20px",
          height: "20px",
          color: "#F9FAFB",
          marginLeft: "2px",
        },
      })
    } else {
      setRatioData({
        title: "Ratio Bajo",
        ratioClass: "bad-ratio",
        toolTipText: `
            El Ratio Risk Reward es la relación entre lo que estas dispuesto a perder y
            lo que quieres ir a ganar.

            Por ejemplo un ratio 1:1 implica que quieres ganar lo mismo
            que lo que estas dispuesto a perder, lo cual no se considera un buen ratio.
            Para llegar a un buen ratio, debes aumentar tu Stop Loss
            (disminuir lo que estas dispuesto a perder) o aumentar tu Take Profit.
        `,
        iconProps: {
          width: "20px",
          height: "20px",
          color: "#637381",
          marginLeft: "2px",
        },
      })
    }
  }, [ratio])

  return (
    <RatioContainer>
      <RatioComponent className={ratioData.ratioClass}>
        <RatioText className={ratioData.ratioClass}>{ratioData.title}</RatioText>
        <ToolTipContainer>
          <CustomToolTip
            title={ratioData.toolTipText}
            componentsProps={componentsProps}
            placement="top-start"
            iconProps={ratioData.iconProps}
            iconClass={ratioData.ratioClass}
          />
        </ToolTipContainer>
      </RatioComponent>
    </RatioContainer>
  )
}

export default RatioButton
