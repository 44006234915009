import { ChangeEvent, FocusEvent, useEffect, useState } from "react"
import styled from "@emotion/styled"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"

import isInputValid from "../helpers/isInputValid"

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#008060",
  },
  "& label.Mui-focused.empty-field": {
    color: "#de3618!important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#008060",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
    "&.empty-field fieldset": {
      border: "1px solid #fe836d!important",
    },
    "&:hover fieldset": {
      borderColor: "#008060",
    },
    "&:hover.empty-field fieldset": {
      borderColor: "#de3618!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#008060",
    },
    "&.Mui-focused.empty-field fieldset": {
      border: "2px solid #de3618!important",
    },
  },
})

type props = {
  lotSize: number | undefined
  setLotSize: (value?: number) => void
  data: InstrumentData | undefined
  formControlSx: SxProps<Theme>
  formHelperTextSx: SxProps<Theme>
  label: string
  onChangePosition?: (event: ChangeEvent<any>) => void
  lotSizeAdvanced: boolean
}

const LotSizeInput = ({
  lotSize,
  setLotSize,
  data,
  formControlSx,
  formHelperTextSx,
  label,
  onChangePosition,
  lotSizeAdvanced,
}: props) => {
  const [minLotSizeText, setMinLotSizeText] = useState<string>("Volumen mínimo 0.1")

  const emptyLotSizeClass = `${String(lotSize) === "" ? "empty-field" : undefined}`

  const inputPropsLotSize = {
    type: "text",
    endAdornment: <InputAdornment position="start">lt</InputAdornment>,
    className: emptyLotSizeClass,
    min: "0.1",
    step: "0.1",
  }

  const inputLabelPropsLotSize = {
    className: emptyLotSizeClass,
  }

  const onBlurLotSizeInput = (event: FocusEvent<any>) => {
    const inputValue: string = event.target.value

    if (inputValue.match(/\d\.$/)) {
      const value = event.target.value.slice(0, -1)
      setLotSize(value)
    }
    if (inputValue.match(/^0$|^0\.$/) || !inputValue) {
      setLotSize(0.1)
    }
    if (
      minLotSizeText === "Volumen mínimo 0.01" &&
      (inputValue.match(/^0$|^0\.$|^0\.0$/) || !inputValue)
    ) {
      setLotSize(0.01)
    }
    if (minLotSizeText === "Volumen mínimo 0.01" && !inputValue) {
      setLotSize(0.01)
    }
    if (minLotSizeText === "Volumen mínimo 1" && !inputValue) {
      setLotSize(1)
    }
  }

  const onChangeLotSize = (event: ChangeEvent<any>) => {
    const inputValue: string = event.target.value
    let value: number

    if (minLotSizeText === "Volumen mínimo 0.1") {
      value = 0.1
    } else if (minLotSizeText === "Volumen mínimo 0.01") {
      value = 0.01
    } else {
      value = 1
    }

    if (!isInputValid(inputValue, minLotSizeText)) {
      return
    }

    if (lotSizeAdvanced) {
      value = event.target.value
    }

    if (!inputValue.match(/^0\.0/)) {
      value = event.target.value
    }

    if (minLotSizeText === "Volumen mínimo 0.01" && !inputValue.match(/^0\.00/)) {
      value = event.target.value
    }

    setLotSize(value)
  }

  const onFocusLotSize = (event: React.FocusEvent<any>) => {
    if (minLotSizeText === "Volumen mínimo 1" && event.target.value < 1) {
      setLotSize(1)
    }
  }

  useEffect(() => {
    if (data === undefined) return

    if (data.pro_min_volumen === undefined) return

    const min_volumen: string = data.pro_min_volumen as string
    let lotSizeTemp: string
    /**
     * Si vienen ambos caracteres "." o ",", se elimina el que no este mas a la derecha. Ya que
     * ese indica separador de punto decimal. El caracter que se elimina es separador de miles
     */
    if ([",", "."].every((char) => min_volumen.includes(char))) {
      // Se determina cual caracter de puntuación esta mas a la derecha. El otro, se asigna
      // para eliminar
      const charDelete = min_volumen.lastIndexOf(".") > min_volumen.lastIndexOf(",") ? "," : "."

      // Se elimina el caracter de puntuación que no esta mas a la derecha
      lotSizeTemp = min_volumen.replaceAll(charDelete, "")

      // Si el signo borrado es el . se reemplaza la , por un . para poder hacer la conversión
      // en JS a float
      if (charDelete === ".") {
        lotSizeTemp = lotSizeTemp.replace(",", ".")
      }
    } else if (min_volumen.indexOf(".") !== -1) {
      lotSizeTemp = min_volumen
    } else {
      lotSizeTemp = min_volumen.replace(",", ".")
    }

    setMinLotSizeText(`Volumen mínimo ${lotSizeTemp}`)
    setLotSize(Number(lotSizeTemp))
  }, [data])

  return (
    <FormControl
      sx={formControlSx}
      variant="outlined"
    >
      <CssTextField
        id="outlined-adornment-weight"
        label={label}
        value={lotSize}
        onChange={(event) => {
          onChangeLotSize(event)
          onChangePosition && onChangePosition(event)
        }}
        onBlur={(event) => onBlurLotSizeInput(event)}
        onFocus={(event) => onChangePosition && onFocusLotSize(event)}
        InputProps={inputPropsLotSize}
        InputLabelProps={inputLabelPropsLotSize}
      />
      <FormHelperText
        sx={formHelperTextSx}
        id="outlined-weight-helper-text"
      >
        {minLotSizeText}
      </FormHelperText>
    </FormControl>
  )
}

export default LotSizeInput
