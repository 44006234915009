import winLoseUSDCLPCase from "./winLoseUSDCLPCase"
import winLoseWhenBaseCurrencyIsUsd from "./winLoseWhenBaseCurrencyIsUsd"
import winLoseWhenMarginBaseCurrencyAreDistinctOfUsd from "./winLoseWhenMarginBaseCurrencyAreDistinctOfUsd"
import winLoseWhenMarginBaseCurrencyAreEqualButDistinctToUsd from "./winLoseWhenMarginBaseCurrencyAreEqualButDistinctToUsd"
import winLoseWhenMarginCurrencyIsUsd from "./winLoseWhenMarginCurrencyIsUsd"

export default function getWinLostUsdValue(
  data: InstrumentData,
  inputCurrencyValue: string | undefined,
  contractSize: string | undefined,
  contractValueUSD: string | number,
  instrumentPrice: string,
  values: ContextValues
) {
  let winLoseValue: number | string = 0
  const { usdValueForWinLose } = values

  if (inputCurrencyValue !== undefined && contractSize !== undefined && data !== undefined) {
    inputCurrencyValue = inputCurrencyValue.replace(/,/g, "").replace("$", "")
    contractSize = contractSize.replace(/,/g, "")
    //eslint-disable-next-line
    contractValueUSD = String(contractValueUSD).replace(/\,/g, "")
    const floatInstrumentPrice = parseFloat(instrumentPrice)
    const floatInputCurrencyValue = parseFloat(inputCurrencyValue)
    const floatContractSize = parseFloat(contractSize)
    const floatContractValueUSD = parseFloat(contractValueUSD)

    if (data.margin_currency.trim() === "USD" && data.base_currency.trim() === "CLP") {
      winLoseValue = winLoseUSDCLPCase(
        floatInstrumentPrice,
        floatInputCurrencyValue,
        floatContractSize,
        floatContractValueUSD
      )
    } else if (data.base_currency.trim() === "USD") {
      winLoseValue = winLoseWhenBaseCurrencyIsUsd(
        floatInstrumentPrice,
        floatInputCurrencyValue,
        floatContractSize,
        floatContractValueUSD
      )
    } else if (data.margin_currency.trim() === "USD") {
      winLoseValue = winLoseWhenMarginCurrencyIsUsd(
        floatInstrumentPrice,
        floatInputCurrencyValue,
        floatContractSize,
        floatContractValueUSD
      )
    } else if (data.base_currency.trim() !== "USD" && data.margin_currency.trim() !== "USD") {
      const floatUsdValue = parseFloat(usdValueForWinLose)
      winLoseValue = winLoseWhenMarginBaseCurrencyAreDistinctOfUsd(
        floatInstrumentPrice,
        floatInputCurrencyValue,
        floatContractSize,
        floatUsdValue
      )
      if (data.base_currency.trim() === data.margin_currency.trim()) {
        winLoseValue = winLoseWhenMarginBaseCurrencyAreEqualButDistinctToUsd(
          floatInputCurrencyValue,
          floatContractSize,
          floatUsdValue
        )
      }
    }
  }

  if (Object.is(winLoseValue, NaN)) {
    return winLoseValue
  }

  if (winLoseValue < 0.001) {
    return winLoseValue
  }
  return Intl.NumberFormat("en-US").format(winLoseValue)
}
