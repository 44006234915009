import roundToAny from "../../helpers/roundToAny"
import getLossOnStopLoss from "./getLossOnStopLoss"

export default function calculateStopLossAmount(
  positionSize: number | undefined,
  volumenInCurrency: string,
  entryPrice: string,
  stopLoss: number | undefined
) {
  /**
   * @param  {number | undefined} positionSize Tamaño de Posición.
   * @param  {string} volumenInCurrency Volumen en la Moneda.
   * @param  {string} entryPrice Precio de Entrada.
   * @param  {number | undefined} stopLoss Stop Loss.
   * @return {number | undefined} Monto de Stop Loss.
   *
   * Formula: Tamaño Posición * Volumen en la moneda * Perdida en TP

   *
   * Calcula el monto de Stop Loss.
   */
  let stopLossAmount = 0
  if (stopLoss && positionSize) {
    const lossOnStopLoss = getLossOnStopLoss(entryPrice, stopLoss)
    const intVolumenInCurrency = parseInt(volumenInCurrency, 10)
    stopLossAmount = roundToAny(positionSize * intVolumenInCurrency * lossOnStopLoss, 2)
  }
  return stopLossAmount
}
