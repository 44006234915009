import styled from "styled-components"

import TabsComponent from "./TabsComponent"

const TabWrapper = styled.div`
  @media (max-width: 424px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const DetailText = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: -0.28px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #008060;

  @media (max-width: 424px) {
    padding-left: 15px;
  }
`
const SelectedNemoText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #1a2225;
  padding-top: 23px;

  @media (max-width: 424px) {
    display: none;
    padding-left: 15px;
  }
`

type Props = {
  detailText: string
  tabs: Array<Tabs>
  tabSliderClassName: string
  selectedNemo: string
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
  children?: React.ReactNode
}

const CustomTabComponent = ({
  detailText,
  tabs,
  tabSliderClassName,
  selectedNemo,
  setSelectedTab,
  children,
}: Props) => {
  return (
    <>
      <DetailText>{detailText}</DetailText>
      <SelectedNemoText>{selectedNemo}</SelectedNemoText>
      <TabWrapper>
        <TabsComponent
          tabs={tabs}
          setSelectedTab={setSelectedTab}
          tabSliderClassName={tabSliderClassName}
        />
        {children}
      </TabWrapper>
    </>
  )
}

export default CustomTabComponent
