import { useEffect, useState } from "react"
import styled from "@emotion/styled"

const ContainerSelect = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 17px;
  width: 1041px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 330px;
    padding-top: 10px;
    padding-bottom: 17px;
  }
`

const TitleSelector = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #b42006;
  font-family: "Roboto";
  display: flex;
  align-items: center;
  padding-left: 5px;
  @media (max-width: 424px) {
    align-items: center;
    text-align: center;
    max-width: 330px;
  }
`

type props = {
  data?: InstrumentData
  errorMessage: ErrorMessage
}

const UpdateText = ({ data, errorMessage }: props) => {
  const [returnedText, setReturnedText] = useState<string | undefined>("")

  useEffect(() => {
    if (data !== undefined) {
      setReturnedText(`Información actualizada a ${data.time} de ${data.date}`)
    }
    if (errorMessage.onClickError !== undefined) {
      setReturnedText(errorMessage.onClickError)
    }
    if (errorMessage.serverError !== undefined) {
      setReturnedText(errorMessage.serverError)
    }
  }, [data, errorMessage])

  return (
    <ContainerSelect>
      <TitleSelector>{returnedText}</TitleSelector>
    </ContainerSelect>
  )
}

export default UpdateText
