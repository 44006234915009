const LoseVector = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="LoseVector"
      d="M6.21808 5.37923L10.7229 0.527846L21.535 12.1716L22.3907 11.25L23.8609 16L19.4502 14.4167L20.494 13.2926L10.7227 2.76962L6.21786 7.62101L0.336914 1.28768L1.37784 0.166677L6.21808 5.37923Z"
      fill="#B42006"
    />
  </svg>
)

export default LoseVector
