import styled from "@emotion/styled"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import { ChangeEvent, FocusEvent, useEffect, useState } from "react"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import CurrencyInput from "react-currency-input-field"
import InputAdornment from "@mui/material/InputAdornment"
import { CssTextField } from "./cssStyledTextFields"

const SliderInput = CssTextField

const ErrorContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  gap: 5px;
`

const ErrorText = styled.span`
  color: #de3618;
  line-height: 1.3;
  &.field-error {
    line-height: 1.4;
  }
`

type props = {
  formControlSx?: SxProps<Theme>
  value: string | number | undefined
  currency: string
  handleInputSliderChange: (event: ChangeEvent<any>) => void
  onBlurInputSlider: (event: FocusEvent<any>) => void
  formHelperTextSx?: SxProps<Theme>
  name: string
  balance: string | undefined
  errors: ErrorClass
}

const InputSlider = ({
  formControlSx,
  value,
  currency,
  handleInputSliderChange,
  onBlurInputSlider,
  formHelperTextSx,
  name,
  balance,
  errors,
}: props) => {
  let error = ""
  const [helperText, setHelperText] = useState<string>("")

  if (name === "inputSlider") {
    error = errors.riskError
  }

  const inputProps = {
    type: "text",
    endAdornment: (
      <InputAdornment
        position="start"
        style={{ paddingRight: "0px" }}
      >
        {currency}
      </InputAdornment>
    ),
    className: error,
  }

  const inputLabelProps = {
    className: error,
  }

  useEffect(() => {
    if ((value || 0) > parseFloat(balance || "0")) {
      setHelperText("Riesgo no puede ser mayor que Balance.")
      return
    }
    setHelperText("")
  }, [value, balance])

  return (
    <FormControl
      sx={formControlSx}
      variant="outlined"
    >
      <CurrencyInput
        customInput={SliderInput}
        type="text"
        name={name}
        value={value}
        label={"Monto de Riesgo"}
        onChange={handleInputSliderChange}
        onBlur={onBlurInputSlider}
        groupSeparator=","
        decimalSeparator="."
        allowDecimals={true}
        decimalsLimit={4}
        allowNegativeValue={false}
        maxLength={10}
        className={error}
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <FormHelperText
        sx={formHelperTextSx}
        id="outlined-weight-helper-text"
        className={error}
      >
        <ErrorContainer>
          {error !== "" && <ErrorOutlinedIcon sx={{ fontSize: "1rem", marginTop: "1px" }} />}{" "}
          <ErrorText className="field-error">{helperText}</ErrorText>
        </ErrorContainer>
      </FormHelperText>
    </FormControl>
  )
}

export default InputSlider
