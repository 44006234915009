export default function setHelpertTexts(
  instrumentPrice: string,
  stopLoss: number | undefined,
  takeProfit: number | undefined,
  setHelperTextStopLoss: React.Dispatch<React.SetStateAction<string>>,
  setHelperTextTakeProfit: React.Dispatch<React.SetStateAction<string>>
) {
  if (stopLoss) {
    setHelperTextStopLoss("")
    if (parseFloat(instrumentPrice) < stopLoss) {
      setHelperTextStopLoss("Stop Loss debe ser menor al Precio")
    }
  }
  if (takeProfit) {
    setHelperTextTakeProfit("")
    if (parseFloat(instrumentPrice) > takeProfit) {
      setHelperTextTakeProfit("Take Profit debe ser mayor al Precio")
    }
  }
}
