import React, { useEffect, useState, ChangeEvent, MouseEventHandler } from "react"
import styled from "@emotion/styled"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined"
import FormControl from "@mui/material/FormControl"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Button from "@mui/material/Button"

import CustomToolTip from "../ui/CustomToolTip"
import AutoCompleteNemoInput from "../shared/AutoCompleteNemoInput"
import LotSizeInput from "../shared/LotSizeInput"

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#008060",
  },
  "& label.Mui-focused.empty-field": {
    color: "#de3618!important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#008060",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.12)",
    },
    "&.empty-field fieldset": {
      border: "1px solid #fe836d!important",
    },
    "&:hover fieldset": {
      borderColor: "#008060",
    },
    "&:hover.empty-field fieldset": {
      borderColor: "#de3618!important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#008060",
    },
    "&.Mui-focused.empty-field fieldset": {
      border: "2px solid #de3618!important",
    },
  },
})

const Label = styled(FormLabel)({
  "&.Mui-focused": {
    color: "#4E585C",
  },
  fontFamily: "Roboto",
})

const DirectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const BoxHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 1041px;
  margin: auto;
  background-color: #e0f5f5;
  border-radius: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
`

const TitleSelector = styled.div`
  font-size: 24px;
  font-family: "Roboto";
  color: #008060;
  line-height: 36px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 20px;
  letter-spacing: -0.28px;
`

const ToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4px;
  cursor: pointer;
`

const cssTextFieldSx = {
  width: {
    xs: "379px",
    md: "407px",
  },
  marginLeft: {
    xs: "0px",
    md: "20px",
  },
  "& selected": {
    bgcolor: "#F4F6F8",
  },
  "& hover": {
    bgcolor: "#E0F5F5",
  },
}

const formControlSx = {
  width: {
    xs: "379px",
    md: "150px",
  },
}

const formControlSxOperation = {
  width: {
    xs: "379px",
    md: "200px",
  },
  flexDirection: {
    xs: "row",
    sm: "column",
  },
}

const labelSxOperation = {
  color: "#4E585C",
  textAlign: { xs: "left" },
  clear: { xs: "both" },
  float: { xs: "left" },
  marginTop: { xs: "3px" },
}

const formHelperTextSx = {
  width: "150px",
  marginBottom: {
    xs: "20px",
    md: "0px",
  },
}

const ButtonEm = styled(Button)`
  background-color: #2ba770;
  border-radius: 10px;
  width: 212px;
  height: 50px;
  margin: 5px;
  padding: 13px, 8px;
  color: #f5f6fa;
  font-size: 16px;
  text-transform: none;
  margin-bottom: 20px;
  margin-right: 16px;

  &:hover {
    background-color: #2ba770;
    color: #ffffff;
  }
`

const formControlLabelRadioSx = {
  "&, &.Mui-checked": {
    color: "#2BA770",
  },
}

const customToolTipComponentProps = {
  arrow: { style: { marginLeft: "2px", color: "#2ba770" } },
  tooltip: {
    style: { color: "#F9FAFB", backgroundColor: "#2ba770", opacity: "0.9" },
  },
}

const customToolTipIconProps = {
  width: "19px",
  height: "19px",
  marginLeft: "4px",
  color: "#454f5b",
}

type props = {
  children?: any
  setLotSize: (value?: number) => void
  setSelectedNemo: React.Dispatch<React.SetStateAction<string>>
  setShowDetailInformation: React.Dispatch<React.SetStateAction<boolean>>
  loadInstrumentsData: () => Promise<void>
  setSelectedOperation: React.Dispatch<React.SetStateAction<string>>
  selectedOperation: string
  setShowDataboxInformation: React.Dispatch<React.SetStateAction<boolean>>
  lotSize?: number
  selectedNemo: string
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>
  data?: InstrumentData
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>
}

const BoxBanner = ({
  children,
  setLotSize,
  setSelectedNemo,
  setShowDetailInformation,
  loadInstrumentsData,
  setSelectedOperation,
  selectedOperation,
  setShowDataboxInformation,
  lotSize,
  selectedNemo,
  setErrorMessage,
  data,
  setHasErrors,
}: props) => {
  const [nemoInput, setNemoInput] = useState<string>(" ")

  const handleChangeDirection = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOperation(event.target.value)
  }

  useEffect(() => {
    if (selectedNemo !== " ") {
      loadInstrumentsData()
    }
  }, [selectedNemo])

  const onClickCalculate: MouseEventHandler<HTMLButtonElement> = () => {
    if (
      selectedOperation !== "" &&
      selectedNemo !== " " &&
      selectedNemo === nemoInput &&
      lotSize !== undefined
    ) {
      setShowDetailInformation(true)
      setShowDataboxInformation(true)
      setErrorMessage({ onClickError: undefined })
    } else {
      setShowDetailInformation(false)
      setShowDataboxInformation(false)
      setErrorMessage({ onClickError: "Completa todos los campos correctamente." })
    }
  }

  return (
    <BoxHorizontal className="search-instrument-wrapper">
      <TitleSelector>Buscar Instrumento</TitleSelector>
      {children}
      <Stack
        className="form-wrapper"
        alignItems="center"
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <AutoCompleteNemoInput
          id="free-solo-2-demo"
          CssTextField={CssTextField}
          cssTextFieldSx={cssTextFieldSx}
          setSelectedNemo={setSelectedNemo}
          setNemoInput={setNemoInput}
          nemoInput={nemoInput}
          selectedNemo={selectedNemo}
          setErrorMessage={setErrorMessage}
          setHasErrors={setHasErrors}
        />
        <LotSizeInput
          lotSize={lotSize}
          setLotSize={setLotSize}
          data={data}
          formControlSx={formControlSx}
          formHelperTextSx={formHelperTextSx}
          label="Lotes"
          lotSizeAdvanced={false}
        />
        <FormControl
          sx={formControlSxOperation}
          component="fieldset"
        >
          <DirectionContainer>
            <Label sx={labelSxOperation}>Operación</Label>
            <ToolTipContainer>
              <CustomToolTip
                title="¿Piensas que el precio del instrumento subirá?. Selecciona Compra.
                  ¿Crees que bajará?. Selecciona Venta."
                componentsProps={customToolTipComponentProps}
                placement={"top-start"}
                iconProps={customToolTipIconProps}
                className={{ popper: "custom-tooltip-class" }}
                iconClass="operation-tooltip-icon"
              />
            </ToolTipContainer>
          </DirectionContainer>
          <RadioGroup
            row
            aria-label="direccion"
            name="controlled-radio-buttons-group"
            onChange={handleChangeDirection}
            style={{ marginBottom: "28px" }}
          >
            <FormControlLabel
              value="Compra"
              control={<Radio sx={formControlLabelRadioSx} />}
              label="Compra"
            />
            <FormControlLabel
              value="Venta"
              control={<Radio sx={formControlLabelRadioSx} />}
              label="Venta"
            />
          </RadioGroup>
        </FormControl>
        <ButtonEm
          variant="contained"
          fullWidth
          startIcon={<NavigateNextOutlinedIcon fontSize="small" />}
          onClick={onClickCalculate}
        >
          Calcular Operación
        </ButtonEm>
      </Stack>
    </BoxHorizontal>
  )
}
export default BoxBanner
