const GreenLineVector = () => (
  <svg
    width="27"
    height="3"
    viewBox="0 0 27 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="GreenLineVector"
      d="M0.00195312 0.396667H26.2001V2.16001H0.00195312V0.396667Z"
      fill="#004C3F"
    />
  </svg>
)

export default GreenLineVector
