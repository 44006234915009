import getInstrumentData from "./getInstrumentData"

export default async function getInstrumentWhenMarginBaseCurrencyAreEqualsButDistinctOfUsd(
  data: InstrumentData
) {
  const currencyOptions = ["EUR", "GBP"]
  if (currencyOptions.includes(data.margin_currency)) {
    return getInstrumentData(`${data.margin_currency}USD`)
  } else {
    return getInstrumentData(`USD${data.margin_currency}`)
  }
}
