import calculateLeverage from "./calculateLeverage"

export default function getLeverage(data: InstrumentData | undefined) {
  let leverage: string | number | undefined = ""

  if (data !== undefined && data.margin != undefined) {
    const margin = parseFloat(data.margin)
    leverage = calculateLeverage(margin)
  }
  return leverage
}
