import roundToAny from "../../helpers/roundToAny"

export default function winLoseMarginBaseCurrencyEqualAndDistinctOfUsd(
  takeProfitAmount: number,
  stopLossAmount: number,
  usdValue: number
) {
  /**
   * @param  {number} takeProfitAmount Monto de Take Profit.
   * @param  {number} stopLossAmount Monto de Stop Loss.
   * @param  {number} usdValue Valor equivalente en USD.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: TP * (1/USD) Value.
   * Formula: SL * (1/USD) Value.
   *
   * Calcula la ganancia o perdida de la operación por Stop Loss o Take Profit,
   * cuando la moneda de margen y la moneda base son iguales pero distintas
   * al USD, y la retorna redondeada a 2 digitos.
   */
  const winByTakeProfit = roundToAny(takeProfitAmount * (1 / usdValue), 2)
  const lossByStopLoss = roundToAny(stopLossAmount * (1 / usdValue), 2)
  return { winByTakeProfit, lossByStopLoss }
}
