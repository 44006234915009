import styled from "styled-components"
import getOpenCloseMarketHour from "../helpers/getOpenCloseMarketHour"
import getSpanishDayName from "../helpers/getSpanishDayName"

import SpreadNumberText from "./spreadNumberText"
import SpreadStringText from "./spreadStringText"

const Span = styled.span`
  display: block;
`

const MarketHoursContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 424px) {
    width: 100%;
  }
`

const MarketBox = styled.div`
  min-width: 75px;
  min-height: 50px;
  border: 1px solid rgb(206, 222, 241);
  box-sizing: border-box;
  margin-top: 3px;
  margin-right: 8px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  font-family: "Roboto";
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  padding: 6px 0px;

  &.today {
    border: 1px solid #2ba770;
  }

  @media (max-width: 424px) {
    min-width: 0px;
  }
`

type Props = {
  calculatedOperationValues: CalculatedValues
  data: InstrumentData
  leverage: string | number
  spreadTypes: SpreadTypes
}

const InstrumentText = ({ calculatedOperationValues, data, leverage, spreadTypes }: Props) => {
  const { contractSize } = calculatedOperationValues
  const { proSpread, spread } = spreadTypes
  const { marketHoursByDay } = getOpenCloseMarketHour(data)
  const today = getSpanishDayName()
  const excludeCases = ["Flotante", "Solo Cierre"]

  return (
    <>
      <div>
        <strong>Nombre / NEMO:</strong>
        <Span>
          {data.name} / {data.nemo}
        </Span>
        <p>
          <strong>Tamaño del Instrumento:</strong>
          <Span>
            {contractSize} contratos = {contractSize} CFD
          </Span>
        </p>
        {!excludeCases.includes(String(proSpread)) && !excludeCases.includes(String(spread)) ? (
          <SpreadNumberText
            spreadTypes={spreadTypes}
            data={data}
          />
        ) : (
          <SpreadStringText
            spreadTypes={spreadTypes}
            data={data}
          />
        )}
        <p>
          <strong>Apalancamiento:</strong>
          <Span>x{leverage}</Span>
        </p>
        <p>
          <strong>Margen:</strong>
          <Span>{data.margin}</Span>
        </p>
        <p>
          <strong>SWAP / Rollover:</strong>
          <Span>Venta: {data.sell_swap} (en terminos de valor PIP diario)</Span>
          <Span>Compra: {data.buy_swap} (en terminos de valor PIP diario)</Span>
        </p>
        <Span>
          <strong>Horario Mercado:</strong>
        </Span>
        <MarketHoursContainer>
          {marketHoursByDay?.map(({ day, hours }) => (
            <MarketBox
              key={`idx-box-${day}-${hours}`}
              className={today === day.toLowerCase() ? "today" : ""}
            >
              <strong>{day}</strong>
              {hours}
            </MarketBox>
          ))}
        </MarketHoursContainer>
      </div>
    </>
  )
}

export default InstrumentText
