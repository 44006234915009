export default function lotText(lotSize: number | undefined) {
  if (lotSize === undefined) return ""

  let text = ""
  if (lotSize == 1) {
    text = "lote"
  } else {
    text = "lotes"
  }
  return text
}
