const WinVector = () => (
  <svg
    width="27"
    height="18"
    viewBox="0 0 27 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="WinVector"
      d="M6.54976 11.8282L11.5668 17.2311L23.6079 4.26368L24.561 5.29L26.1983 0L21.2862 1.76333L22.4487 3.01524L11.5665 14.7345L6.54953 9.33156L0 16.3849L1.15927 17.6333L6.54976 11.8282Z"
      fill="#004C3F"
    />
  </svg>
)

export default WinVector
