import { createContext, useContext, useMemo, useState } from "react"

const initState: InstrumentListContext = {
  instrumentList: [],
  setInstrumentList: () => [],
}

const InstrumentListContext = createContext(initState)

function InstrumentListContextProvider({ children }: any) {
  const [instrumentList, setInstrumentList] = useState<InstrumentList[]>([])
  const value = useMemo(
    () => ({ instrumentList, setInstrumentList }),
    [instrumentList, setInstrumentList]
  )

  return <InstrumentListContext.Provider value={value}>{children}</InstrumentListContext.Provider>
}

const useContextInstrumentList = () => useContext(InstrumentListContext)

export { InstrumentListContextProvider, useContextInstrumentList }
