import { useEffect, useState } from "react"
import styled from "@emotion/styled"

import Footer from "../ui/Footer"
import SelectCurrency from "../shared/SelectCurrency"
import BoxBanner from "./horizontalBox"
import CustomDataBox from "../shared/CustomDataBox"
import BoxInformation from "./InformationBox"
import UpdateText from "../shared/UpdateText"
import "./index.css"
import DetailInformation from "./DetailInformation"
import DataBoxInformation from "./DataBoxInformation"
import getContractSize from "../helpers/getContractSize"
import getMargin from "../helpers/getMargin"
import getWinLostValue from "../helpers/getWinLostValue"
import getUsdclpValue from "../helpers/getUsdclpValue"
import getUsdValueForWinLoseValue from "../helpers/getUsdValueForWinLoseValue"
import getUsdValueForContractValue from "../helpers/getUsdValueForContractValue"
import { contextValues } from "../../context/ContextValues"
import CustomHero from "../ui/CustomHero"
import { heroOptions } from "../helpers/heroOptions"
import { dataBoxOptions } from "../helpers/dataBoxOptions"
import loadInstrumentData from "../helpers/loadInstrumentData"
import loadContractsValues from "../helpers/loadContractsValues"

const ContainerCurrency = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 51px;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`

const BasicCalculator = () => {
  const [currency, setCurrency] = useState("CLP")
  const [selectedNemo, setSelectedNemo] = useState<string>(" ")
  const [lotSize, setLotSize] = useState<number | undefined>(0.1)
  const [selectedOperation, setSelectedOperation] = useState<string>("")
  const [data, setData] = useState<InstrumentData | undefined>(undefined)
  const [showDetailInformation, setShowDetailInformation] = useState(false)
  const [showDataboxInformation, setShowDataboxInformation] = useState(false)
  const [inputCurrencyValue, setInputCurrencyValue] = useState<string>("$1")
  const [contractValueCLP, setContractValueCLP] = useState<string | number>("")
  const [contractValueUSD, setContractValueUSD] = useState<string | number>("")
  const [winLostValue, setWinLostValue] = useState<string | number | void>("")
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    serverError: undefined,
    onClickError: undefined,
  })
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [clpValue, setClpValue] = useState<string>("")
  const [usdValueForWinLose, setUsdValueForWinLose] = useState<string>("")
  const [usdValueForContract, setUsdValueForContract] = useState<string>("")
  const [previousData, setPreviousData] = useState<InstrumentData | undefined>(undefined)

  const valuesClpUsd: ContextValues = {
    clpValue: clpValue,
    usdValueForWinLose: usdValueForWinLose,
    usdValueForContract: usdValueForContract,
  }

  const calculatedOperationValues: CalculatedValues = {
    contractSize: getContractSize(lotSize, data),
    usdMargin: getMargin(contractValueUSD, undefined, data),
    clpMargin: getMargin(undefined, contractValueCLP, data),
  }

  const IconLeftRender: React.FunctionComponent<any> = dataBoxOptions().iconLeft
  const IconRightRender: React.FunctionComponent<any> = dataBoxOptions().iconRight
  const HeroImg: React.FunctionComponent<any> = heroOptions().heroImg

  const setSelectedCurrency = (selectedCurrency: string) => {
    setCurrency(selectedCurrency)
  }

  const loadClpValue = async () => {
    if (clpValue.length === 0) {
      const resultClp = await getUsdclpValue(selectedOperation)
      if (resultClp !== undefined) {
        setClpValue(resultClp)
      }
    }
  }

  const loadUsdValues = async () => {
    if (data !== undefined) {
      if (data.margin_currency === "USD") {
        return
      }
      if (
        data.margin_currency === previousData?.margin_currency &&
        data.base_currency === previousData.base_currency
      ) {
        return
      }
      const resultUsd = await getUsdValueForWinLoseValue(selectedOperation, data)
      const resultUsdContract = await getUsdValueForContractValue(selectedOperation, data)

      setUsdValueForWinLose(resultUsd)
      setUsdValueForContract(resultUsdContract)
    }
  }
  useEffect(() => {
    loadClpValue()
  }, [])

  useEffect(() => {
    loadUsdValues()
  }, [data])

  useEffect(() => {
    setPreviousData(data)
  }, [selectedNemo])

  const loadInstrumentsData = async () => {
    await loadInstrumentData(selectedNemo, setErrorMessage, setHasErrors, setData)
  }

  const loadContractValues = async () => {
    await loadContractsValues(
      data,
      selectedOperation,
      calculatedOperationValues.contractSize,
      usdValueForContract,
      clpValue,
      setContractValueUSD,
      setContractValueCLP
    )
  }

  const loadWinLostValue = () => {
    const result = getWinLostValue(
      data,
      inputCurrencyValue,
      currency,
      selectedOperation,
      calculatedOperationValues.contractSize,
      contractValueUSD,
      valuesClpUsd
    )
    setWinLostValue(result)
  }

  return (
    <contextValues.Provider value={{ usdValueForWinLose, usdValueForContract, clpValue }}>
      <CustomHero
        heroTitle={heroOptions().heroTitle}
        text={heroOptions().heroText}
        img={<HeroImg />}
      />
      <ContainerCurrency>
        <SelectCurrency setCurrency={setSelectedCurrency} />
      </ContainerCurrency>
      <BoxBanner
        setLotSize={setLotSize}
        setSelectedNemo={setSelectedNemo}
        setShowDetailInformation={setShowDetailInformation}
        setSelectedOperation={setSelectedOperation}
        loadInstrumentsData={loadInstrumentsData}
        selectedOperation={selectedOperation}
        setShowDataboxInformation={setShowDataboxInformation}
        lotSize={lotSize}
        selectedNemo={selectedNemo}
        setErrorMessage={setErrorMessage}
        data={data}
        setHasErrors={setHasErrors}
      />
      <UpdateText
        data={data}
        errorMessage={errorMessage}
      />
      {showDataboxInformation ? (
        <DataBoxInformation
          selectedOperation={selectedOperation}
          data={data}
          lotSize={lotSize}
          currency={currency}
          inputCurrencyValue={inputCurrencyValue}
          setInputCurrencyValue={setInputCurrencyValue}
          contractValueCLP={contractValueCLP}
          contractValueUSD={contractValueUSD}
          loadContractValues={loadContractValues}
          calculatedOperationValues={calculatedOperationValues}
          winLostValue={winLostValue}
          loadWinLostValue={loadWinLostValue}
          hasErrors={hasErrors}
        />
      ) : (
        <CustomDataBox
          titleLeft={dataBoxOptions().titleLeft}
          titleRight={dataBoxOptions().titleRight}
          textLeft={dataBoxOptions().textLeft}
          textRight={dataBoxOptions().textRight}
          iconLeft={<IconLeftRender />}
          iconRight={<IconRightRender />}
        />
      )}
      {showDetailInformation ? (
        <DetailInformation
          selectedNemo={selectedNemo}
          currency={currency}
          data={data}
          lotSize={lotSize}
          calculatedOperationValues={calculatedOperationValues}
          contractValueCLP={contractValueCLP}
          contractValueUSD={contractValueUSD}
          inputCurrencyValue={inputCurrencyValue}
          selectedOperation={selectedOperation}
          winLostValue={winLostValue}
          hasErrors={hasErrors}
        />
      ) : (
        <BoxInformation />
      )}
      <Footer />
    </contextValues.Provider>
  )
}

export default BasicCalculator
