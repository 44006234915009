import getVariationPriceStopLoss from "./getVariationPriceStopLoss"

export default function getVariationPriceTakeProfit(entryPrice: string) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @return {number} Variación del precio de Take Profit.
   *
   * Formula: Variación del Stop Loss * 2
   *
   * Calcula la variación de precio del Take Profit.
   */

  const stopLossPriceVariation = getVariationPriceStopLoss(entryPrice)

  const variationTakeProfitPrice = stopLossPriceVariation * 2
  return variationTakeProfitPrice
}
