export default function inputIsValid(inputValue: string) {
  const letters = /[a-zA-Z]/
  const startsWithSpecialChar = /^\W/
  const containsDots = /\./g
  const startsWithZeroAndNumber = /^0\d/
  //eslint-disable-next-line
  const containSpecialChars = /[^0-9-,\.]/g
  const moreThanFourDecimals = /\.\d{5}/g

  if (inputValue.match(letters)) return false
  if (inputValue.match(startsWithSpecialChar)) return false
  if (inputValue.match(startsWithZeroAndNumber)) return false
  if (inputValue.match(containSpecialChars)) return false
  const inputContainsDots = inputValue.match(containsDots)
  if (inputContainsDots !== null && inputContainsDots.length > 1) return false
  if (inputValue.match(moreThanFourDecimals)) return false

  return true
}
