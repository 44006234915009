export default function getLossOnStopLoss(entryPrice: string, stopLoss: number | undefined) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @param  {number | undefined} stopLoss Stop Loss.
   * @return {number | undefined} Ganancia en Stop Loss.
   *
   * Formula: (PE - SL)
   *
   * Calcula la pérdida en Stop Loss.
   */
  const floatEntryPrice = parseFloat(entryPrice.replace(/,/g, ""))
  let lossStopLoss = 0
  if (stopLoss) {
    lossStopLoss = floatEntryPrice - stopLoss
  }
  return lossStopLoss
}
