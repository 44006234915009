const roundToAny = (num: number, decimalPlaces = 0): number => {
  if (num < 0) return -roundToAny(-num, decimalPlaces)
  const p = Math.pow(10, decimalPlaces)
  const n = num * p
  const f = n - Math.floor(n)
  const e = Number.EPSILON * n

  return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p
}

export default roundToAny
