import getInstrumentData from "./getInstrumentData"
import instrumentForWinLoseValueWhenBaseMarginCurrencyAreDisctinctOfUsd from "./instrumentForWinLoseValueWhenBaseMarginCurrencyAreDisctinctOfUsd"

export default async function getUsdValueForWinLoseValue(
  selectedOperation: string,
  data: InstrumentData
) {
  let instrument: InstrumentData | undefined = undefined
  if (data.base_currency !== "USD" && data.margin_currency !== "USD") {
    instrument = await instrumentForWinLoseValueWhenBaseMarginCurrencyAreDisctinctOfUsd(data)
  } else if (data.margin_currency === "USD") {
    instrument = await getInstrumentData(`${data.base_currency}USD`)
  } else if (data.base_currency === "USD") {
    instrument = await getInstrumentData(`${data.margin_currency}USD`)
  } else {
    instrument = await getInstrumentData(`USD${data.base_currency}`)
  }

  if (instrument !== undefined) {
    if (selectedOperation === "Compra") {
      return instrument.sell_price
    }
    return instrument.buy_price
  }
  return ""
}
