export default function getPipValue(data: InstrumentData | undefined, swap = false) {
  if (data !== undefined) {
    let floatPipValue = 0
    const subStrings = ["centavo", "penny"]
    if (subStrings.some((substring: string) => data.pip_value.toLowerCase().includes(substring))) {
      floatPipValue = parseFloat(data.pip_value)
      return floatPipValue / 100
    }

    if ([data.margin_currency, data.base_currency].every((currency) => currency === "CLP")) {
      floatPipValue = parseFloat(data.pip_value)
      return floatPipValue / 100
    }

    data.pip_value = data.pip_value.replace(",", ".")
    floatPipValue = parseFloat(data.pip_value)

    if (data.margin_currency === "EUR" && data.base_currency === "USD") {
      floatPipValue = 10 / parseFloat(data.buy_price)
      if (swap) {
        floatPipValue = 1 / parseFloat(data.buy_price)
      }
    }

    if (
      (data.margin_currency === "USD" && data.base_currency === "CLP") ||
      (data.margin_currency === "EUR" && data.base_currency === "CLP")
    ) {
      floatPipValue = 1000 / parseFloat(data.buy_price)
    }
    return floatPipValue
  }
}
