import styled from "styled-components"

import IconMoneySmall from "../../assets/IconMoneySmall"

const SimulationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 100%;
  width: 486px;
  margin-top: 60px;
  margin-bottom: 23px;
  @media (max-width: 424px) {
    width: 97%;
  }
`

const Title = styled.div`
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.28px;
  color: #008060;
  @media (max-width: 424px) {
    width: 95%;
    margin: auto;
  }
`

const SimulationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
  height: 100%;
  width: 486px;
  background: #f9fafb;
  border-radius: 10px;
  margin-top: 3px;
  padding-top: 33px;
  padding-bottom: 33px;
  @media (max-width: 424px) {
    width: 100%;
  }
`

const SubText = styled.div`
  display: flex;
  width: 235px;
  padding-top: 12px;
  align-items: center;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #637381;
`

type props = {
  showSimulationInformation: boolean
  formState: FormValues
  hasErrors: boolean
  children: React.ReactNode
}

const CustomSimulationInformation = ({ showSimulationInformation, hasErrors, children }: props) => {
  return (
    <>
      <SimulationWrapper>
        <Title>Información de la Simulación</Title>
        <SimulationContainer>
          {!showSimulationInformation || hasErrors ? (
            <>
              <IconMoneySmall />
              <SubText>El nivel de Margen y recomendación aparecerán acá</SubText>
            </>
          ) : (
            children
          )}
        </SimulationContainer>
      </SimulationWrapper>
    </>
  )
}

export default CustomSimulationInformation
