export default function getNemo(selectedNemo: string) {
  let nemo = ""
  if (selectedNemo !== undefined) {
    const splittedNemo = selectedNemo.split(" - ")
    if (splittedNemo.length > 2) {
      nemo = splittedNemo[splittedNemo.length - 1]
    } else {
      nemo = splittedNemo[1]
    }
    if (selectedNemo.includes("T.S-MITSU-F")) {
      nemo = "T.S-MITSU-F"
    }
    if (nemo !== undefined) {
      nemo = nemo.trim()
    }
  }
  return nemo
}
