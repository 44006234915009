import { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import CurrencyInput from "react-currency-input-field"

import AlertIcon from "../../assets/iconAlert"
import MarketComponent from "./MarketComponent"
import getLeverage from "../helpers/getLeverage"
import isMarketOpen from "../helpers/isMarketOpen"
import changeMargin from "../helpers/changeMargin"
import getCurrencyShowed from "../helpers/getCurrencyShowed"
import getInstrumentPrice from "../helpers/getInstrumentPrice"
import OperationAnalysis from "./OperationAnalysis"
import WinLoseContainers from "./WinLoseContainer"
import { contextValues } from "../../context/ContextValues"
import DepositHere from "../shared/DepositHere"

const Wrapper = styled.div`
  display: flex;
  margin-top: 15px;
`

const Container = styled.div`
  width: 1041px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 424px) {
    flex-direction: column;
    width: 100%;
  }
`

const ContainerLeft = styled.div`
  display: flex;
  border-radius: 10px;
  width: 510px;
  height: 100%;
  flex-direction: column;
  padding-top: 14px;
  align-items: flex-start;
  text-align: center;
  @media (max-width: 424px) {
    max-width: 345px;
    align-items: center;
    align-items: flex-start;
    padding-left: 15px;
  }
`

const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 510px;
  height: 100%;
  padding-top: 14px;
  align-items: start;
  text-align: center;
  @media (max-width: 424px) {
    max-width: 100%;
  }
`

const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 424px) {
    margin-top: 40px;
  }
`

const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 424px) {
    justify-content: flex-start;
  }
`

const Title = styled.div`
  font-weight: 500;
  color: #008060;
  font-size: 24px;
  line-height: 36px;
  font-family: "Roboto";
  letter-spacing: -0.28px;
  padding-bottom: 5px;
  @media (max-width: 424px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    align-items: center;
    padding-left: 15px;
  }
`

const TextInfo = styled.div`
  width: 480px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flow-root;
  align-items: center;
  text-align: initial;
  color: #4e585c;
  @media (max-width: 424px) {
    max-width: 330px;
  }
`

const InputContainer = styled.div<divProps>`
  position: relative;
  float: left;

  &:after {
    position: absolute;
    right: 0;
    top: 32px;
    padding-right: 10px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #495057;
    content: "${(props: any) =>
      props.currencyShowed != undefined ? `[${props.currencyShowed}]` : null}";
  }
`

const Input = css`
  width: 350px;
  height: 50px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(51, 154, 240, 0.1);
  margin-top: 18px;
  padding-left: 16px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: inline-flex;
  align-items: center;

  &:hover {
    border: 1px solid #008060;
  }
  &:focus {
    border: 2px solid #008060;
  }
  @media (max-width: 424px) {
    max-width: 330px;
  }
`

const InputCurrency = styled(CurrencyInput)`
  ${Input}
`

const WinLoseWrapper = styled.div`
  margin-top: 15px;

  @media (max-width: 424px) {
    display: flex;
    flex-direction: column;
    max-width: 330px;
  }
`

const LastPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 424px) {
    width: 100%;
  }
`

const PriceText = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #008060;

  @media (max-width: 424px) {
    padding-left: 20px;
  }

  &.last-registered-price-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1px;
    color: #637381;
    width: 115px;
    text-align: initial;
    align-items: center;
    padding-left: 8px;
  }
`

const ContainerDetailOperation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 25px;
  padding-left: 7px;
  @media (max-width: 424px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
  }
`

const DetailOperationTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 424px) {
    align-items: baseline;
    width: 92%;
  }
`

const RiskLevelContainer = styled.div`
  display: flex;
  background: #fff0d2;
  border-radius: 4px;
  flex-direction: row;
  margin-top: 19px;
  padding-top: 19px;
  padding-bottom: 19px;

  @media (max-width: 424px) {
    width: 90%;
  }
`

const AlertIconContainer = styled.div`
  width: 52px;
  display: flex;
  justify-content: center;
  padding-top: 10px;

  @media (max-width: 424px) {
    align-items: center;
    padding-bottom: 15px;
  }
`

const RiskLevelText = styled.div`
  width: 85%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: initial;
  color: #b42006;
  @media (max-width: 424px) {
    margin-right: 10px;
    width: 100%;
  }
`

const DepositWrapper = styled.div`
  @media (max-width: 424px) {
    width: 90%;
    display: flex;
  }
`

const DisclaimerWinLoseText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flow-root;
  align-items: center;
  text-align: initial;
  color: #4e585c;
  margin-top: 30px;
`

// prettier-ignore
interface Props extends CommonTypes {
  selectedOperation: string
  currency: string
  inputCurrencyValue: string
  setInputCurrencyValue: React.Dispatch<React.SetStateAction<string>>
  contractValueCLP: string | number
  contractValueUSD: string | number
  calculatedOperationValues: CalculatedValues
  winLostValue: string | number | void
  loadContractValues: () => void
  loadWinLostValue: () => void
  hasErrors: boolean
}

const DataBoxInformation = ({
  selectedOperation,
  data,
  lotSize,
  currency,
  inputCurrencyValue,
  setInputCurrencyValue,
  contractValueCLP,
  contractValueUSD,
  calculatedOperationValues,
  winLostValue,
  loadContractValues,
  loadWinLostValue,
  hasErrors,
}: Props) => {
  const [upText, setUpText] = useState("sube")
  const [downText, setDownText] = useState("baja")
  const [marketIsOpen, setMarketIsOpen] = useState<boolean | undefined>(false)
  const leverage = getLeverage(data)
  const currencyShowed = getCurrencyShowed(data)
  const instrumentPrice = getInstrumentPrice(data, selectedOperation)
  const margin = changeMargin(currency, calculatedOperationValues)
  const { contractSize, usdMargin, clpMargin } = calculatedOperationValues
  const { usdValueForWinLose, usdValueForContract } = useContext(contextValues)
  const depositText = "¿Bajo en Balance?"

  useEffect(() => {
    loadContractValues()
    setMarketIsOpen(isMarketOpen(data))

    if (selectedOperation === "Venta") {
      setUpText("baja")
      setDownText("sube")
    }
    if (selectedOperation === "Compra") {
      setUpText("sube")
      setDownText("baja")
    }
  }, [data, usdValueForContract, lotSize, selectedOperation])

  useEffect(() => {
    loadWinLostValue()
  }, [data, usdValueForWinLose, inputCurrencyValue, contractValueUSD, selectedOperation, currency])

  const handleChangeInputCurrencyValue = (value: string) => {
    setInputCurrencyValue(value)
  }

  return (
    <Wrapper>
      <Container>
        <WrapperLeft>
          <TitleContainer>
            <Title>Escenario</Title>
          </TitleContainer>
          <ContainerLeft>
            {!hasErrors ? (
              <TextInfo>
                Ingresa el valor que crees que podría moverse el precio de{" "}
                <strong>{data?.nemo}</strong> para saber cuánto podrías ganar o perder abriendo una
                operación de <strong>{selectedOperation}</strong>.
              </TextInfo>
            ) : (
              <TextInfo>
                No se pudo cargar la información del instrumento para determinar cuánto podrías
                ganar o perder. Por favor, recarga el navegador e intentalo nuevamente.
              </TextInfo>
            )}

            <InputContainer currencyShowed={currencyShowed}>
              <InputCurrency
                type="text"
                defaultValue="1"
                groupSeparator=","
                decimalSeparator="."
                prefix="$"
                placeholder="$"
                allowNegativeValue={false}
                allowDecimals={true}
                maxLength={10}
                decimalsLimit={4}
                onChange={(event: any) => handleChangeInputCurrencyValue(event.target.value)}
              />
            </InputContainer>
            <WinLoseWrapper>
              <WinLoseContainers
                upText={upText}
                downText={undefined}
                inputCurrencyValue={inputCurrencyValue}
                currency={currency}
                currencyShowed={currencyShowed}
                winLostValue={winLostValue}
                winLostColorTextClassName={"win-text"}
                elipsisClassName={undefined}
                hasErrors={hasErrors}
              />
              <WinLoseContainers
                upText={undefined}
                downText={downText}
                inputCurrencyValue={inputCurrencyValue}
                currency={currency}
                currencyShowed={currencyShowed}
                winLostValue={winLostValue}
                winLostColorTextClassName={"lose-text"}
                elipsisClassName={"lose-elipsis"}
                hasErrors={hasErrors}
              />
              <DisclaimerWinLoseText>
                <strong>*Valores de ganancia o pérdida no incluyen el spread de entrada.</strong>
              </DisclaimerWinLoseText>
            </WinLoseWrapper>
          </ContainerLeft>
        </WrapperLeft>
        <WrapperRight>
          <Title>Análisis de la operación</Title>
          <ContainerRight>
            <LastPriceContainer>
              <PriceText>${instrumentPrice}</PriceText>
              <PriceText className="last-registered-price-text">Precio del Instrumento</PriceText>
              <MarketComponent marketIsOpen={marketIsOpen} />
            </LastPriceContainer>
            <ContainerDetailOperation>
              <DetailOperationTextContainer>
                <OperationAnalysis
                  lotSize={lotSize}
                  contractValueUSD={contractValueUSD}
                  contractValueCLP={contractValueCLP}
                  contractSize={contractSize}
                  usdMargin={usdMargin}
                  clpMargin={clpMargin}
                  hasErrors={hasErrors}
                />
              </DetailOperationTextContainer>
              {!hasErrors ? (
                <RiskLevelContainer>
                  <AlertIconContainer>
                    <AlertIcon />
                  </AlertIconContainer>
                  <RiskLevelText>
                    <strong>Recuerda:</strong> Para operar este instrumento necesitarías al menos $
                    {margin} de margen libre en tu cuenta. Este instrumento te permitiría un
                    apalancamiento x{leverage} veces.
                  </RiskLevelText>
                </RiskLevelContainer>
              ) : null}
              <DepositWrapper>
                <DepositHere text={depositText} />
              </DepositWrapper>
            </ContainerDetailOperation>
          </ContainerRight>
        </WrapperRight>
      </Container>
    </Wrapper>
  )
}

export default DataBoxInformation
