import getContractValueCLP from "./getContractValueCLP"
import getContractValueUSD from "./getContractValueUSD"

export default async function loadContractsValues(
  data: InstrumentData | undefined,
  selectedOperation: string,
  contractSize: string | undefined,
  usdValueForContract: string,
  clpValue: string,
  setContractValueUSD: (value: React.SetStateAction<string | number>) => void,
  setContractValueCLP: (value: React.SetStateAction<string | number>) => void
) {
  const resultContractValueUsd = await getContractValueUSD(
    data,
    selectedOperation,
    contractSize,
    usdValueForContract
  )
  const clpResult = await getContractValueCLP(resultContractValueUsd, clpValue)
  setContractValueUSD(resultContractValueUsd)
  setContractValueCLP(clpResult)
}
