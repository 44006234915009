import { useLocation } from "react-router-dom"

import IconMoney from "../../assets/iconMoney"
import IconSearch from "../../assets/iconSearch"
import CandlesIcon from "../../assets/candlesIcon"
import IconMoneySmall from "../../assets/IconMoneySmall"

export function dataBoxOptions() {
  let textLeft = ""
  let textRight = ""
  let titleLeft = ""
  let titleRight = ""
  let iconLeft: () => JSX.Element = () => <></>
  let iconRight: () => JSX.Element = () => <></>
  const location = useLocation()

  const dataBoxOptions = [
    {
      path: "/basica",
      titleLeft: "Escenario",
      titleRight: "Análisis de la operación",
      textLeft: `
        Acá verás las posibles ganancias o pérdidas que tendrías con esa operación cuando hagas la
        busqueda.
      `,
      textRight: "Mira el análisis de la operación en esta sección cuando hagas la busqueda.",
      iconLeft: IconMoney,
      iconRight: IconSearch,
    },
    {
      path: "/avanzada",
      titleLeft: "Simulación de Riesgo / Recompensa",
      titleRight: "Información de la operación",
      textLeft: `
        Para ver la simulación de la operación debes ingresar primero tu patrimonio
        y el instrumento que quieres operar.
      `,
      textRight:
        "Acá verás todo el detalle de la operación que estarías abriendo con los datos de la simulación.",
      iconLeft: CandlesIcon,
      iconRight: IconMoneySmall,
    },
  ]

  dataBoxOptions.forEach((option) => {
    if (option.path === location.pathname) {
      textLeft = option.textLeft
      textRight = option.textRight
      titleLeft = option.titleLeft
      titleRight = option.titleRight
      iconLeft = option.iconLeft
      iconRight = option.iconRight
    }
  })

  return {
    textLeft,
    textRight,
    titleLeft,
    titleRight,
    iconLeft,
    iconRight,
  }
}
