const MoneyVector = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M6.09032 7.9C3.72203 7.31 2.96043 6.7 2.96043 5.75C2.96043 4.66 4.01416 3.9 5.77733 3.9C7.6344 3.9 8.32298 4.75 8.38558 6H10.6913C10.6182 4.28 9.52277 2.7 7.34228 2.19V0H4.21238V2.16C2.18839 2.58 0.56084 3.84 0.56084 5.77C0.56084 8.08 2.55354 9.23 5.46434 9.9C8.07259 10.5 8.59424 11.38 8.59424 12.31C8.59424 13 8.08302 14.1 5.77733 14.1C3.62814 14.1 2.78307 13.18 2.6683 12H0.373047C0.498243 14.19 2.20925 15.42 4.21238 15.83V18H7.34228V15.85C9.37671 15.48 10.9938 14.35 10.9938 12.3C10.9938 9.46 8.45861 8.49 6.09032 7.9Z"
      fill="#139392"
    />
  </svg>
)

export default MoneyVector
