import { useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { NavLink as Link } from "react-router-dom"

import CapitariaLogo from "../../assets/logo"

const DesktopContainer = styled.div`
  display: flex;
  height: 88px;
  justify-content: space-between;
  align-items: center;
  background-color: #1a2225;
  @media (max-width: 424px) {
    display: none;
  }
  @media (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 1.25rem 2rem;
  }
`

const MobileContainer = styled.div`
  background-color: #1a2225;
  width: 100%;
  display: none;
  @media (max-width: 424px) {
    display: block;
  }
`

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`

const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f2425;
  text-decoration: none;
  padding-right: 0.25rem;
  min-height: 22px;
`

const NavBarWrapper = styled.div`
  width: 1041px;
  height: 34px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 424px) {
    display: none;
  }
`
const Logo = styled(CapitariaLogo)`
  height: 34px;
  width: 157px;
`

const NavMenu = styled.nav`
  position: relative;
  width: 739px;
  align-items: center;

  @media (max-width: 424px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const NavLink = styled(Link)`
  display: inline-block;
  position: relative;
  color: rgba(245, 245, 245, 0.7);
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  border-bottom: 5px solid #1f2425;
  transition: all 0.3s;
  &.active {
    color: #ffffff;
  }
  &:hover {
    color: #ffffff;
  }
  @media (max-width: 424px) {
    display: flex;
    padding: 0 0;
  }
`

type props = {
  listPages: PageProps[]
}

const DesktopNavbar = ({ listPages }: props) => {
  return (
    <DesktopContainer>
      <NavBarWrapper>
        <Logo />
        <NavMenu className="nav-menu">
          {listPages.map((page, idx) => (
            <NavLink
              key={`route-${idx}`}
              to={page.path}
            >
              {page.title}
            </NavLink>
          ))}
          <span className="prod-slide"></span>
        </NavMenu>
      </NavBarWrapper>
    </DesktopContainer>
  )
}

const MobileNavbar = ({ listPages }: props) => {
  const [isOpened, setIsOpened] = useState(false)
  const onClick = () => setIsOpened(!isOpened)
  const wrapperRef = useRef(null)

  const ClickOutside = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpened(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  ClickOutside(wrapperRef)

  return (
    <MobileContainer ref={wrapperRef}>
      <MobileHeader tabIndex={0}>
        <Logo />
        <ToggleButton
          className={isOpened ? "hamburger active" : "hamburger"}
          onClick={onClick}
        >
          <span className="line line-1"></span>
          <span className="line line-2"></span>
          <span className="line line-3"></span>
        </ToggleButton>
      </MobileHeader>
      {isOpened && (
        <NavMenu className="nav-menu">
          {listPages.map((page, idx) => (
            <NavLink
              key={`route-${idx}`}
              to={page.path}
              onClick={onClick}
            >
              {page.title}
            </NavLink>
          ))}
        </NavMenu>
      )}
    </MobileContainer>
  )
}

const Navbar = ({ listPages }: props) => (
  <>
    <DesktopNavbar listPages={listPages} />
    <MobileNavbar listPages={listPages} />
  </>
)

export default Navbar
