import styled from "styled-components"

import DetailText from "./DetailText"
import ExplanationText from "../basic-calculator/ExplanationText"
import InstrumentText from "./instrumentText"

const TabText = styled.div`
  width: 488px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4e585c;
  padding-top: 20px;
`
type Props = {
  selectedTab: string
  lotSize?: number
  calculatedOperationValues: CalculatedValues
  data?: InstrumentData
  margin: string | number
  leverage: string | number
  contractValue: string | number
  currency: string
  currencyShowed?: string
  inputCurrencyValue?: string
  instrumentPrice: string
  upPrice: number
  downPrice: number
  winLostValue?: string | number | void
  spreadTypes: SpreadTypes
  startSpread: number | string
  proSpread: number | string
  premiumSpread: number | string
  sellSwap: number | string
  buySwap: number | string
  descountTextSellSwap: string
  descountTextBuySwap: string
  swapValues: SwapValues
  hasErrors: boolean
  selectedOperation: string
  winLoseValue?: StopLossTakeProfitAmounts
  ratio: number | undefined
}

const SelectedText = (props: Props) => {
  const { hasErrors, data, winLoseValue } = props

  let selectedText: any = {
    0: DetailText,
    1: ExplanationText,
    2: InstrumentText,
  }

  if (winLoseValue) {
    selectedText = {
      0: DetailText,
      1: InstrumentText,
    }
  }

  const RenderTextComponent: React.FunctionComponent<Props> = selectedText[props.selectedTab]

  return (
    <TabText>
      {!hasErrors && data !== undefined ? (
        <RenderTextComponent {...props} />
      ) : (
        "No se pudo cargar la información en detalle."
      )}
    </TabText>
  )
}

export default SelectedText
