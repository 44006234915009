import styled from "@emotion/styled"

import Business from "../../assets/business"

const Wrapper = styled.div`
  display: flex;
  padding-top: 42px;
  padding-bottom: 60px;
`

const Container = styled.div`
  width: 1041px;
  display: flex;
  flex-wrap: wrap;
  background: #e0f5f5;
  border-radius: 10px;
  justify-content: space-evenly;
  margin: 0 auto;
`

const ContainerLeft = styled.div`
  display: flex;
  width: 509px;
  padding: 22px;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 424px) {
    display: none;
  }
`

const ContainerRight = styled.div`
  display: flex;
  width: 402px;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 424px) {
    display: none;
  }
`

const Title = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: bold;
  padding-top: 28px;
  justify-content: space-around;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: #008060;
  @media (max-width: 424px) {
    display: none;
  }
`

const SubText = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  padding-top: 90px;
  color: #637381;
  @media (max-width: 424px) {
    padding: 0 20px;
  }
`

const BoxInformation = () => (
  <Wrapper>
    <Container>
      <ContainerLeft>
        <Business />
      </ContainerLeft>
      <ContainerRight>
        <SubText>
          Busca un instrumento, estudia y define cada punto de tu estrategia como Trader.
        </SubText>
        <Title>No te dejes llevar por las emociones.</Title>
      </ContainerRight>
    </Container>
  </Wrapper>
)

export default BoxInformation
