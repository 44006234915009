import styled from "styled-components"

import BankIcon from "../../assets/bankIcon"

const DepositContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-top: 19px;
  margin-left: 7px;
  align-items: flex-end;

  @media (max-width: 424px) {
    margin-left: 0px;
  }
`

const DepositText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #008060;
  padding-left: 7px;

  &.deposit-here {
    cursor: pointer;
    padding-left: 4px;

    &:hover {
      color: #2ba770;
    }
  }
`

type props = {
  text: string
}

const DepositHere = ({ text }: props) => {
  const clickDeposit = () => {
    window.open("https://www.capitaria.com/depositos/", "_blank", "noopener")
  }
  return (
    <DepositContainer>
      <BankIcon />
      <DepositText>{text}</DepositText>
      <DepositText
        onClick={clickDeposit}
        className="deposit-here"
      >
        Deposita aquí.
      </DepositText>
    </DepositContainer>
  )
}

export default DepositHere
