import getInstrumentPrice from "./getInstrumentPrice"
import roundToTwo from "./roundToTwo"

export default async function getContractValueUSD(
  data: InstrumentData | undefined,
  selectedOperation: string,
  contractSize: string | undefined,
  usdValue: string
) {
  let instrumentPrice: string | number = getInstrumentPrice(data, selectedOperation)
  instrumentPrice = parseFloat(instrumentPrice)
  let contractValueUSD: string | number = 0
  contractSize = contractSize?.replace(/,/g, "") || "0"
  const floatContractSize = parseFloat(contractSize)
  if (data !== undefined) {
    if (data.base_currency.includes("USD") && contractSize != undefined) {
      contractValueUSD = roundToTwo(floatContractSize * instrumentPrice)
    } else if (data.margin_currency === "USD" && contractSize != undefined) {
      contractValueUSD = roundToTwo(floatContractSize)
    } else if (
      data.base_currency !== "USD" &&
      data.margin_currency !== "USD" &&
      contractSize !== undefined
    ) {
      const floatUsdValue = parseFloat(usdValue)
      contractValueUSD = roundToTwo((floatContractSize * instrumentPrice) / floatUsdValue)
    }
  }

  if (isNaN(contractValueUSD)) {
    return contractValueUSD
  }

  if (contractValueUSD !== parseInt(String(contractValueUSD))) {
    return Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    }).format(contractValueUSD)
  } else {
    return Intl.NumberFormat("en-US").format(contractValueUSD)
  }
}
