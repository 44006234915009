import calculateSwapForexCommoditiesMarkets from "./calculateSwapForexCommoditiesMarkets"
import calculateSwapStocksEtfsCrypto from "./calculateSwapStocksEtfsCrypto"
import getPipValue from "./getPipValue"
import roundToAny from "./roundToAny"

export default function getSwap(
  data: InstrumentData | undefined,
  contractValueUsd: string | number,
  usdClpValue: string,
  lotSize: number | undefined,
  usdValueForWinLose: string
) {
  let buySwap: string | number = 0
  let sellSwap: string | number = 0
  let buySwapClp: string | number = 0
  let sellSwapClp: string | number = 0
  const floatUsdClpValue = parseFloat(usdClpValue)
  const floatUsdValue = parseFloat(usdValueForWinLose)
  const pipValue = getPipValue(data, true)
  //eslint-disable-next-line
  contractValueUsd = String(contractValueUsd).replace(/\,/g, "")
  const floatContractValueUsd = parseFloat(contractValueUsd)

  if (
    data !== undefined &&
    pipValue !== undefined &&
    data.buy_swap !== undefined &&
    data.sell_swap !== undefined &&
    lotSize !== undefined
  ) {
    data.buy_swap = data.buy_swap.replace(",", ".")
    data.sell_swap = data.sell_swap.replace(",", ".")

    if (data.buy_swap === "-") {
      data.buy_swap = "0"
    }
    if (data.sell_swap === "-") {
      data.sell_swap = "0"
    }

    const floatBuySwap = parseFloat(data.buy_swap)
    const floatSellSwap = parseFloat(data.sell_swap)

    if (["forex", "commodities"].includes(data.market.trim())) {
      buySwap = calculateSwapForexCommoditiesMarkets(
        pipValue,
        floatBuySwap,
        lotSize,
        data,
        floatUsdValue,
        2
      )
      sellSwap = calculateSwapForexCommoditiesMarkets(
        pipValue,
        floatSellSwap,
        lotSize,
        data,
        floatUsdValue,
        2
      )
      buySwapClp = Math.round(buySwap * floatUsdClpValue)
      sellSwapClp = Math.round(sellSwap * floatUsdClpValue)
    } else {
      if (floatContractValueUsd <= 1.1) {
        buySwap = parseFloat(
          calculateSwapStocksEtfsCrypto(floatContractValueUsd, floatBuySwap).toFixed(6)
        )
        sellSwap = parseFloat(
          calculateSwapStocksEtfsCrypto(floatContractValueUsd, floatSellSwap).toFixed(6)
        )
        buySwapClp = parseFloat((buySwap * floatUsdClpValue).toFixed(4))
        sellSwapClp = parseFloat((sellSwap * floatUsdClpValue).toFixed(4))
      } else {
        buySwap = roundToAny(calculateSwapStocksEtfsCrypto(floatContractValueUsd, floatBuySwap), 4)
        sellSwap = roundToAny(
          calculateSwapStocksEtfsCrypto(floatContractValueUsd, floatSellSwap),
          4
        )
        buySwapClp = Math.round(buySwap * floatUsdClpValue)
        sellSwapClp = Math.round(sellSwap * floatUsdClpValue)
      }
    }
  }

  return {
    buySwap: buySwap,
    sellSwap: sellSwap,
    buySwapClp: buySwapClp,
    sellSwapClp: sellSwapClp,
  }
}
