import { useState } from "react"
import styled from "@emotion/styled"

import chileFlag from "../../assets/chileFlag"
import usaFlag from "../../assets/usaFlag"

const ContainerSelect = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 26px;
  padding-bottom: 13px;
  width: 1041px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 22px 18px;
  }
`

const TitleSelector = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-family: Roboto;
`

const ContainerFlag = styled.div`
  display: flex;
`

const Flag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 4px;
  border: 1px solid transparent;
  height: 29px;
  color: #4e585c;
  border-radius: 5px;
  margin: 0 3.5px;
  cursor: pointer;

  &.selected {
    border: 1px solid #30ab76;
    color: #008060;
  }

  &:hover {
    color: #3b4245;
    &.selected {
      color: #008060;
    }
  }
`

type props = {
  setCurrency: (currency: string) => void
}

const SelectCurrency = ({ setCurrency }: props) => {
  const [selectedCurrency, setSelectedCurrency] = useState("CLP")

  const currencies = [
    {
      name: "CLP",
      flag: chileFlag,
      tag: "CLP",
    },
    {
      name: "USD",
      flag: usaFlag,
      tag: "USD",
    },
  ]

  return (
    <ContainerSelect>
      <TitleSelector>Reflejar los valores en</TitleSelector>
      <ContainerFlag>
        {currencies.map((currency) => (
          <Flag
            className={selectedCurrency === currency.name ? "selected" : ""}
            onClick={() => {
              setSelectedCurrency(currency.name)
              setCurrency(currency.name)
            }}
            key={`idcurrency-${currency.name}`}
          >
            <currency.flag /> {currency.tag}
          </Flag>
        ))}
      </ContainerFlag>
    </ContainerSelect>
  )
}

export default SelectCurrency
