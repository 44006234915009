import { useLocation } from "react-router-dom"

import Trading from "../../assets/trading"
import tradingAdvanced from "../../assets/tradingAdvanced"

export function heroOptions() {
  let heroText = ""
  let heroTitle = ""
  let heroImg: () => JSX.Element = () => <></>
  const location = useLocation()

  const heroOptions = [
    {
      path: "/basica",
      heroText: `
          Analiza toda la información necesaria antes de entrar en una posición en el mercado.
          Mira los posibles escenarios de pérdidas o ganancias.
        `,
      heroTitle: "Calcula tus operaciones de forma fácil y rápida.",
      heroImg: Trading,
    },
    {
      path: "/margen",
      heroTitle: "Simula tu nivel de Margen rapidamente.",
      heroText: `
        Genera diferentes escenarios de margen usado, rescates y/o depósitos según tu equidad, para
        calcular y simular el nivel de margen que tendrías.
      `,
      heroImg: Trading,
    },
    {
      path: "/stopout",
      heroTitle: "Calcula tu StopOut de forma fácil y rápida.",
      heroText: `
      Simula el punto de StopOut, según la equidad y el margen usado,
      viendo cuánto más tendrías que perder para llegar a tu StopOut.
    `,
      heroImg: Trading,
    },
    {
      path: "/avanzada",
      heroTitle: "Simula y calcula una operación facilmente.",
      heroText: `
      Analiza toda la información necesaria antes de entrar en una posición en el mercado.
      Mira los posibles escenarios de pérdidas o ganancias.
    `,
      heroImg: tradingAdvanced,
    },
  ]

  heroOptions.forEach((option) => {
    if (option.path === location.pathname) {
      heroText = option.heroText
      heroTitle = option.heroTitle
      heroImg = option.heroImg
    }
  })

  return { heroText, heroTitle, heroImg }
}
