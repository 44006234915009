import roundToAny from "../../helpers/roundToAny"
import getVariationPriceTakeProfit from "./getVariationPriceTakeProfit"

export default function calculateDefaultTakeProfit(entryPrice: string) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @return {number} Valor de Take Profit por defecto.
   *
   * Formula: Precio de entrada + Variacion de precio TP
   *
   * Calcula el valor por default del Take Profit.
   */

  const floatEntryPrice = parseFloat(entryPrice.replace(/,/g, ""))
  const takeProfitVariation = getVariationPriceTakeProfit(entryPrice)

  const defaultTakeProfit = roundToAny(floatEntryPrice + takeProfitVariation, 4)
  return defaultTakeProfit
}
