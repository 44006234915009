import styled from "styled-components"
import LightSpinner from "../../assets/light-spinner"
import returnedSpreadTexts from "../helpers/returnedSpreadTexts"
import swapConversor from "../helpers/swapConversor"

const Span = styled.span`
  display: block;
`

const DisclaimerText = styled.p`
  font-size: 12px;
`

type Props = {
  lotSize?: number
  calculatedOperationValues: CalculatedValues
  margin: string
  contractValue: string
  currency: string
  currencyShowed?: string
  instrumentPrice: string
  upPrice: number
  downPrice: number
  spreadTypes: SpreadTypes
  swapValues: SwapValues
  totalSpreads: TotalSpreads
  winLostValue?: string | number | void
  data: InstrumentData
  winLoseValue?: StopLossTakeProfitAmounts
  ratio: number | undefined
}

type PropsSpreadComponent = {
  accountType: string
  startSpreadText: string | number
  proSpreadText: string | number
  premiumSpreadText: string | number
  baseCurrency: string
  marginCurrency: string
  totalSpreads: TotalSpreads
}

const SpreadComponent = ({
  accountType,
  startSpreadText,
  proSpreadText,
  premiumSpreadText,
  baseCurrency,
  marginCurrency,
  totalSpreads,
}: PropsSpreadComponent) => {
  if (accountType === "start") {
    return <Span>Start: {startSpreadText}</Span>
  } else {
    if ([marginCurrency, baseCurrency].every((currency) => currency === "CLP")) {
      return (
        <>
          <Span>Pro: ${totalSpreads.totalProSpread} CLP</Span>
          <Span>Premium: ${totalSpreads.totalPremiumSpread} CLP</Span>
        </>
      )
    } else {
      return (
        <>
          <Span>Pro: {proSpreadText}</Span>
          <Span>Premium: {premiumSpreadText}</Span>
        </>
      )
    }
  }
}

const DetailText = ({
  lotSize,
  calculatedOperationValues,
  margin,
  contractValue,
  currency,
  currencyShowed,
  instrumentPrice,
  upPrice,
  downPrice,
  spreadTypes,
  swapValues,
  totalSpreads,
  winLostValue,
  data,
  winLoseValue,
  ratio,
}: Props) => {
  const { contractSize } = calculatedOperationValues
  const { sellSwap, sellSwapClp, buySwap, buySwapClp } = swapValues
  const { account_type, base_currency, margin_currency } = data
  const { winByTakeProfit, lossByStopLoss } = winLoseValue || {}

  console.log("🚀 ~ totalSpreads:", totalSpreads)
  console.log("🚀 ~ spreadTypes:", spreadTypes)

  const { startSpreadText, proSpreadText, premiumSpreadText } = returnedSpreadTexts(
    spreadTypes,
    totalSpreads
  )

  const { formatedBuySwap, formatedBuySwapClp, formatedSellSwap, formatedSellSwapClp } =
    swapConversor(sellSwap, buySwap, sellSwapClp, buySwapClp)

  let displayBuySwap: string | number = formatedBuySwap
  let displaySellSwap: string | number = formatedSellSwap

  if (Math.abs(formatedBuySwap) > 0.1) {
    displayBuySwap = Intl.NumberFormat("en-US").format(formatedBuySwap)
    displaySellSwap = Intl.NumberFormat("en-US").format(formatedSellSwap)
  }

  return (
    <>
      {Object.is(parseFloat(String(margin)), NaN) ? (
        <LightSpinner />
      ) : (
        <div>
          {!winLoseValue ? (
            <>
              <strong>Posibles movimientos de precio:</strong>
              <Span>
                De ${instrumentPrice} a ${upPrice} {currencyShowed} al alza.
              </Span>
              <Span>
                De ${instrumentPrice} a ${downPrice} {currencyShowed} a la baja.
              </Span>
              <p></p>
            </>
          ) : undefined}

          <strong>Tamaño de la Operación:</strong>
          <Span>
            {lotSize} lotes = {contractSize} contratos = ${contractValue} {currency}
          </Span>
          {!winLoseValue ? (
            <p>
              <strong>Ganancia / Pérdida:</strong>
              <Span>
                ${winLostValue} {currency}
              </Span>
            </p>
          ) : (
            <>
              <p>
                <strong> Ganancia hasta tu Take Profit: </strong>
                <Span>
                  ${winByTakeProfit} {currency}
                </Span>
              </p>
              <p>
                <strong> Pérdida hasta tu Stop Loss: </strong>
                <Span>
                  ${lossByStopLoss} {currency}
                </Span>
              </p>
              <p>
                <strong> Ratio Risk Reward: </strong>
                <Span>
                  1 : {ratio} (Dispuesto a perder 1 e ir a ganar {ratio}).
                </Span>
                <Span>Se recomienda un ratio de al menos 2 es a 1.</Span>
              </p>
            </>
          )}
          <p>
            <strong>Spread Operación:</strong>
            <SpreadComponent
              accountType={account_type}
              startSpreadText={startSpreadText}
              proSpreadText={proSpreadText}
              premiumSpreadText={premiumSpreadText}
              baseCurrency={base_currency}
              marginCurrency={margin_currency}
              totalSpreads={totalSpreads}
            />
          </p>
          <p>
            <strong>Margen Utilizado:</strong>
            <Span>${margin}</Span>
          </p>
          <p>
            <strong>SWAP / Rollover</strong>
            {formatedSellSwap !== sellSwap ? (
              <Span>
                Venta diario: -${displaySellSwap} USD = -${" "}
                {Intl.NumberFormat("en-US").format(Number(formatedSellSwapClp))} CLP
              </Span>
            ) : (
              <Span>
                Venta diario: ${displaySellSwap} USD = ${" "}
                {Intl.NumberFormat("en-US").format(Number(sellSwapClp))} CLP
              </Span>
            )}
            {formatedBuySwap !== buySwap ? (
              <Span>
                Compra diario: -${displayBuySwap} USD = -${" "}
                {Intl.NumberFormat("en-US").format(Number(formatedBuySwapClp))} CLP
              </Span>
            ) : (
              <Span>
                Compra diario: ${displayBuySwap} USD = ${" "}
                {Intl.NumberFormat("en-US").format(Number(buySwapClp))} CLP
              </Span>
            )}
          </p>
          {!winLoseValue ? (
            <>
              <DisclaimerText>
                *SWAP x 3 los miercoles o viernes en algunos instrumentos. Para mas información,
                revisar el detalle en Especificaciones de plataforma.
              </DisclaimerText>
              <DisclaimerText>
                *Ganancia o pérdida depende si movimiento es a tu favor o en contra. Restar el
                spread si es a tu favor o sumarlo si es en contra.
              </DisclaimerText>
            </>
          ) : (
            <DisclaimerText>
              *Ganancia o pérdida dependiendo si el movimiento introducido es a tu favor o en
              contra. Restar el spread si el movimiento es a tu favor o sumarlo si es en contra. El
              SL y TP no son recomendaciones, son solo ejemplos. Aspectos Operativos Oficiales se
              ven en plataforma, este sito podría no estar 100% actualizado.
            </DisclaimerText>
          )}
        </div>
      )}
    </>
  )
}

export default DetailText
