import roundToAny from "../../helpers/roundToAny"

export default function calculatePercentRiskLevel(
  lossOnStopLoss: string | number,
  positionSize: number | undefined,
  volumenInCurrency: string,
  balance: string | undefined
) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @param  {number | undefined} stopLoss Stop Loss.
   * @param  {number | undefined} positionSize Tamaño de posición.
   * @param  {string} volumenInCurrency Volumen en la moneda.
   * @param  {string | undefined} balance Balance.
   * @return {number} Porcentaje nivel de riesgo.
   *
   * Formula: (Perdida en Stop Loss * Tamaño Posición *  Volumen en la moneda ) / Balance
   *
   * Calcula el porcentaje del nivel de riesgo.
   */

  const floatBalance = parseFloat(balance || "0")
  volumenInCurrency = volumenInCurrency.replace(".", "")
  const intVolumenInCurrency = parseInt(volumenInCurrency, 10)
  lossOnStopLoss = parseFloat(String(lossOnStopLoss).replace(/,/g, ""))
  let riskLevel = 0

  if (positionSize) {
    riskLevel = roundToAny(
      ((lossOnStopLoss * positionSize * intVolumenInCurrency) / floatBalance) * 100,
      2
    )
  }
  return riskLevel
}
