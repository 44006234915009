import roundToTwo from "../../helpers/roundToTwo"

export default function calculateMarginLevel(equity: number, usedMargin: number): number {
  /**
   * @param  {number} equity Equidad
   * @param  {number} usedMargin Margen utilizado.
   * @return {number} Nivel de margen redondeado a 2 digitos.
   *
   * Formula: Equidad / Margen usado *100
   *
   * Calcula el nivel de margen y lo retorna redondeado.
   */

  const marginLevel = roundToTwo((equity / usedMargin) * 100)

  return marginLevel
}
