import { useContext, useEffect, useState } from "react"
import styled from "styled-components"

import changeMargin from "../helpers/changeMargin"
import { contextValues } from "../../context/ContextValues"
import getCurrencyShowed from "../helpers/getCurrencyShowed"
import getLeverage from "../helpers/getLeverage"
import getSpreadByCfd from "../helpers/getSpreadByCfd"
import getSwap from "../helpers/getSwap"
import getTotalSpread from "../helpers/getTotalSpread"
import getUpDownPrice from "../helpers/getUpDownPrice"
import SelectedText from "./SelectedText"

const TabContent = styled.div`
  width: 509px;
  display: flex;
  justify-content: center;
  @media (max-width: 424px) {
    width: 97%;
  }
`

type Props = {
  selectedTab: string
  currency: string
  data?: InstrumentData
  lotSize?: number
  calculatedOperationValues: CalculatedValues
  contractValueCLP: string | number
  contractValueUSD: string | number
  inputCurrencyValue?: string
  selectedOperation: string
  instrumentPrice: string
  winLostValue?: string | number | void
  hasErrors: boolean
  winLoseValue?: StopLossTakeProfitAmounts
  ratio?: number
}

const initialState = {
  spread: 0,
  proSpread: 0,
  premiumSpread: 0,
  spreadClp: 0,
  proSpreadClp: 0,
  premiumSpreadClp: 0,
}

const initialStateTotalSpread = {
  totalSpread: 0,
  totalProSpread: 0,
  totalPremiumSpread: 0,
  totalSpreadClp: 0,
  totalProSpreadClp: 0,
  totalPremiumSpreadClp: 0,
}

const initialSwapValues = {
  buySwap: 0,
  sellSwap: 0,
  buySwapClp: 0,
  sellSwapClp: 0,
}

const SelectedTabText = ({
  selectedTab,
  currency,
  data,
  lotSize,
  calculatedOperationValues,
  contractValueCLP,
  contractValueUSD,
  inputCurrencyValue,
  selectedOperation,
  instrumentPrice,
  winLostValue,
  hasErrors,
  winLoseValue,
  ratio,
}: Props) => {
  const leverage = getLeverage(data)
  const margin = changeMargin(currency, calculatedOperationValues)
  const [contractValue, setContractValue] = useState<string | number>(contractValueCLP)
  const currencyShowed = getCurrencyShowed(data)
  const [spreadTypes, setSpreadTypes] = useState<SpreadTypes>(initialState)
  const [totalSpreads, setTotalSpreads] = useState<TotalSpreads>(initialStateTotalSpread)
  const [startSpread, setStartSpread] = useState<number | string>(totalSpreads.totalSpread)
  const [proSpread, setProSpread] = useState<number | string>(totalSpreads.totalProSpread)
  const [premiumSpread, setPremiumSpread] = useState<number | string>(
    totalSpreads.totalPremiumSpread
  )
  const [swapValues, setSwapValues] = useState<SwapValues>(initialSwapValues)
  const [buySwap, setBuySwap] = useState<number | string>(swapValues.buySwap)
  const [sellSwap, setSellSwap] = useState<number | string>(swapValues.sellSwap)
  const [descountTextSellSwap, setDescountTextSellSwap] = useState<string>("te descuenta")
  const [descountTextBuySwap, setDescountTextBuySwap] = useState<string>("te descuenta")
  const { clpValue, usdValueForWinLose } = useContext<ContextValues>(contextValues)

  let upPrice = 0
  let downPrice = 0
  if (inputCurrencyValue) {
    upPrice = getUpDownPrice(instrumentPrice, inputCurrencyValue)
    downPrice = getUpDownPrice(instrumentPrice, inputCurrencyValue, false)
  }

  const loadSpreadTypes = () => {
    const result = getSpreadByCfd(data, clpValue)
    setTotalSpreads(getTotalSpread(result, data, lotSize))
    return setSpreadTypes(result)
  }

  const loadSwapValues = () => {
    const result = getSwap(data, contractValueUSD, clpValue, lotSize, usdValueForWinLose)
    setSwapValues(result)
  }

  useEffect(() => {
    loadSpreadTypes()
    loadSwapValues()
  }, [data, selectedOperation, lotSize, contractValueUSD, usdValueForWinLose])

  useEffect(() => {
    swapValues.sellSwap < 0
      ? setDescountTextSellSwap("te descuenta")
      : setDescountTextSellSwap("te abona")

    swapValues.buySwap < 0
      ? setDescountTextBuySwap("te descuenta")
      : setDescountTextBuySwap("te abona")
  }, [data, selectedOperation, sellSwap, buySwap])

  useEffect(() => {
    if (currency === "USD") {
      setContractValue(contractValueUSD)
      setStartSpread(totalSpreads.totalSpread)
      setProSpread(totalSpreads.totalProSpread)
      setPremiumSpread(totalSpreads.totalPremiumSpread)
      setBuySwap(swapValues.buySwap)
      setSellSwap(swapValues.sellSwap)
    } else {
      setContractValue(contractValueCLP)
      setStartSpread(totalSpreads.totalSpreadClp)
      setProSpread(totalSpreads.totalProSpreadClp)
      setPremiumSpread(totalSpreads.totalPremiumSpreadClp)
      setBuySwap(swapValues.buySwapClp)
      setSellSwap(swapValues.sellSwapClp)
    }
  }, [currency, spreadTypes, swapValues, contractValueCLP, data, selectedOperation])

  return (
    <TabContent>
      <SelectedText
        {...{
          selectedTab,
          lotSize,
          calculatedOperationValues,
          data,
          margin,
          leverage,
          contractValue,
          currency,
          currencyShowed,
          inputCurrencyValue,
          instrumentPrice,
          upPrice,
          downPrice,
          winLostValue,
          spreadTypes,
          startSpread,
          proSpread,
          premiumSpread,
          sellSwap,
          buySwap,
          descountTextSellSwap,
          descountTextBuySwap,
          swapValues,
          hasErrors,
          selectedOperation,
          totalSpreads,
          winLoseValue,
          ratio,
        }}
      />
    </TabContent>
  )
}

export default SelectedTabText
