export default function getCurrencyShowed(data: InstrumentData | undefined) {
  if (
    data !== undefined &&
    data.base_currency !== undefined &&
    data.margin_currency !== undefined
  ) {
    if (data.base_currency === "") {
      return undefined
    }
    return data.base_currency
  }
  return undefined
}
