import getTakeProfitStopLossClpValue from "./getTakeProfitStopLossClpValue"
import getTakeProfitStopLossUsdValue from "./getTakeProfitStopLossUsdValue"

export default function getStopLossTakeProfitValue(
  data: InstrumentData,
  values: ContextValues,
  takeProfitAmount: number,
  stopLossAmount: number,
  currency: string
) {
  let functionExecute: CallableFunction

  if (currency === "USD") {
    functionExecute = getTakeProfitStopLossUsdValue
  } else {
    functionExecute = getTakeProfitStopLossClpValue
  }
  return functionExecute(data, values, takeProfitAmount, stopLossAmount)
}
