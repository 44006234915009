import styled from "styled-components"
import Button from "@mui/material/Button"
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"
import { ChangeEvent, FocusEvent, MouseEventHandler, useEffect, useState } from "react"
import CurrencyInput from "react-currency-input-field"

import CustomKnowMore from "./CustomKnowMore"
import renderInputLabelProps from "../helpers/renderInputLabelProps"
import renderPropsInput from "../helpers/renderPropsInput"
import { StyledTextField } from "./cssStyledTextFields"

const SimulatorContainer = styled.div`
  display: flex;
  height: 100%;
  width: 1040px;
  border-radius: 10px;
  background: #f9fafb;
  margin: auto;
  margin-top: 30px;

  @media (max-width: 424px) {
    width: 97%;
  }
`

const DataContainer = styled.div`
  width: 485px;
  margin: auto;
  padding-top: 24px;
  @media (max-width: 424px) {
    width: 95%;
  }
`

const Title = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.2800000011920929px;
  text-align: left;
  display: flex;
  align-items: center;
  letter-spacing: -0.28px;
  color: #008060;
`

const SubText = styled.div`
  display: block;
  padding-top: 24px;
  padding-bottom: 10px;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4e585c;
`

const cssTextFieldSx = {
  width: {
    xs: "100%",
    md: "100%",
  },
  marginLeft: {
    xs: "0px",
    md: "0px",
  },
  marginTop: {
    xs: "15px",
    md: "20px",
  },
  "& selected": {
    bgcolor: "#F4F6F8",
  },
  "& hover": {
    bgcolor: "#E0F5F5",
  },
}

const ButtonEm = styled(Button)`
  background-color: #2ba770;
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: 50px;
  margin-top: 38px;
  padding: 13px, 8px;
  color: #f5f6fa;
  text-transform: none;

  &:hover {
    background-color: #2ba770;
    color: #ffffff;
  }
  &:disabled {
    color: #637381;
    background: #dfe3e8;
  }
`

const DisclaimerText = styled.div`
  width: 100%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #919eab;
  margin: auto;
  margin-top: 34px;
  margin-bottom: 35px;
`

const ErrorContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  gap: 5px;

  &.all-fields-error {
    margin-top: 25px;
    justify-content: center;
  }
`

const ErrorText = styled.span`
  color: #de3618;
  line-height: 1.3;
  &.field-error {
    line-height: 1.4;
  }
`

type props = {
  title: string
  subText: string
  knowMoreUrl: string
  buttonText: string
  formState: FormValues
  setFormState: React.Dispatch<React.SetStateAction<FormValues>>
  setShowSimulationInformation: React.Dispatch<React.SetStateAction<boolean>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  errorMessage: string
  hasErrors: boolean
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>
}

const CustomSimulator = ({
  title,
  subText,
  knowMoreUrl,
  buttonText,
  formState,
  setFormState,
  setShowSimulationInformation,
  setErrorMessage,
  errorMessage,
  hasErrors,
  setHasErrors,
}: props) => {
  const [errors, setErrors] = useState<string[]>([])
  const [focusInput, setFocusInput] = useState<string[]>([])
  const excludeKeys = ["deposit", "withdrawal"]
  const { equity, usedMargin } = formState

  const placeholders = {
    equity: "Ingresa tu Equidad",
    usedMargin: "Ingresa tu Margen Utilizado",
    deposit: "Ingresa Monto a Depositar",
    withdrawal: "Ingresa Monto a Rescatar",
  }

  const labels = {
    equity: "Equidad",
    usedMargin: "Margen Utilizado",
    deposit: "Depósito",
    withdrawal: "Rescate",
  }

  const handleInputChange = (event: ChangeEvent<any>) => {
    const inputValue: string = event.target.value

    if (inputValue === "" || inputValue === undefined) {
      if (!errors.includes(event.target.name) && !excludeKeys.includes(event.target.name)) {
        setErrors([...errors, event.target.name])
      }
      setFormState({ ...formState, [event.target.name]: "" })
    } else {
      setErrors(errors.filter((error: string) => error !== event.target.name))
    }
  }

  useEffect(() => {
    if (errors.length < 1) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
      setErrorMessage("Completa los campos correctamente.")
    }
  }, [errors])

  const onBlurInput = (event: FocusEvent<any>) => {
    const inputValue: string = event.target.value

    if (inputValue.match(/\d\.$/)) {
      setFormState({
        ...formState,
        [event.target.name]: inputValue.replace(/\.$/, "").replace(/,/g, ""),
      })
    }

    setFocusInput(focusInput.filter((focus: string) => focus !== event.target.name))

    if (inputValue.match(/^\./)) {
      setFormState({
        ...formState,
        [event.target.name]: inputValue.replace(/\./, "").replace(/,/g, ""),
      })
    }
  }

  const onFocusInput = (event: FocusEvent<any>) => {
    const inputValue = event.target.name

    if (!focusInput.includes(inputValue)) {
      setFocusInput([...focusInput, inputValue])
    }
  }

  const onClickCalculate: MouseEventHandler<HTMLButtonElement> = () => {
    const errorList: string[] = []

    Object.keys(formState).forEach((key: string) => {
      const keyName = key as keyof FormValues
      if (!excludeKeys.includes(key)) {
        if (formState[keyName] === "" || formState[keyName] === undefined) {
          errorList.push(keyName)
        }
      }
    })
    setErrors(errorList)

    if (equity !== "" && usedMargin !== "") {
      setShowSimulationInformation(true)
      setErrorMessage("")
      setHasErrors(false)
    } else {
      setShowSimulationInformation(false)
      setErrorMessage("Completa los campos correctamente.")
      setHasErrors(true)
    }
  }

  return (
    <SimulatorContainer>
      <DataContainer>
        <Title>{title}</Title>
        <SubText>
          {subText} <CustomKnowMore url={knowMoreUrl} />
        </SubText>
        {Object.keys(formState).map((key, idx) => (
          <CurrencyInput
            key={`input-${idx}`}
            customInput={StyledTextField}
            placeholder={placeholders[key as keyof FormValues]}
            name={key}
            value={formState[key as keyof FormValues]}
            label={labels[key as keyof FormValues]}
            sx={cssTextFieldSx}
            InputProps={renderPropsInput(errors.includes(key), focusInput.includes(key))}
            InputLabelProps={renderInputLabelProps(errors.includes(key))}
            error={errors.includes(key)}
            helperText={
              errors.includes(key) ? (
                <ErrorContainer>
                  <ErrorOutlinedIcon sx={{ fontSize: "1rem" }} />{" "}
                  <ErrorText className="field-error">Campo Requerido</ErrorText>
                </ErrorContainer>
              ) : undefined
            }
            onChange={(event) => handleInputChange(event)}
            onValueChange={(value: string | undefined) =>
              setFormState({ ...formState, [key]: value })
            }
            onBlur={onBlurInput}
            onFocus={onFocusInput}
            groupSeparator=","
            decimalSeparator="."
            allowNegativeValue={false}
            allowDecimals={true}
            decimalsLimit={2}
            type="text"
          />
        ))}
        <ButtonEm
          variant="contained"
          fullWidth
          endIcon={<NavigateNextOutlinedIcon fontSize="small" />}
          onClick={onClickCalculate}
          disabled={hasErrors}
        >
          {buttonText}
        </ButtonEm>
        {hasErrors && (
          <ErrorContainer className="all-fields-error">
            <ErrorOutlinedIcon
              sx={{ fontSize: "larger" }}
              color="error"
            />
            <ErrorText>{errorMessage}</ErrorText>
          </ErrorContainer>
        )}
        <DisclaimerText>
          Todos los valores reflejados son aproximados. Se deben interpretar solamente como
          sugerencias al momento de considerarlos en la toma de decisiones. A la hora de operar con
          activos financieros, los posibles valores de pérdida o ganancia que pudieran obtener al
          operar en la plataforma MetaTrader5, serán de exclusiva responsabilidad del cliente.
        </DisclaimerText>
      </DataContainer>
    </SimulatorContainer>
  )
}

export default CustomSimulator
