import roundToAny from "./roundToAny"

export default function calculateMargin(contractValue: number, margin: number, decimal: number) {
  /**
   * @param  {number} contractValue Valor del contrato.
   * @param  {number} margin Porcentaje de margen.
   * @param  {number} decimal Numero de decimales a redondear.
   * @return {number} Valor del margen segun el tamaño del contrato.
   *
   * Formula: ( (Valor del contrato) * (Margen / 100) )
   *
   * Calcula el valor del margen según el tamaño del contrato y lo retorna redondeado a
   * un numero de decimales determinado.
   */

  const requiredMargin = roundToAny(contractValue * (margin / 100), decimal)

  return requiredMargin
}
