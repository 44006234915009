import { useContext, useEffect, useState } from "react"
import { contextValues } from "../../context/ContextValues"
import Button from "@mui/material/Button"
import CurrencyInput from "react-currency-input-field"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined"
import styled from "@emotion/styled"

import AutoCompleteNemoInput from "../shared/AutoCompleteNemoInput"
import CustomKnowMore from "../shared/CustomKnowMore"
import renderPropsInput from "../helpers/renderPropsInput"
import renderInputLabelProps from "../helpers/renderInputLabelProps"
import loadInstrumentData from "../helpers/loadInstrumentData"
import getUsdBalance from "./helpers/getUsdClpBalance"
import useEffectAfterFirstRender from "../../hooks/useEffectAfterFirstRender"
import { StyledTextField } from "../shared/cssStyledTextFields"

const ConfigureOperationContainer = styled.div`
  width: 1040px;
  height: 100%;
  background: #f9fafb;
  border-radius: 10px;
  margin: auto;
  padding-bottom: 30px;
  @media (max-width: 424px) {
    width: 100%;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 500px;
  margin: auto;

  @media (max-width: 424px) {
    width: 94%;
  }
`

const Title = styled.div`
  margin-top: 24px;
  height: 36px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  letter-spacing: -0.28px;
  color: #008060;
`

const SubText = styled.div`
  display: block;
  margin-top: 22px;
  align-items: center;
  height: 46px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #4e585c;
  @media (max-width: 424px) {
    height: 100%;
  }
`

const ErrorContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  gap: 5px;

  &.all-fields-error {
    margin-top: 25px;
    justify-content: center;
  }
`

const ErrorText = styled.span`
  color: #de3618;
  line-height: 1.3;
  &.field-error {
    line-height: 1.4;
  }
`

const DisclaimerText = styled.div`
  width: 500px;
  display: flex;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1px;
  color: #919eab;
  margin: auto;
  margin-top: 34px;

  @media (max-width: 424px) {
    width: 95%;
  }
`

const ButtonEm = styled(Button)`
  display: flex;
  background-color: #2ba770;
  border-radius: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 500px;
  margin-right: auto;
  margin-left: auto;
  height: 50px;
  margin-top: 18px;
  padding: 13px, 8px;
  color: #f5f6fa;
  text-transform: none;

  &:hover {
    background-color: #2ba770;
    color: #ffffff;
  }
  &:disabled {
    color: #637381;
    background: #dfe3e8;
  }

  @media (max-width: 424px) {
    width: 95%;
  }
`

const cssTextFieldSx = {
  display: {
    xs: "flex",
    md: "flex",
  },
  width: {
    xs: "95%",
    md: "500px",
  },
  marginLeft: {
    xs: "auto",
    md: "auto",
  },
  marginRight: {
    xs: "auto",
    md: "auto",
  },
  marginTop: {
    xs: "15px",
    md: "15px",
  },
  "& selected": {
    bgcolor: "#F4F6F8",
  },
  "& hover": {
    bgcolor: "#E0F5F5",
  },
}

type props = {
  balance: string | undefined
  setBalance: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedNemo: React.Dispatch<React.SetStateAction<string>>
  selectedNemo: string
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>
  hasErrors: boolean
  setShowSimulationInformation: React.Dispatch<React.SetStateAction<boolean>>
  setData: React.Dispatch<React.SetStateAction<InstrumentData | undefined>>
  currency: string
}

const ConfigureOperation = ({
  balance,
  setBalance,
  setSelectedNemo,
  selectedNemo,
  setErrorMessage,
  setHasErrors,
  hasErrors,
  setShowSimulationInformation,
  setData,
  currency,
}: props) => {
  const [nemoInput, setNemoInput] = useState<string>(" ")
  const [errors, setErrors] = useState<string[]>([])
  const [focusInput, setFocusInput] = useState<string[]>([])
  const { clpValue } = useContext(contextValues)

  const handleInputChange = (event: React.ChangeEvent<any>) => {
    const inputValue: string = event.target.value

    if (inputValue === "" || inputValue === undefined) {
      if (!errors.includes(event.target.name)) {
        setErrors([...errors, event.target.name])
      }
      setBalance("")
    } else {
      setErrors(errors.filter((error: string) => error !== event.target.name))
    }
  }

  const onBlurInput = (event: React.FocusEvent<any>) => {
    const inputValue: string = event.target.value

    if (inputValue.match(/\d\.$/)) {
      setBalance(inputValue.replace(/\.$/, "").replace(/,/g, ""))
    }

    setFocusInput(focusInput.filter((focus: string) => focus !== event.target.name))
  }

  const onFocusInput = (event: React.FocusEvent<any>) => {
    const inputValue = event.target.name

    if (!focusInput.includes(inputValue)) {
      setFocusInput([...focusInput, inputValue])
    }
  }

  const onClickSimulate: React.MouseEventHandler<HTMLButtonElement> = () => {
    const errorList: string[] = []

    if (balance === "" || balance === undefined) {
      errorList.push("balance")
    }

    if (selectedNemo !== nemoInput || nemoInput === " ") {
      errorList.push("nemo")
    }

    setErrors(errorList)

    if (balance !== "" && selectedNemo !== " " && selectedNemo === nemoInput) {
      setShowSimulationInformation(true)
      setErrorMessage({ onClickError: undefined })
      setHasErrors(false)
      setErrorMessage({ onClickError: undefined })
    } else {
      setShowSimulationInformation(false)
      setErrorMessage({ onClickError: "Completa todos los campos correctamente." })
      setHasErrors(true)
    }
  }

  useEffect(() => {
    if (errors.length < 1) {
      setHasErrors(false)
    } else {
      setHasErrors(true)
      setErrorMessage({ onClickError: "Completa todos los campos correctamente." })
    }
  }, [errors])

  const loadInstrumentsData = async () => {
    await loadInstrumentData(selectedNemo, setErrorMessage, setHasErrors, setData)
  }

  useEffect(() => {
    if (selectedNemo !== " ") {
      loadInstrumentsData()
    }
  }, [selectedNemo])

  const loadBalanceValues = async () => {
    const valueUSD: number = await getUsdBalance(balance, clpValue, currency)
    setBalance(valueUSD.toString().replace(/,/g, ""))
  }

  useEffectAfterFirstRender(loadBalanceValues, [currency])

  return (
    <ConfigureOperationContainer>
      <TextContainer>
        <Title>Configurar Operación</Title>
        <SubText>
          Para hacer el cálculo de la operación, necesitas ingresar tu <strong>Patrimonio</strong>{" "}
          disponible en la MetaTrader5.{" "}
          <CustomKnowMore url="https://capitaria.zendesk.com/hc/es/articles/360043623911--C%C3%B3mo-descargo-la-plataforma-Metatrader-5-" />
        </SubText>
      </TextContainer>
      <CurrencyInput
        customInput={StyledTextField}
        placeholder="Ingresa tu Patrimonio"
        name="balance"
        value={balance}
        label="Patrimonio"
        sx={cssTextFieldSx}
        InputProps={renderPropsInput(errors.includes("balance"), focusInput.includes("balance"))}
        InputLabelProps={renderInputLabelProps(errors.includes("balance"))}
        error={errors.includes("balance")}
        helperText={
          errors.includes("balance") ? (
            <ErrorContainer>
              <ErrorOutlinedIcon sx={{ fontSize: "1rem" }} />{" "}
              <ErrorText className="field-error">Campo Requerido</ErrorText>
            </ErrorContainer>
          ) : undefined
        }
        onChange={(event) => handleInputChange(event)}
        onValueChange={(value: string | undefined) => setBalance(value)}
        onBlur={onBlurInput}
        onFocus={onFocusInput}
        groupSeparator=","
        decimalSeparator="."
        allowNegativeValue={false}
        allowDecimals={true}
        decimalsLimit={2}
        type="text"
      />
      <AutoCompleteNemoInput
        id="nemo-input-advanced-calculator"
        CssTextField={StyledTextField}
        cssTextFieldSx={cssTextFieldSx}
        setSelectedNemo={setSelectedNemo}
        setNemoInput={setNemoInput}
        nemoInput={nemoInput}
        selectedNemo={selectedNemo}
        setErrorMessage={setErrorMessage}
        setHasErrors={setHasErrors}
        errors={errors}
        setErrors={setErrors}
      />
      <ButtonEm
        variant="contained"
        endIcon={<NavigateNextOutlinedIcon fontSize="small" />}
        onClick={onClickSimulate}
        disabled={hasErrors}
      >
        Simular Operación
      </ButtonEm>
      <DisclaimerText>
        Todos los valores reflejados son aproximados. Se deben interpretar solamente como
        sugerencias al momento de considerarlos en la toma de decisiones. A la hora de operar con
        activos financieros, los posibles valores de pérdida o ganancia que pudieran obtener al
        operar en la plataforma MetaTrader5, serán de exclusiva responsabilidad del cliente.
      </DisclaimerText>
    </ConfigureOperationContainer>
  )
}

export default ConfigureOperation
