import { useState } from "react"
import styled from "styled-components"

import Business from "../../assets/business"
import getInstrumentPrice from "../helpers/getInstrumentPrice"
import CustomTabComponent from "../shared/CustomTabComponent"
import SelectedTabText from "../shared/SelectedTabText"

const Wrapper = styled.div`
  display: flex;
  padding-top: 90px;
  padding-bottom: 60px;
  @media (max-width: 424px) {
    padding-top: 42px;
  }
`
const Container = styled.div`
  width: 1041px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 auto;
  @media (max-width: 424px) {
    width: 100%;
  }
`

const ContainerLeft = styled.div`
  display: flex;
  width: 510px;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 424px) {
    width: 100%;
  }
`

const ContainerRight = styled.div`
  width: 510px;
  padding-top: 125px;
  @media (max-width: 424px) {
    display: none;
  }
`

const WrapperItems = styled.div`
  display: flex;
  width: 510px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background: #e0f5f5;
`
const ItemsContainer = styled.div`
  padding-top: 32px;
  width: 403px;
`

const SubText = styled.div`
  font-family: "Roboto";
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  align-items: center;
  padding-top: 28px;
  color: #454f5b;
  @media (max-width: 424px) {
    padding: 0 20px;
  }
`

const Title = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: bold;
  padding-top: 35px;
  padding-bottom: 81px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1px;
  color: #008060;
  @media (max-width: 424px) {
    display: none;
  }
`

const tabs = [
  {
    key: "0",
    name: "detalle",
  },
  {
    key: "1",
    name: "explicación",
  },
  {
    key: "2",
    name: "instrumento",
  },
]

type Props = {
  selectedNemo: string
  currency: string
  data?: InstrumentData
  lotSize?: number
  calculatedOperationValues: CalculatedValues
  contractValueCLP: string | number
  contractValueUSD: string | number
  inputCurrencyValue: string
  selectedOperation: string
  winLostValue: string | number | void
  hasErrors: boolean
}

const DetailInformation = ({
  selectedNemo,
  currency,
  data,
  lotSize,
  calculatedOperationValues,
  contractValueCLP,
  contractValueUSD,
  inputCurrencyValue,
  selectedOperation,
  winLostValue,
  hasErrors,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].key)
  const instrumentPrice = getInstrumentPrice(data, selectedOperation)
  return (
    <>
      <Wrapper>
        <Container>
          <ContainerLeft>
            <CustomTabComponent
              detailText="Información en detalle"
              tabs={tabs}
              tabSliderClassName="tab-slider-prod"
              selectedNemo={selectedNemo}
              setSelectedTab={setSelectedTab}
            >
              <SelectedTabText
                selectedTab={selectedTab}
                currency={currency}
                data={data}
                lotSize={lotSize}
                calculatedOperationValues={calculatedOperationValues}
                contractValueCLP={contractValueCLP}
                contractValueUSD={contractValueUSD}
                inputCurrencyValue={inputCurrencyValue}
                selectedOperation={selectedOperation}
                instrumentPrice={instrumentPrice}
                winLostValue={winLostValue}
                hasErrors={hasErrors}
              />
            </CustomTabComponent>
          </ContainerLeft>
          <ContainerRight>
            <WrapperItems>
              <ItemsContainer>
                <Business />
                <SubText>
                  Antes de entrar al mercado, estudia y define cada punto de tu estrategia como
                  Trader.
                </SubText>
                <Title>No te dejes llevar por las emociones.</Title>
              </ItemsContainer>
            </WrapperItems>
          </ContainerRight>
        </Container>
      </Wrapper>
    </>
  )
}

export default DetailInformation
