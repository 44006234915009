export default function currentHourIsBetweenHoursRange(
  hoursRange: Array<number>,
  currentHour: number
) {
  if (hoursRange[0] <= currentHour && currentHour <= hoursRange[1]) {
    return true
  }
  if (hoursRange[2] <= currentHour && currentHour <= hoursRange[3]) {
    return true
  }
}
