export default function minPositionSizeValidation(
  data: InstrumentData,
  oneMinLotSizeCases: string[],
  lotSizeExcludeIndexCases: string[],
  microLotCases: string[],
  setMinLotSize: React.Dispatch<React.SetStateAction<number>>
) {
  if (
    (data.market === "index" || oneMinLotSizeCases.includes(data.nemo)) &&
    !lotSizeExcludeIndexCases.includes(data.nemo.slice(0, -5))
  ) {
    setMinLotSize(1)
  } else if (data.account_type === "start" || microLotCases.includes(data.nemo)) {
    setMinLotSize(0.01)
  } else {
    setMinLotSize(0.1)
  }
}
