const chileFlag = (props: any) => (
  <svg
    width={21}
    height={14}
    viewBox="0 0 28 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#FFC700"
      d="M0 0h21v14H0z"
    />
    <path
      fill="#fff"
      d="M-901.5-85.5h967v623h-967z"
    />
    <rect
      x={0.25}
      y={0.25}
      width={27.5}
      height={19.5}
      rx={1.75}
      fill="#fff"
      stroke="#F5F5F5"
      strokeWidth={0.5}
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={28}
      height={20}
    >
      <rect
        x={0.25}
        y={0.25}
        width={27.5}
        height={19.5}
        rx={1.75}
        fill="#fff"
        stroke="#fff"
        strokeWidth={0.5}
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20h28V9.333H0V20Z"
        fill="#EA3B2E"
      />
      <path
        fill="#0B48C2"
        d="M0 0h9.333v9.333H0z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.667 5.707 3.099 6.824l.579-1.836L2.13 3.843l1.924-.018L4.667 2l.611 1.825 1.925.018-1.547 1.145.578 1.836-1.567-1.117Z"
        fill="#fff"
      />
    </g>
    <path
      stroke="#000"
      strokeWidth={5}
      d="M-901.5-85.5h967v623h-967z"
    />
  </svg>
)

export default chileFlag
