export default function getGainOnTakeProfit(entryPrice: string, takeProfit: number | undefined) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @param  {number | undefined} takeProfit Take Profit.
   * @return {number | undefined} Ganancia en Take Profit.
   *
   * Formula: (TP - PE)
   *
   * Calcula la ganancia en Take Profit.
   */
  const floatEntryPrice = parseFloat(entryPrice.replace(/,/g, ""))
  let takeProfitGain = 0
  if (takeProfit) {
    takeProfitGain = takeProfit - floatEntryPrice
  }
  return takeProfitGain
}
