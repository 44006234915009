import calculateAmountToDeposit from "../../helpers/calculateAmountToDeposit"
import calculateAmountToWithdrawal from "./calculateAmountToWithdrawal"

export default function renderMarginText(marginVars: MarginVars, marginLevel: number) {
  const { equity, usedMargin, depositAmount, withdrawalAmount } = marginVars

  const amountToWithdrawal = calculateAmountToWithdrawal(equity, depositAmount, usedMargin)
  const amountToDeposit = calculateAmountToDeposit(equity, usedMargin, withdrawalAmount)

  const marginTexts = [
    `
      Con este nivel de margen, estas en un nivel bastante saludable, puedes realizar mas
      operaciones sin problema (Con tamaño adecuado a tu Equidad). Puedes rescatar hasta
      ${amountToWithdrawal} para quedar a un nivel de margen de 300% lo que se considera saludable.
    `,
    `
      Con este nivel de margen, estas en un nivel saludable, puedes realizar mas operaciones
      pero con cautela. Puedes rescatar hasta ${amountToWithdrawal} para quedar a un nivel de margen
      de 300% lo que se considera saludable.
    `,
    `
      Con este nivel de margen, estas en un nivel inferior al 300% recomendado, se recomienda
      gestionar operaciones o depositar. Atento a la volatilidad del Mercado. No se aconseja
      rescatar dinero a este nivel.
    `,
    `
      Con este nivel de margen, estas cercano a Margin call, se recomienda gestionar operaciones
      o depositar. Atento a la volatilidad del Mercado. Si depositas mas de ${amountToDeposit}
      llegarias a un nivel de margen de 300% lo que se considera saludable. No se aconseja
      rescatar dinero a este nivel.
    `,
    `
      Con este nivel de margen, llegarias a Margin call, se recomienda gestionar operaciones o
      depositar. Si depositas mas de ${amountToDeposit} llegarias a un nivel de margen de 300% lo
      que se considera saludable. No se aconseja rescatar dinero a este nivel.
    `,
    `
      Nivel de margen indeterminado. Por favor, ingresa valores validos para calcular el nivel
      de margen adecuado.
    `,
    `
      Con este nivel de margen, te irias en stop out y se cerrarian tus operaciones. Si depositas
      mas de ${amountToDeposit} llegarias a un nivel de margen de 300% lo que se considera
      saludable. No se aconseja rescatar dinero a este nivel.
    `,
  ]

  if (marginLevel > 300) return marginTexts[0]
  if (marginLevel === 300) return marginTexts[1]
  if (marginLevel < 300 && marginLevel >= 150) return marginTexts[2]
  if (marginLevel < 150 && marginLevel >= 90) return marginTexts[3]
  if (marginLevel < 90 && marginLevel >= 50) return marginTexts[4]
  if (Object.is(marginLevel, NaN)) return marginTexts[5]
  return marginTexts[6]
}
