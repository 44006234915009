import roundToAny from "./roundToAny"

export default function winLoseWhenMarginCurrencyIsUsd(
  instrumentPrice: number,
  inputCurrencyValue: number,
  contractSize: number,
  contractValueUSD: number
) {
  /**
   * @param  {number} instrumentPrice El precio del instrumento.
   * @param  {number} inputCurrencyValue Variación del precio al alza o baja.
   * @param  {number} contractSize Tamaño del contrato.
   * @param  {number} contractValueUSD Valor del contrato en USD.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: (( 1 / Precio del instrumento +  1/ Variación de precio) * Tamaño del contrato
   * * Variación de precio) - Valor del contrato en USD
   *
   * Calcula la ganancia o perdida de la operación cuando la moneda de margen es USD y
   * la retorna redondeada a 2 digitos.
   */

  const winLoseValue = roundToAny(
    (1 / instrumentPrice + 1 / inputCurrencyValue) * contractSize * inputCurrencyValue -
      contractValueUSD,
    4
  )

  return winLoseValue
}
