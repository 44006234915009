export default async function getContractValueCLP(
  contractValueUSD: string | number,
  usdClpValue: string
) {
  let contractValueCLP: string | number = ""
  const FloatUsdClpValue = parseFloat(usdClpValue)

  if (typeof contractValueUSD === "string") {
    //eslint-disable-next-line
    contractValueUSD = contractValueUSD.replace(/\,/g, "")
    const floatContractValueUSD = parseFloat(contractValueUSD)
    contractValueCLP = Math.round(floatContractValueUSD * FloatUsdClpValue)
    return Intl.NumberFormat("en-US").format(contractValueCLP)
  }
  return NaN
}
