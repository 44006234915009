import roundToAny from "../../helpers/roundToAny"

export default function calculateSizePosition(
  risk: number | undefined | string | string[] | number[],
  balance: string | undefined,
  volumenInCurrency: string,
  lossOnStopLoss: number | string,
  minLotSize?: number
) {
  /**
   * @param  {number | undefined | string | string[] | number[]} risk Porcentaje de riesgo.
   * @param  {string | undefined} balance Balance.
   * @param  {string} volumenInCurrency Volumen en la moneda.
   * @param  {number | undefined} lossOnStopLoss Perdida en Stop Loss.
   * @param  {number | undefined} currency Moneda seleccionada.
   * @param  {number | undefined} clpValue Valor USDCLP.
   * @return {number} Tamaño de Posición.
   *
   * Formula: ((Riesgo % * Balance)/ Volumen en la moneda)/(Perdida en Stop Loss)
   *
   * Calcula el valor del tamaño de la posición
   */
  const riskLevel = parseFloat(String(risk)) / 100
  const floatBalance = parseFloat(balance || "0")
  volumenInCurrency = volumenInCurrency.replace(".", "")
  const intVolumenInCurrency = parseInt(volumenInCurrency, 10)
  lossOnStopLoss = parseFloat(String(lossOnStopLoss).replace(/,/g, ""))

  let positionSize = 0
  if (minLotSize === 0.01) {
    positionSize = roundToAny((riskLevel * floatBalance) / intVolumenInCurrency / lossOnStopLoss, 2)
  }
  if (minLotSize === 0.1) {
    positionSize = roundToAny((riskLevel * floatBalance) / intVolumenInCurrency / lossOnStopLoss, 1)
  }
  if (minLotSize === 1) {
    positionSize = Math.round((riskLevel * floatBalance) / intVolumenInCurrency / lossOnStopLoss)
  }

  return positionSize
}
