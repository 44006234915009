import styled from "styled-components"

import GreenLineVector from "../../assets/greenLineVector"
import LoseVector from "../../assets/loseVector"
import PinkLineVector from "../../assets/pinkLineVector"
import WinVector from "../../assets/winVector"

const WinLoseContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 480px;
  padding-top: 15px;

  @media (max-width: 424px) {
    max-width: 330px;
    justify-content: flex-start;
  }
`

const Elipsis = styled.div`
  background: #a4f8d5;
  width: 59px;
  height: 59px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.lose-elipsis {
    background: #ffcac0;
  }
`

const WinLoseTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-left: 12px;
`

const WinLoseColorText = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #4e585c;

  &.win-text {
    color: #008060;
  }
  &.lose-text {
    color: #de3618;
  }
`

type Props = {
  upText?: string
  downText?: string
  inputCurrencyValue: string
  currency: string
  currencyShowed?: string
  winLostValue: string | number | void
  winLostColorTextClassName: string
  elipsisClassName?: string
  hasErrors: boolean
}

const WinLoseContainers = ({
  upText,
  downText,
  inputCurrencyValue,
  currency,
  currencyShowed,
  winLostValue,
  winLostColorTextClassName,
  elipsisClassName,
  hasErrors,
}: Props) => {
  let winLoseText = ""
  let upDownText: string | undefined = ""
  let vector: JSX.Element
  let lineVector: JSX.Element
  let winLostMessage = ""
  const emptyMessage = `$ ${currency}`
  const filterInput = parseFloat(inputCurrencyValue.replace(/,/g, "").replace("$", ""))
  let displayInput: number | string = ""
  displayInput = Intl.NumberFormat("en-US").format(filterInput)

  if (filterInput < 0.001) {
    displayInput = filterInput
  }
  // const displayInput = Intl.NumberFormat("en-US").format(filterInput)

  if (upText) {
    winLoseText = "ganarías"
    upDownText = upText
    vector = <WinVector />
    lineVector = <GreenLineVector />
  } else {
    winLoseText = "perderías"
    upDownText = downText
    vector = <LoseVector />
    lineVector = <PinkLineVector />
  }

  if (Object.is(winLostValue, NaN)) {
    winLostMessage = emptyMessage
  } else if (!hasErrors) {
    winLostMessage = `${winLostValue} ${currency}`
  } else {
    winLostMessage = emptyMessage
  }

  return (
    <>
      <WinLoseContainer>
        <Elipsis className={elipsisClassName}>
          {lineVector}
          {vector}
        </Elipsis>
        <WinLoseTextContainer>
          <WinLoseColorText className={winLostColorTextClassName}>
            Si {upDownText} {displayInput} {currencyShowed}, {winLoseText}
          </WinLoseColorText>
          <WinLoseColorText>{winLostMessage}</WinLoseColorText>
        </WinLoseTextContainer>
      </WinLoseContainer>
    </>
  )
}

export default WinLoseContainers
