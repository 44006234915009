import styled from "@emotion/styled"

import Offices from "./Offices"
import Disclaimer from "./Disclaimer"

const Footer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  background-color: #1a2225;
  padding-top: 50px;
  padding-bottom: 25px;
  @media (max-width: 424px) {
    padding: 1rem 0 2rem;
    display: block;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 1rem 0 2rem;
  }
`
const Container = styled.div`
  width: 1041px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 424px) {
    width: 100%;
  }
`
const FooterSection = () => (
  <Footer>
    <Container>
      <Offices />
      <Disclaimer />
    </Container>
  </Footer>
)

export default FooterSection
