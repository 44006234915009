import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Slider from "@mui/material/Slider"

import CustomTabComponent from "../shared/CustomTabComponent"
import getInstrumentPrice from "../helpers/getInstrumentPrice"
import getContractSize from "../helpers/getContractSize"
import getMargin from "../helpers/getMargin"
import loadContractsValues from "../helpers/loadContractsValues"
import inputIsValid from "./helpers/inputIsValid"
import LotSizeInput from "../shared/LotSizeInput"
import CustomFormInput from "../shared/CustomFormInput"
import StopLossTakeProfitContainer from "./StopLossTakeProfitContainer"
import Ratio from "./Ratio"
import getGainOnTakeProfit from "./helpers/getGainOnTakeProfit"
import getLossOnStopLoss from "./helpers/getLossOnStopLoss"
import calculateRatio from "./helpers/calculateRatio"
import calculateDefaultTakeProfit from "./helpers/calculateDefaultTakeProfit"
import calculateDefaultStopLoss from "./helpers/calculateDefaultStopLoss"
import setHelpertTexts from "./helpers/setHelperTexts"
import calculatePositionSize from "./helpers/calculatePositionSize"
import calculateDefaultRiskAmount from "./helpers/calculateDefaultRiskAmount"
import calculatePercentRiskLevel from "./helpers/calculatePercentRiskLevel"
import { contextValues } from "../../context/ContextValues"
import getStopLossTakeProfitValue from "./helpers/getStopLossTakeProfitValue"
import calculateTakeProfitAmount from "./helpers/calculateTakeProfitAmount"
import calculateStopLossAmount from "./helpers/calculateStopLossAmount"
import InputSlider from "../shared/InputSlider"
import SelectedTabText from "../shared/SelectedTabText"
import minPositionSizeValidation from "./helpers/minPositionSizeValidation"
import inputSliderValidation from "./helpers/inputSliderValidation"
import {
  oneMinLotSizeCases,
  lotSizeExcludeIndexCases,
  microLotCases,
} from "../../config/specialsCase"

const Wrapper = styled.div`
  display: flex;
  margin-top: 15px;
`

const Container = styled.div`
  width: 1041px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 424px) {
    flex-direction: column;
    width: 100%;
  }
`

const ContainerLeft = styled.div`
  display: flex;
  border-radius: 10px;
  width: 510px;
  height: 100%;
  flex-direction: column;
  padding-top: 8px;
  align-items: flex-start;
  text-align: center;
  @media (max-width: 424px) {
    max-width: 90%;
    align-items: center;
    align-items: flex-start;
    margin-left: 15px;
    margin-right: 15px;
    align-self: center;
  }
`

const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  //border-radius: 10px;
  width: 510px;
  height: 100%;
  //padding-top: 14px;
  align-items: start;
  //text-align: center;
  @media (max-width: 424px) {
    max-width: 100%;
  }
`

const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 424px) {
    margin-top: 40px;
  }
`

const WrapperLeft = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 424px) {
    justify-content: center;
  }
`

const Title = styled.div`
  font-weight: 500;
  color: #008060;
  font-size: 24px;
  line-height: 36px;
  font-family: "Roboto";
  letter-spacing: -0.28px;
  @media (max-width: 424px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    align-items: center;
    width: 90%;
    margin-left: 15px;
    margin-right: 15px;
  }
`

const TextInfo = styled.div`
  width: 480px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flow-root;
  align-items: center;
  text-align: initial;
  color: #4e585c;

  &.sub-text {
    padding-top: 32px;
  }

  @media (max-width: 424px) {
    max-width: 100%;
  }
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

const PriceText = styled.div`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #008060;

  &.last-registered-price-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.1px;
    color: #637381;
    text-align: initial;
    align-items: center;
  }
`

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 25px;
  gap: 20%;

  @media (max-width: 424px) {
    gap: 5%;
  }
`

const InputRatioContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
  gap: 20%;

  @media (max-width: 424px) {
    gap: 5%;
  }
`

const formControlSx = {
  width: {
    xs: "100%",
    md: "175px",
  },
}

const formControlSxTakeProfit = {
  width: {
    xs: "50%",
    md: "175px",
  },
}

const formControlSxInputSlider = {
  width: {
    xs: "100%",
    md: "175px",
  },
  marginLeft: "2px",
}

const formHelperTextSx = {
  width: "150px",
  marginBottom: {
    xs: "0px",
    md: "0px",
  },
}

const tabs = [
  {
    key: "0",
    name: "detalle",
  },
  {
    key: "1",
    name: "instrumento",
  },
]

const initState = {
  winByTakeProfit: 0,
  lossByStopLoss: 0,
}

const startValues = {
  gainOnTakeProfit: 0,
  lossOnStopLoss: 0,
}

const initError = {
  riskError: "",
  stopLossError: "",
  takeProfitError: "",
}

type Props = {
  data: InstrumentData | undefined
  currency: string
  hasErrors: boolean
  selectedNemo: string
  balance: string | undefined
}

const InformationAdvancedCalculator = ({
  data,
  currency,
  hasErrors,
  selectedNemo,
  balance,
}: Props) => {
  const [positionSize, setPositionSize] = useState<number | undefined>(0.1)
  const [minLotSize, setMinLotSize] = useState<number>(0)
  const [sellPrice, setSellPrice] = useState<string>("")
  const [stopLoss, setStopLoss] = useState<number | undefined>(0)
  const [takeProfit, setTakeProfit] = useState<number | undefined>(0)
  const [ratio, setRatio] = useState<number | undefined>(0)
  const [sliderRatio, setSliderRatio] = useState<number | undefined | string | string[] | number[]>(
    5
  )
  const instrumentPrice = getInstrumentPrice(data, "Compra")
  const [inputSlider, setInputSlider] = useState<undefined | string | number>("")
  const [helperTextStopLoss, setHelperTextStopLoss] = useState<string>("")
  const [helperTextTakeProfit, setHelperTextTakeProfit] = useState<string>("")
  const [winLoseValue, setWinLoseValue] = useState<StopLossTakeProfitAmounts>(initState)
  const [winLossOnTPSLValues, setWinLossOnTPSLValues] = useState<WinLossTPSLValues>(startValues)
  const { usdValueForWinLose, usdValueForContract, clpValue } = useContext(contextValues)
  const { lossOnStopLoss } = winLossOnTPSLValues
  const { winByTakeProfit, lossByStopLoss } = winLoseValue
  const [errors, setErrors] = useState<ErrorClass>(initError)
  const [selectedTab, setSelectedTab] = useState(tabs[0].key)

  const valuesClpUsd: ContextValues = {
    clpValue: clpValue,
    usdValueForWinLose: usdValueForWinLose,
    usdValueForContract: usdValueForContract,
  }

  const [contractValueCLP, setContractValueCLP] = useState<string | number>("")
  const [contractValueUSD, setContractValueUSD] = useState<string | number>("")
  const calculatedOperationValues: CalculatedValues = {
    contractSize: getContractSize(positionSize, data),
    usdMargin: getMargin(contractValueUSD, undefined, data),
    clpMargin: getMargin(undefined, contractValueCLP, data),
  }

  const marks = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 50,
      label: "50%",
    },
    {
      value: 100,
      label: "100%",
    },
  ]

  const handleSliderChange = (event: Event, value: number | number[] | string[]) => {
    setSliderRatio(value)

    const riskAmount = calculateDefaultRiskAmount(value, balance)
    setInputSlider(riskAmount)

    if (data !== undefined) {
      minPositionSizeValidation(
        data,
        oneMinLotSizeCases,
        lotSizeExcludeIndexCases,
        microLotCases,
        setMinLotSize
      )

      if (String(stopLoss) !== "") {
        const positionSize = calculatePositionSize(
          value,
          balance,
          data.volumen_in_currency,
          lossOnStopLoss,
          minLotSize
        )

        if (positionSize < minLotSize) {
          setPositionSize(minLotSize)
        } else {
          setPositionSize(positionSize)
        }
      }
    }

    if ((inputSlider || 0) > parseFloat(balance || "0")) {
      setErrors({ ...errors, riskError: "errors" })
    } else {
      setErrors({ ...errors, riskError: "" })
    }
  }

  const handleInputSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value

    if (!inputIsValid(inputValue)) {
      return
    }
    setInputSlider(inputValue.replace(/,/g, ""))

    if (data !== undefined) {
      const params = {
        inputValue,
        balance,
        setSliderRatio,
        data,
        oneMinLotSizeCases,
        lotSizeExcludeIndexCases,
        microLotCases,
        setMinLotSize,
        stopLoss,
        minLotSize,
        setPositionSize,
        lossOnStopLoss,
      }
      inputSliderValidation(params)
    }

    if (event.target.name === "inputSlider") {
      if ((inputValue.replace(/,/g, "") || 0) > parseFloat(balance || "0")) {
        setErrors({ ...errors, riskError: "errors" })
      } else {
        setErrors({ ...errors, riskError: "" })
      }
    }
  }

  const onBlurInputSlider = (event: React.FocusEvent<any>) => {
    const inputValue: string = event.target.value

    if (!inputValue) {
      setInputSlider("")
    }

    if (inputValue.match(/\d\.$/)) {
      const value = event.target.value.slice(0, -1).replace(/,/g, "")
      setInputSlider(value)
    }
  }

  const onChangeInput = (
    event: React.ChangeEvent<any>,
    setter: React.Dispatch<React.SetStateAction<number | undefined>>
  ) => {
    const inputValue: string = event.target.value
    let value = 0

    if (!inputIsValid(inputValue)) {
      return
    }

    if (!inputValue.match(/^0\.0/)) {
      value = event.target.value
    }

    setter(value)

    if (event.target.name === "takeProfit") {
      setErrors({
        ...errors,
        takeProfitError: parseFloat(sellPrice) > (value || 0) || !value || 0 ? "errors" : "",
      })
    }
    if (event.target.name === "stopLoss") {
      setErrors({
        ...errors,
        stopLossError: parseFloat(sellPrice) < (value || 0) || !value || 0 ? "errors" : "",
      })
    }
  }

  const onChangePositionSize = (event: React.ChangeEvent<any>) => {
    const inputValue: string = event.target.value
    let value = 0.1

    if (!inputIsValid(inputValue)) {
      return
    }

    if (!inputValue.match(/^0\.0/)) {
      value = event.target.value
    }

    if (data !== undefined) {
      const riskLevel = calculatePercentRiskLevel(
        lossOnStopLoss,
        value,
        data.volumen_in_currency,
        balance
      )
      if (inputValue !== "") {
        setSliderRatio(riskLevel)
        const riskAmount = calculateDefaultRiskAmount(riskLevel, balance)
        setInputSlider(riskAmount)
      }
    }
  }

  const onBlurInput = (
    event: React.FocusEvent<any>,
    setter: React.Dispatch<React.SetStateAction<number | undefined>>
  ) => {
    const inputValue: string = event.target.value

    if (!inputValue) {
      setter(0)
    }

    if (inputValue.match(/\d\.$/)) {
      const value = event.target.value.slice(0, -1)
      setter(value)
    }
  }

  const loadWinLossValues = () => {
    if (data !== undefined) {
      const lossOnStopLossUnformatted = getLossOnStopLoss(sellPrice, stopLoss)
      const gainOnTakeProfitUnformatted = getGainOnTakeProfit(sellPrice, takeProfit)
      const result = getStopLossTakeProfitValue(
        data,
        valuesClpUsd,
        gainOnTakeProfitUnformatted,
        lossOnStopLossUnformatted,
        currency
      )
      const { winByTakeProfit, lossByStopLoss } = result
      const lossOnStopLoss = parseFloat(String(lossByStopLoss).replace(/,/g, ""))
      const gainOnTakeProfit = parseFloat(String(winByTakeProfit).replace(/,/g, ""))
      setWinLossOnTPSLValues({ gainOnTakeProfit, lossOnStopLoss })
    }
  }

  const loadWinLostValue = () => {
    if (data !== undefined) {
      const takeProfitAmount = calculateTakeProfitAmount(
        positionSize,
        data.volumen_in_currency,
        sellPrice,
        takeProfit
      )
      const stopLossAmount = calculateStopLossAmount(
        positionSize,
        data.volumen_in_currency,
        sellPrice,
        stopLoss
      )
      const result = getStopLossTakeProfitValue(
        data,
        valuesClpUsd,
        takeProfitAmount,
        stopLossAmount,
        currency
      )
      setWinLoseValue(result)
    }
  }

  const loadContractValues = async () => {
    await loadContractsValues(
      data,
      "Compra",
      calculatedOperationValues.contractSize,
      usdValueForContract,
      clpValue,
      setContractValueUSD,
      setContractValueCLP
    )
  }

  useEffect(() => {
    loadContractValues()
  }, [data, usdValueForContract, positionSize])

  useEffect(() => {
    if (data !== undefined) {
      setSellPrice(data.sell_price)
    }
  }, [data])

  useEffect(() => {
    if (data !== undefined) {
      const defaultTakeProfit = calculateDefaultTakeProfit(sellPrice)
      const defaultStopLoss = calculateDefaultStopLoss(sellPrice)

      if (!isNaN(defaultTakeProfit) && !isNaN(defaultStopLoss)) {
        setStopLoss(defaultStopLoss)
        setTakeProfit(defaultTakeProfit)
      }
    }
  }, [data, sellPrice])

  useEffect(() => {
    if (data !== undefined) {
      setHelpertTexts(
        sellPrice,
        stopLoss,
        takeProfit,
        setHelperTextStopLoss,
        setHelperTextTakeProfit
      )
      if (stopLoss && parseFloat(sellPrice) < stopLoss) return
      if (takeProfit && parseFloat(instrumentPrice) > takeProfit) return

      const takeProfitGain = getGainOnTakeProfit(sellPrice, takeProfit)
      const lossOnStopLoss = getLossOnStopLoss(sellPrice, stopLoss)
      const ratio = calculateRatio(takeProfitGain, lossOnStopLoss)
      setRatio(ratio)
    }
  }, [takeProfit, stopLoss, data])

  useEffect(() => {
    if (data !== undefined) {
      minPositionSizeValidation(
        data,
        oneMinLotSizeCases,
        lotSizeExcludeIndexCases,
        microLotCases,
        setMinLotSize
      )

      if (String(stopLoss) !== "" && lossOnStopLoss) {
        const positionSize = calculatePositionSize(
          sliderRatio,
          balance,
          data.volumen_in_currency,
          lossOnStopLoss,
          minLotSize
        )

        if (positionSize < minLotSize) {
          setPositionSize(minLotSize)
        } else {
          setPositionSize(positionSize)
        }
      }
    }
  }, [data, lossOnStopLoss, stopLoss, balance])

  useEffect(() => {
    const riskAmount = calculateDefaultRiskAmount(sliderRatio, balance)
    setInputSlider(riskAmount)
  }, [data, balance])

  useEffect(() => {
    loadWinLossValues()
    loadWinLostValue()
  }, [data, usdValueForWinLose, usdValueForContract, currency, positionSize, takeProfit, stopLoss])

  return (
    <Wrapper>
      <Container>
        <WrapperLeft>
          <TitleContainer>
            <Title>Simulación de Riesgo / Recompensa</Title>
          </TitleContainer>
          <ContainerLeft>
            {!hasErrors ? (
              <TextInfo>
                Modifica los siguientes parámetros y fijate cuanto sería el riesgo que estarías
                asumiendo
              </TextInfo>
            ) : (
              <TextInfo>
                No se pudo cargar la información del instrumento para determinar cuánto sería el
                riesgo que estarías asumiendo. Por favor, recarga el navegador e intentalo
                nuevamente.
              </TextInfo>
            )}
            <TextInfo className="sub-text">
              Riesgo en tu Patrimonio: <strong>{sliderRatio}%</strong>
            </TextInfo>
            <Box
              sx={{
                width: "90%",
                "@media screen and (max-width: 424px)": {
                  width: "100%",
                },
              }}
            >
              <Typography
                id="input-slider"
                gutterBottom
              ></Typography>
              <Grid
                container
                spacing={3}
                alignItems="center"
                width={"100%"}
                sx={{ textAlign: "start", marginLeft: "0", gap: "25px" }}
              >
                <Grid
                  item
                  xs
                  sx={{
                    width: "60%",
                    "@media screen and (max-width: 424px)": {
                      width: "50%",
                      paddingLeft: "0px",
                    },
                  }}
                >
                  <Slider
                    value={typeof sliderRatio === "number" ? sliderRatio : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="input-slider"
                    defaultValue={50}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    marks={marks}
                    min={0}
                    max={100}
                    step={0.01}
                    sx={{ width: "100%", color: "#2BA770" }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: "40%",
                    "@media screen and (max-width: 424px)": {
                      width: "50%",
                    },
                    alignSelf: "flex-start",
                  }}
                >
                  <InputSlider
                    name="inputSlider"
                    value={inputSlider}
                    currency={currency}
                    handleInputSliderChange={handleInputSliderChange}
                    onBlurInputSlider={onBlurInputSlider}
                    balance={balance}
                    errors={errors}
                    formControlSx={formControlSxInputSlider}
                  />
                </Grid>
              </Grid>
            </Box>
            <PriceContainer>
              <PriceText className="last-registered-price-text">Precio del Instrumento</PriceText>
              <PriceText>${instrumentPrice}</PriceText>
            </PriceContainer>
            <InputsContainer>
              <LotSizeInput
                lotSize={positionSize}
                setLotSize={setPositionSize}
                data={data}
                formControlSx={formControlSx}
                formHelperTextSx={formHelperTextSx}
                label="Tamaño de Lotes"
                onChangePosition={(event) => onChangePositionSize(event)}
                lotSizeAdvanced={true}
              />
              <CustomFormInput
                formControlSx={formControlSx}
                value={stopLoss}
                name="stopLoss"
                onChange={(event) => onChangeInput(event, setStopLoss)}
                onBlur={(event) => onBlurInput(event, setStopLoss)}
                label="Stop Loss"
                helperText={helperTextStopLoss}
                errors={errors}
              />
            </InputsContainer>
            <StopLossTakeProfitContainer
              currency={currency}
              hasErrors={hasErrors}
              lossByStopLoss={lossByStopLoss}
            />
            <InputRatioContainer>
              <CustomFormInput
                formControlSx={formControlSxTakeProfit}
                value={takeProfit}
                name="takeProfit"
                onChange={(event) => onChangeInput(event, setTakeProfit)}
                onBlur={(event) => onBlurInput(event, setTakeProfit)}
                label="Take Profit"
                helperText={helperTextTakeProfit}
                errors={errors}
              />
              <Ratio ratio={ratio} />
            </InputRatioContainer>
            <StopLossTakeProfitContainer
              currency={currency}
              hasErrors={hasErrors}
              winByTakeProfit={winByTakeProfit}
            />
          </ContainerLeft>
        </WrapperLeft>
        <WrapperRight>
          <ContainerRight>
            <CustomTabComponent
              detailText="Información de la operación"
              tabs={tabs}
              tabSliderClassName="tab-slider-advanced-calc-prod"
              selectedNemo={selectedNemo}
              setSelectedTab={setSelectedTab}
            >
              <SelectedTabText
                selectedTab={selectedTab}
                currency={currency}
                data={data}
                lotSize={positionSize}
                calculatedOperationValues={calculatedOperationValues}
                contractValueCLP={contractValueCLP}
                contractValueUSD={contractValueUSD}
                selectedOperation={"Compra"}
                instrumentPrice={instrumentPrice}
                hasErrors={hasErrors}
                winLoseValue={winLoseValue}
                ratio={ratio}
              />
            </CustomTabComponent>
          </ContainerRight>
        </WrapperRight>
      </Container>
    </Wrapper>
  )
}

export default InformationAdvancedCalculator
