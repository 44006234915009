import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"
import styled from "@emotion/styled"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import { ChangeEvent, FocusEvent } from "react"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import { CssTextField } from "./cssStyledTextFields"

const ErrorContainer = styled.span`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  gap: 5px;
`

const ErrorText = styled.span`
  color: #de3618;
  line-height: 1.3;
  &.field-error {
    line-height: 1.4;
  }
`

type props = {
  formControlSx: SxProps<Theme>
  value: number | undefined
  onChange: (event: ChangeEvent<any>) => void
  onBlur: (event: FocusEvent<any>) => void
  formHelperTextSx?: SxProps<Theme>
  helperText?: string
  label: string
  name: string
  errors: ErrorClass
}

const CustomFormInput = ({
  formControlSx,
  value,
  onChange,
  onBlur,
  formHelperTextSx,
  helperText,
  label,
  name,
  errors,
}: props) => {
  let error = ""
  let inputProps = {
    className: "",
  }
  let inputLabelProps = {
    className: "",
  }

  if (name === "takeProfit") {
    error = errors.takeProfitError
    inputProps = {
      className: error,
    }
    inputLabelProps = {
      className: error,
    }
  }

  if (name === "stopLoss") {
    error = errors.stopLossError
    inputProps = {
      className: error,
    }
    inputLabelProps = {
      className: error,
    }
  }

  return (
    <FormControl
      sx={formControlSx}
      variant="outlined"
    >
      <CssTextField
        id="outlined-adornment-weight"
        label={label}
        name={name}
        value={value}
        onChange={(event) => onChange(event)}
        onBlur={(event) => onBlur(event)}
        InputProps={inputProps}
        InputLabelProps={inputLabelProps}
      />
      <FormHelperText
        sx={formHelperTextSx}
        id="outlined-weight-helper-text"
        className={error}
      >
        <ErrorContainer>
          {error !== "" && <ErrorOutlinedIcon sx={{ fontSize: "1rem", marginTop: "1px" }} />}{" "}
          <ErrorText className="field-error">{helperText}</ErrorText>
        </ErrorContainer>
      </FormHelperText>
    </FormControl>
  )
}

export default CustomFormInput
