/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listInstruments = /* GraphQL */ `
  query ListInstruments {
    listInstruments {
      name
      nemo
    }
  }
`;
export const getInstrument = /* GraphQL */ `
  query GetInstrument($nemo: String!) {
    getInstrument(nemo: $nemo) {
      account_type
      buy_swap
      sell_swap
      base_currency
      margin_currency
      name
      nemo
      margin
      market
      pip_value
      spread
      premium_spread
      pro_spread
      volumen_in_currency
      pro_min_volumen
      monday
      tuesday
      wednesday
      thursday
      friday
      sunday
      buy_price
      sell_price
      date
      time
    }
  }
`;
