import getInstrumentData from "./getInstrumentData"

export default async function getUsdclpValue(selectedOperation: string) {
  try {
    const instrument: InstrumentData = await getInstrumentData("USDCLP")
    if (selectedOperation === "Compra") {
      return instrument.sell_price
    }
    return instrument.buy_price
  } catch (e) {
    console.error(e)
  }
}
