import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    companyGreen: {
      main: "#0DBB73",
      contrastText: "#F9FAFB",
      white: "#FFFFFF",
      secondary: "#2BA770",
      darker: "#004C3F",
    },
    companyGrey: {
      main: "#303A3E",
      contrastText: "#F9FAFB",
      dark: "#5F6368",
      darker: "#1A2225",
    },
    companyContrast: {
      main: "#919EAB",
      contrastText: "#637381",
      contrastTextLighter: "#4E585C",
      contrastTextLight: "#454F5B",
      contrastTextDark: "#080F12",
    },
    companyBackgrounds: {
      main: "#E0F5F5",
      empty: "#F9FAFB",
      grey: "#DFE3E8",
    },
    companyCritical: {
      main: "#B42006",
      lighter: "#FFCAC0",
      dark: "#984900",
    },
    companyWarning: {
      main: "#FFF0D2",
      dark: "#DE3618",
    },
    companySuccess: {
      lighter: "#A4F8D5",
      dark: "#008060",
    },
  },
  typography: {
    fontSize: 16,
    h3: {
      fontWeight: 700,
      fontSize: "2.2rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.75rem",
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiOutlinedInput: {
      focused: {
        border: "1px solid #008060",
      },
      "& $notchedOutline": {
        border: "1px solid #008060",
      },
    },
  },
})
export default theme
