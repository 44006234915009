type props = {
  text: string
}

const HeroDescription = ({ text }: props) => {
  return (
    <>
      <span>{text}</span>
      <p>
        <strong>Todos los valores reflejados son aproximados. Pueden variar.</strong>
      </p>
    </>
  )
}

export default HeroDescription
