import styled from "@emotion/styled"

const SpinnerSvg = styled.svg`
  margin: auto;
  margin-top: 100px;
  background: rgba(255, 255, 255);
  display: block;
  shape-rendering: auto;
`

const LightSpinner = () => (
  <SpinnerSvg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#85e0b7"
      strokeWidth="8"
      r="35"
      strokeDasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.0101010101010102s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </SpinnerSvg>
)
export default LightSpinner
