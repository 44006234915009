import roundToAny from "./roundToAny"

export default function winLoseWhenMarginBaseCurrencyAreEqualButDistinctToUsd(
  inputCurrencyValue: number,
  contractSize: number,
  usdValue: number
) {
  /**
   * @param  {number} inputCurrencyValue Variación del precio al alza o baja.
   * @param  {number} contractSize Tamaño del contrato.
   * @param  {number} usdValue Valor equivalente del USD segun divisa.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: (Variación de precio) * Tamaño del contrato * (1 / Valor equivalente en USD).
   *
   * Calcula la ganancia o perdida de la operación cuando la moneda de margen y la moneda base son
   * distintas al USD pero son iguales entre si, y la retorna redondeada a 2 digitos.
   */

  const winLoseValue = roundToAny(inputCurrencyValue * contractSize * (1 / usdValue), 4)

  return winLoseValue
}
