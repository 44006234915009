export default function calculateSupportedLoss(equity: number, usedMargin: number) {
  /**
   * @param  {number} equity Equidad
   * @param  {number} usedMargin Margen utilizado.
   * @constant {number} stopOutMargin Margen de Stop Out.
   * @return {number} Perdida a soportar antes de irse a Stop Out
   *
   * Formula: Equidad - (Margen Stop Out * Margen Usado)
   *
   * Calcula la perdida a soportar antes de irse a Stop Out y la retorna redondeada.
   */

  const stopOutMargin = 0.5
  const supportedLoss = Math.abs(equity - usedMargin * stopOutMargin)

  return supportedLoss
}
