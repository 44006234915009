import roundToAny from "../../helpers/roundToAny"

export default function winLoseUSDCLP(
  takeProfitAmount: number,
  stopLossAmount: number,
  clpValue: number
) {
  /**
   * @param  {number} takeProfitAmount Monto de Take Profit.
   * @param  {number} stopLossAmount Monto de Stop Loss.
   * @param  {number} clpValue Valor Clp.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: TP / clp Value.
   * Formula: SL / clp Value.
   *
   * Calcula la ganancia o perdida de la operación por StopLoss o Take Profit, cuando la moneda
   * base es USD y la de margen CLP, y la retorna redondeada a 2 digitos.
   */
  const winByTakeProfit = roundToAny(takeProfitAmount / clpValue, 2)
  const lossByStopLoss = roundToAny(stopLossAmount / clpValue, 2)
  return { winByTakeProfit, lossByStopLoss }
}
