import roundToAny from "../../helpers/roundToAny"

export default function winLoseMarginBaseCurrencyDisctincOfUsd(
  takeProfitAmount: number,
  stopLossAmount: number,
  usdValue: number,
  usdValueEquivalent: number,
  data: InstrumentData
) {
  /**
   * @param  {number} takeProfitAmount Monto de Take Profit.
   * @param  {number} stopLossAmount Monto de Stop Loss.
   * @param  {number} usdValue Valor equivalente en USD.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: TP * USD Value.
   * Formula: SL * USD Value.
   *
   * Calcula la ganancia o perdida de la operación por StopLoss o Take Profit, cuando la moneda
   * de margen y la moneda base son distintas al USD, y la retorna redondeada a 2 digitos.
   */
  let winByTakeProfit = roundToAny(takeProfitAmount * usdValue, 2)
  let lossByStopLoss = roundToAny(stopLossAmount * usdValue, 2)

  if (
    (data.margin_currency === "AUD" && data.base_currency === "JPY") ||
    (data.margin_currency === "EUR" && data.base_currency === "JPY") ||
    (data.margin_currency === "EUR" && data.base_currency === "CLP") ||
    (data.margin_currency === "EUR" && data.base_currency === "NOK") ||
    (data.margin_currency === "GBP" && data.base_currency === "JPY") ||
    (data.margin_currency === "GBP" && data.base_currency === "NZD")
  ) {
    winByTakeProfit = roundToAny(takeProfitAmount / usdValueEquivalent, 2)
    lossByStopLoss = roundToAny(stopLossAmount / usdValueEquivalent, 2)
  }

  return { winByTakeProfit, lossByStopLoss }
}
