const usaFlag = (props: any) => (
  <svg
    width={21}
    height={14}
    viewBox="0 0 28 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="#FFC700"
      d="M0 0h21v14H0z"
    />
    <path
      fill="#fff"
      d="M-325.5-517.5h967v623h-967z"
    />
    <rect
      width={28}
      height={20}
      rx={2}
      fill="#fff"
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={28}
      height={20}
    >
      <rect
        width={28}
        height={20}
        rx={2}
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 0H0v1.333h28V0Zm0 2.667H0V4h28V2.667ZM0 5.333h28v1.334H0V5.333ZM28 8H0v1.333h28V8ZM0 10.667h28V12H0v-1.333Zm28 2.666H0v1.334h28v-1.334ZM0 16h28v1.333H0V16Zm28 2.667H0V20h28v-1.333Z"
        fill="#D02F44"
      />
      <path
        fill="#46467F"
        d="M0 0h12v9.333H0z"
      />
      <g filter="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.666 2a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm2.667 0A.667.667 0 1 1 4 2a.667.667 0 0 1 1.333 0Zm2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM10.666 2a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0ZM3.333 4a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm3.333-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm2 .667a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm2 .667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm-3.333.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm-2-.666a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0ZM2 5.333A.667.667 0 1 0 2 4a.667.667 0 0 0 0 1.333ZM4 6a.667.667 0 1 1-1.334 0A.667.667 0 0 1 4 6Zm2 .667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334ZM9.333 6A.667.667 0 1 1 8 6a.667.667 0 0 1 1.333 0ZM10 8a.667.667 0 1 0 0-1.333A.667.667 0 0 0 10 8Zm-2-.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0ZM4.666 8a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm-2-.667a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Z"
          fill="url(#c)"
        />
      </g>
    </g>
    <path
      stroke="#000"
      strokeWidth={5}
      d="M-325.5-517.5h967v623h-967z"
    />
    <defs>
      <linearGradient
        id="c"
        x1={1.333}
        y1={1.333}
        x2={1.333}
        y2={8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop
          offset={1}
          stopColor="#F0F0F0"
        />
      </linearGradient>
      <filter
        id="b"
        x={1.333}
        y={1.333}
        width={9.333}
        height={7.667}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

export default usaFlag
