export default function getVariationPriceStopLoss(entryPrice: string) {
  /**
   * @param  {string} entryPrice Precio de Entrada.
   * @return {number} Variación del precio de Stop Loss.
   *
   * Formula: entryPrice * 3%
   *
   * Calcula la variación de precio del Stop Loss.
   */

  const floatEntryPrice = parseFloat(entryPrice.replace(/,/g, ""))

  const variationStopLossPrice = (floatEntryPrice * 3) / 100
  return variationStopLossPrice
}
