import styled from "@emotion/styled"

import HeroDescription from "./HeroDescription"

const WrapperHero = styled.div`
  display: flex;
  justify-content: space-around;
  background: #0dbb73;
  color: #f9fafb;
`
const Container = styled.div`
  width: 1041px;
  display: flex;
  justify-content: space-between;
`
const ContainerLeft = styled.div`
  display: flex;
  width: 510px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`
const HeroTitle = styled.div`
  font-size: 40px;
  font-family: "Work Sans";
  letter-spacing: -0.5px;
  line-height: 42px;
  font-weight: 700;
  padding-top: 42px;
  @media (max-width: 768px) {
    padding: 34px 16px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`

const Description = styled.h4`
  font-size: 14px;
  font-family: "Roboto";
  line-height: 18px;
  font-weight: 500;
  color: #080f12;
  @media (max-width: 768px) {
    padding: 0 20px;
    display: none;
  }
`

// prettier-ignore
type props = {
  heroTitle: string;
  text: string;
  img?: JSX.Element;
}

const CustomHero = ({ heroTitle, text, img }: props) => (
  <WrapperHero>
    <Container>
      <ContainerLeft>
        <HeroTitle>{heroTitle}</HeroTitle>
        <Description>
          <HeroDescription text={text} />
        </Description>
      </ContainerLeft>
      <ContainerRight>{img}</ContainerRight>
    </Container>
  </WrapperHero>
)

export default CustomHero
