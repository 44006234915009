import roundToAny from "./roundToAny"

export default function winLoseWhenMarginBaseCurrencyAreDistinctOfUsd(
  instrumentPrice: number,
  inputCurrencyValue: number,
  contractSize: number,
  usdValue: number
) {
  /**
   * @param  {number} instrumentPrice El precio del instrumento.
   * @param  {number} inputCurrencyValue Variación del precio al alza o baja.
   * @param  {number} contractSize Tamaño del contrato.
   * @param  {number} usdValue Valor equivalente del USD segun divisa.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: (Variación de precio * (1 / Precio del instrumento) *
   * Tamaño del contrato * Valor equivalente en USD.
   *
   * Calcula la ganancia o perdida de la operación cuando la moneda de margen y la moneda base son
   * distintas al USD y la retorna redondeada a 2 digitos.
   */

  const winLoseValue = roundToAny(
    inputCurrencyValue * (1 / instrumentPrice) * contractSize * usdValue,
    4
  )

  return winLoseValue
}
