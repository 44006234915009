const CandlesIcon = () => (
  <svg
    width="87"
    height="87"
    viewBox="0 0 87 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group 20">
      <ellipse
        id="Ellipse 2"
        cx="43.4341"
        cy="43.4324"
        rx="43.4185"
        ry="42.7928"
        fill="#E0F5F5"
      />
      <g id="Group">
        <path
          id="Vector"
          d="M41.099 53.4707H31.8694C31.4121 53.4707 31.0413 53.0999 31.0413 52.6426V29.0405C31.0413 28.5828 31.4121 28.2124 31.8694 28.2124H41.099C41.3186 28.2124 41.5293 28.2997 41.6846 28.4549C41.8398 28.6102 41.9271 28.8209 41.9271 29.0405V52.6426C41.9271 52.8622 41.8398 53.0725 41.6846 53.2282C41.5293 53.3835 41.3186 53.4707 41.099 53.4707ZM32.7016 51.8145H40.2708V29.8687H32.6975L32.7016 51.8145Z"
          fill="#637381"
        />
        <path
          id="Vector_2"
          d="M36.4819 29.8023C36.0246 29.8023 35.6538 29.4319 35.6538 28.9742V17.8281C35.6538 17.3708 36.0246 17 36.4819 17C36.9392 17 37.3101 17.3708 37.3101 17.8281V29.0073C37.2923 29.4517 36.9267 29.8026 36.4819 29.8022V29.8023Z"
          fill="#637381"
        />
        <path
          id="Vector_3"
          d="M36.4819 61.4453C36.0246 61.4453 35.6538 61.0744 35.6538 60.6171V52.6423C35.6538 52.1847 36.0246 51.8142 36.4819 51.8142C36.9392 51.8142 37.3101 52.1847 37.3101 52.6423V60.6131C37.3112 60.8334 37.2243 61.0449 37.069 61.2013C36.9134 61.3573 36.7023 61.4453 36.4819 61.4453V61.4453Z"
          fill="#637381"
        />
        <path
          id="Vector_4"
          d="M69.1728 48.5596H59.9473C59.49 48.5596 59.1191 48.1892 59.1191 47.7315V35.5008C59.1191 35.0431 59.49 34.6727 59.9473 34.6727H69.1728C69.3924 34.6727 69.6031 34.7599 69.7584 34.9152C69.9136 35.0705 70.0009 35.2812 70.0009 35.5008V47.7315C70.0009 47.9511 69.9136 48.1618 69.7584 48.3171C69.6031 48.4724 69.3924 48.5596 69.1728 48.5596ZM60.7754 46.9034H68.3446V36.3289H60.7754V46.9034Z"
          fill="#637381"
        />
        <path
          id="Vector_5"
          d="M64.5598 36.258C64.1025 36.258 63.7317 35.8872 63.7317 35.4298V26.7759C63.7317 26.3186 64.1025 25.9478 64.5598 25.9478C65.0171 25.9478 65.3879 26.3186 65.3879 26.7759V35.4298C65.3879 35.6494 65.3007 35.8602 65.1454 36.0154C64.9901 36.1707 64.7794 36.258 64.5598 36.258Z"
          fill="#637381"
        />
        <path
          id="Vector_6"
          d="M64.5598 59.1388C64.1025 59.1388 63.7317 58.7684 63.7317 58.3107V47.7315C63.7317 47.2742 64.1025 46.9034 64.5598 46.9034C65.0171 46.9034 65.3879 47.2742 65.3879 47.7315V58.3107C65.3879 58.5303 65.3007 58.741 65.1454 58.8963C64.9901 59.0516 64.7794 59.1388 64.5598 59.1388Z"
          fill="#637381"
        />
        <path
          id="Vector_7"
          d="M55.1315 34.9831H45.906C45.4487 34.9831 45.0779 34.6123 45.0779 34.155V24.2175C45.0779 23.7598 45.4487 23.3893 45.906 23.3893H55.1315C55.3511 23.3893 55.5618 23.4766 55.7171 23.6319C55.8724 23.7871 55.9596 23.9979 55.9596 24.2175V34.155C55.9596 34.3746 55.8724 34.5849 55.7171 34.7406C55.5618 34.8958 55.3511 34.9831 55.1315 34.9831ZM46.7341 33.3268H54.3034V25.0456H46.7341V33.3268Z"
          fill="#637381"
        />
        <path
          id="Vector_8"
          d="M50.5183 24.9915C50.061 24.9915 49.6902 24.6206 49.6902 24.1633V17.8281C49.6902 17.3708 50.061 17 50.5183 17C50.9756 17 51.3464 17.3708 51.3464 17.8281V24.1633C51.3464 24.3829 51.2592 24.5937 51.1039 24.7489C50.9486 24.9042 50.7379 24.9915 50.5183 24.9915Z"
          fill="#637381"
        />
        <path
          id="Vector_9"
          d="M50.5183 50.1742C50.061 50.1742 49.6902 49.8038 49.6902 49.3461V34.154C49.6902 33.6963 50.061 33.3259 50.5183 33.3259C50.9756 33.3259 51.3464 33.6963 51.3464 34.154V49.3461C51.3464 49.5657 51.2592 49.7764 51.1039 49.9317C50.9486 50.0869 50.7379 50.1742 50.5183 50.1742Z"
          fill="#637381"
        />
        <path
          id="Vector_10"
          d="M27.0536 62.1333H17.8281C17.3708 62.1333 17 61.7625 17 61.3052V54.4731C17 54.0158 17.3708 53.645 17.8281 53.645H27.0536C27.2732 53.645 27.4836 53.7322 27.6392 53.8875C27.7945 54.0428 27.8818 54.2535 27.8818 54.4731V61.3052C27.8818 61.5248 27.7945 61.7355 27.6392 61.8908C27.4836 62.046 27.2732 62.1333 27.0536 62.1333ZM18.6563 60.477H26.2255V55.3012H18.6563V60.477Z"
          fill="#637381"
        />
        <path
          id="Vector_11"
          d="M22.4407 70C21.9834 70 21.6125 69.6292 21.6125 69.1719V61.3047C21.6125 60.8474 21.9834 60.4766 22.4407 60.4766C22.898 60.4766 23.2688 60.8474 23.2688 61.3047V69.1719C23.2688 69.3915 23.1816 69.6022 23.0263 69.7575C22.871 69.9128 22.6603 70 22.4407 70Z"
          fill="#637381"
        />
        <path
          id="Vector_12"
          d="M22.4407 55.301C21.9834 55.301 21.6125 54.9302 21.6125 54.4729V32.4532C21.6125 31.9959 21.9834 31.6251 22.4407 31.6251C22.898 31.6251 23.2688 31.9959 23.2688 32.4532V54.4729C23.2688 54.6925 23.1816 54.9032 23.0263 55.0585C22.871 55.2137 22.6603 55.301 22.4407 55.301Z"
          fill="#637381"
        />
      </g>
    </g>
  </svg>
)

export default CandlesIcon
