import calculatePositionSize from "./calculatePositionSize"
import calculateRiskPercentByChangeAmount from "./calculateRiskPercentByChangeAmount"
import minPositionSizeValidation from "./minPositionSizeValidation"

export default function inputSliderValidation(params: any) {
  const {
    inputValue,
    balance,
    setSliderRatio,
    data,
    oneMinLotSizeCases,
    lotSizeExcludeIndexCases,
    microLotCases,
    setMinLotSize,
    stopLoss,
    minLotSize,
    setPositionSize,
    lossOnStopLoss,
  } = params
  if (inputValue !== "") {
    const riskLevel = calculateRiskPercentByChangeAmount(inputValue.replace(/,/g, ""), balance)
    setSliderRatio(riskLevel)

    minPositionSizeValidation(
      data,
      oneMinLotSizeCases,
      lotSizeExcludeIndexCases,
      microLotCases,
      setMinLotSize
    )

    if (String(stopLoss) !== "") {
      const positionSize = calculatePositionSize(
        riskLevel,
        balance,
        data.volumen_in_currency,
        lossOnStopLoss
      )

      if (positionSize < minLotSize) {
        setPositionSize(minLotSize)
      } else {
        setPositionSize(positionSize)
      }
    }
  }
}
