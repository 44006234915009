export default function getMarketRanges(rangeOpenMarket: Array<string>, hoursRange: Array<number>) {
  for (const range of rangeOpenMarket) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const ranges = range.split("-")
    for (const range of ranges) {
      const time = range.trim()
      const [h, m] = time.split(":")
      const ms = new Date().setHours(parseInt(h), parseInt(m))
      const hourTimeStamp = Date.parse(new Date(ms).toLocaleString("en-US", { timeZone }))
      hoursRange.push(hourTimeStamp)
    }
  }
}
