import styled from "styled-components"

import lotText from "../helpers/lotText"

const DetailOperationText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4e585c;
  text-align: initial;

  &.title {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 6px;
  }

  &.sub-text {
    padding-left: 7px;
  }

  &.error {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -15px;
  }

  @media (max-width: 424px) {
    &.sub-text {
      padding-left: 0px;
      line-height: 18px;
    }
    &.title {
      padding-left: 0px;
      font-size: 14px;
      line-height: 18px;
    }
    &.error {
      padding-left: 0px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`

const Span = styled.span`
  display: block;
`

interface Props extends CommonTypes {
  contractValueUSD: string | number
  contractValueCLP: string | number
  contractSize?: string
  usdMargin: string | number
  clpMargin: string | number
  hasErrors: boolean
}

const OperationAnalysis = ({
  lotSize,
  contractValueUSD,
  contractValueCLP,
  contractSize,
  usdMargin,
  clpMargin,
  hasErrors,
}: Props) => {
  return (
    <>
      {!hasErrors ? (
        <>
          <DetailOperationText className="title">
            <strong>
              Con {lotSize} {lotText(lotSize)} estarías abriendo una operación de:
            </strong>
          </DetailOperationText>
          <DetailOperationText className="sub-text">
            <Span>
              <strong>Tamaño:</strong> {contractSize} contratos
            </Span>
            <Span>
              <strong>Valor del Contrato:</strong> ${contractValueUSD} USD / ${contractValueCLP} CLP
            </Span>
            <Span>
              <strong>Margen Requerido:</strong> ${usdMargin} USD / ${clpMargin} CLP
            </Span>
          </DetailOperationText>
        </>
      ) : (
        <DetailOperationText className="error">
          <strong>No se pudo cargar el análisis de la operación.</strong>
        </DetailOperationText>
      )}
    </>
  )
}
export default OperationAnalysis
