import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined"

export function returnedIcon(nemoInput: string, selectedNemo: string) {
  if (nemoInput === "" || nemoInput === " ") {
    return <SearchOutlinedIcon />
  } else if (nemoInput === selectedNemo) {
    return undefined
  } else {
    return <ArrowDropUpOutlinedIcon />
  }
}
