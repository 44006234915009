import roundToAny from "./roundToAny"

export default function calculateSpreadByCfd(
  pipValue: number,
  volumenInCurrency: number,
  spread: number,
  decimal: number
) {
  /**
   * @param  {number} pipValue Valor del pip.
   * @param  {number} volumenInCurrency Volumen en instrumento.
   * @param  {number} spread Valor spread.
   * @param  {number} decimal Numero de decimales a redondear.
   * @return {number} Valor del spread por CFD.
   *
   * Formula: ( Valor del pip / Volumen del instrumento * Valor del spread )
   *
   * Calcula el valor del spread por CFD y lo retorna redondeado a
   * un numero de decimales determinado.
   */

  const spreadByCfd = roundToAny((pipValue / volumenInCurrency) * spread, decimal)

  return spreadByCfd
}
