import { Route, Routes, Navigate } from "react-router-dom"
import { ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import theme from "./theme"
import AdvancedCalculator from "./components/advanced-calculator"
import BasicCalculator from "./components/basic-calculator"
import Navbar from "./components/ui/Navbar"
import MarginCalculator from "./components/margin-calculator"
import StopOutCalculator from "./components/stop-out-calculator"
import NotFoundPage from "./components/not-found-page"
import { InstrumentListContextProvider } from "./context/InstrumentListContext"

export const listPages: PageProps[] = [
  {
    path: "/basica",
    element: BasicCalculator,
    title: "Calculadora Básica",
  },
  {
    path: "/margen",
    element: MarginCalculator,
    title: "Calculadora Margen",
  },
  {
    path: "/stopout",
    element: StopOutCalculator,
    title: "Calculadora StopOut",
  },
  {
    path: "/avanzada",
    element: AdvancedCalculator,
    title: "Calculadora Avanzada",
  },
]

const redirectPages = [
  {
    path: "/",
    to: "/basica",
  },
  {
    path: "/calculadora-basica",
    to: "/basica",
  },
  {
    path: "/calculadora-margen",
    to: "/margen",
  },
  {
    path: "/calculadora-stopout",
    to: "/stopout",
  },
  {
    path: "/calculadora-avanzada",
    to: "/avanzada",
  },
]

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <>
        <Navbar listPages={listPages} />
        <InstrumentListContextProvider>
          <Routes>
            {listPages.map((page, idx) => (
              <Route
                key={`route-${idx}`}
                path={page.path}
                element={<page.element />}
              />
            ))}
            {redirectPages.map((page, idx) => (
              <Route
                key={`redirect-route-${idx}`}
                path={page.path}
                element={
                  <Navigate
                    to={page.to}
                    replace
                  />
                }
              />
            ))}
            <Route
              key="route-invalid"
              path="*"
              element={<NotFoundPage />}
            />
          </Routes>
        </InstrumentListContextProvider>
      </>
    </ThemeProvider>
  )
}

export default App
