const IconSearch = () => (
  <svg
    width={100}
    height={100}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={50}
      cy={50}
      r={50}
      fill="#E0F5F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0007 26C42.0672 26 34.0015 34.0656 34.0015 43.9992C34.0015 53.9329 42.0671 61.9985 52.0007 61.9985C61.9344 61.9985 69.9999 53.9329 69.9999 43.9992C69.9999 34.0656 61.9344 26 52.0007 26ZM52.0007 27.9985C60.8319 27.9985 68.0019 35.1681 68.0019 43.9998C68.0019 52.8314 60.8323 60.001 52.0007 60.001C43.1691 60.001 35.9995 52.8314 35.9995 43.9998C35.9995 35.1681 43.1691 27.9985 52.0007 27.9985Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0006 29.9988C44.2739 29.9988 38 36.2727 38 43.9994C38 51.7258 44.2739 58.0001 52.0006 58.0001C59.7271 58.0001 66.0013 51.7262 66.0013 43.9994C66.0013 36.2727 59.7274 29.9988 52.0006 29.9988ZM52.0006 31.9997C58.6238 31.9997 63.9999 37.3763 63.9999 43.9998C63.9999 50.6232 58.6236 55.999 52.0006 55.999C45.3772 55.999 40.0006 50.6227 40.0006 43.9998C40.0006 37.3768 45.3769 31.9997 52.0006 31.9997Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.1427 63.9281C40.3142 62.7565 40.3142 60.8571 39.1427 59.6855L36.3141 56.8569C35.1426 55.6854 33.2431 55.6854 32.0716 56.8569L22.8787 66.0498C21.7071 67.2214 21.7071 69.1208 22.8787 70.2924L25.7072 73.121C26.8788 74.2925 28.7782 74.2925 29.9498 73.121L39.1427 63.9281ZM37.7284 62.5138L28.5355 71.7067C28.1454 72.0971 27.5116 72.0971 27.1215 71.7067L24.293 68.8781C23.9025 68.488 23.9025 67.8542 24.293 67.4641C26.6059 65.1512 31.1726 60.5845 33.4859 58.2712C33.8763 57.8808 34.5097 57.8808 34.9002 58.2712L37.7284 61.0998C38.1188 61.4899 38.1188 62.1237 37.7284 62.5138Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.4357 53.9012C39.2586 53.6772 38.9944 53.5392 38.7095 53.5222C38.4245 53.5051 38.1456 53.6112 37.9434 53.8131L34.8995 56.8574C34.5094 57.2471 34.5094 57.8812 34.8995 58.2713L37.7284 61.1003C38.1185 61.4903 38.7526 61.4903 39.1424 61.1003L42.1866 58.0564C42.3885 57.8541 42.4947 57.5752 42.4776 57.2903C42.4605 57.0053 42.3225 56.7412 42.0985 56.564C41.1105 55.7842 40.2165 54.8893 39.4357 53.9012L39.4357 53.9012ZM38.5876 55.9982C39.0326 56.4951 39.5047 56.9672 40.0015 57.4122L38.4355 58.9782L37.0216 57.5642L38.5876 55.9982Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.001 38.9997C52.001 38.9997 51.776 38.9997 52.001 38.9997C50.344 38.9997 49.001 40.3427 49.001 41.9997V45.9995C49.001 47.6565 50.344 48.9995 52.001 48.9995C53.658 48.9995 55.001 47.6565 55.001 45.9995V41.9997C55.001 40.3427 53.658 38.9997 52.001 38.9997ZM52.001 46.9996C51.4489 46.9996 51.0009 46.5516 51.0009 45.9995V41.9997C51.0009 41.4476 51.4489 40.9996 52.001 40.9996C52.5531 40.9996 53.0011 41.4476 53.0011 41.9997V45.9995C53.0011 46.5516 52.5531 46.9996 52.001 46.9996Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.0005 36.9991V39.9991C51.0005 40.5512 51.4485 40.9996 52.0006 40.9996C52.5527 40.9996 53.0007 40.5512 53.0007 39.9991V36.9991C53.0007 36.4473 52.5527 35.999 52.0006 35.999C51.4485 35.999 51.0005 36.4473 51.0005 36.9991Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.0005 47.9997V50.9997C51.0005 51.5515 51.4485 51.9999 52.0006 51.9999C52.5527 51.9999 53.0007 51.5515 53.0007 50.9997V47.9997C53.0007 47.4476 52.5527 46.9993 52.0006 46.9993C51.4485 46.9993 51.0005 47.4476 51.0005 47.9997Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.0005 64.9996V72.9995C51.0005 73.5503 51.4485 74.0006 52.0006 73.9982C52.5527 73.9982 53.0007 73.5502 53.0007 72.9995V64.9996C53.0007 64.4488 52.5527 64.0008 52.0006 64.0008C51.4485 63.9985 51.0005 64.4488 51.0005 64.9996Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.001 66.9993V72.9996C41.001 73.5514 41.449 73.9987 42.0008 73.9987C42.5529 73.9987 43.0009 73.5514 43.0009 72.9996V66.9993C43.0009 66.4475 42.5529 66.0002 42.0008 66.0002C41.449 66.0002 41.001 66.4475 41.001 66.9993Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.001 64.9996V72.9995C61.001 73.5503 61.449 74.0006 62.0011 73.9982C62.5529 73.9982 63.0009 73.5502 63.0009 72.9995V64.9996C63.0009 64.4488 62.5529 64.0008 62.0011 64.0008C61.449 63.9985 61.001 64.4488 61.001 64.9996Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2937 65.7067L54.2937 68.7067C54.6838 69.0968 55.3175 69.0971 55.708 68.7067C56.0984 68.3163 56.098 67.6825 55.708 67.2924L52.708 64.2924C52.3179 63.9023 51.6841 63.902 51.2937 64.2924C50.9032 64.6828 50.9036 65.3166 51.2937 65.7067Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.2934 67.7064L44.2934 70.7064C44.6835 71.0965 45.3176 71.0968 45.7077 70.7064C46.0981 70.3164 46.0978 69.6822 45.7077 69.2925L42.7077 66.2925C42.3176 65.9024 41.6838 65.9021 41.2934 66.2921C40.9033 66.6825 40.9037 67.3167 41.2934 67.7064V67.7064Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2936 63.707L64.2937 66.707C64.6834 67.0967 65.3175 67.0974 65.708 66.707C66.098 66.3166 66.0977 65.6828 65.7076 65.2927L62.7076 62.2927C62.3179 61.9026 61.6837 61.9023 61.2937 62.2927C60.9032 62.6831 60.9036 63.3169 61.2937 63.707H61.2936Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.2937 64.2924L48.2937 67.2924C47.9036 67.6825 47.9032 68.3163 48.2937 68.7067C48.6841 69.0971 49.3179 69.0968 49.7079 68.7067L52.708 65.7067C53.098 65.3166 53.0984 64.6828 52.708 64.2924C52.3176 63.902 51.6838 63.9023 51.2937 64.2924Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.2942 66.293L38.2942 69.293C37.9041 69.6827 37.9038 70.3169 38.2939 70.7069C38.6843 71.0973 39.3184 71.097 39.7081 70.7069L42.7082 67.7069C43.0982 67.3172 43.0986 66.6831 42.7082 66.2926C42.3181 65.9026 41.684 65.9029 41.2942 66.293L41.2942 66.293Z"
      fill="#637381"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2942 62.2927L58.2941 65.2927C57.9041 65.6828 57.9037 66.3166 58.2941 66.707C58.6845 67.0974 59.3183 67.0967 59.7084 66.707L62.7084 63.707C63.0982 63.3169 63.0989 62.6831 62.7084 62.2927C62.318 61.9023 61.6842 61.9026 61.2942 62.2927Z"
      fill="#637381"
    />
  </svg>
)

export default IconSearch
