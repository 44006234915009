import getInstrumentData from "./getInstrumentData"
import getNemo from "./getNemo"

export default async function loadInstrumentData(
  selectedNemo: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>,
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>,
  setData: (value: React.SetStateAction<InstrumentData | undefined>) => void
) {
  const result = await getInstrumentData(getNemo(selectedNemo))

  if (typeof result === "string") {
    setErrorMessage({ serverError: result })
    setHasErrors(true)
    setData(undefined)
  } else {
    setData(result)
    setHasErrors(false)
    setErrorMessage({ serverError: undefined })
  }
}
