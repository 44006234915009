import roundToAny from "../../helpers/roundToAny"

export default async function getUsdClpBalance(
  balance: string | undefined,
  clpValue: string,
  currency: string
) {
  let balanceClp = 0
  let balanceUsd = 0
  const FloatUsdClpValue = parseFloat(clpValue)

  if (typeof balance === "string" && balance !== "") {
    if (currency === "USD") {
      balanceClp = parseFloat(balance || "0")
      balanceUsd = roundToAny(balanceClp / FloatUsdClpValue, 2)
      return balanceUsd
    }
    balanceUsd = parseFloat(balance || "0")
    balanceClp = Math.round(balanceUsd * FloatUsdClpValue)
    return balanceClp
  }
  return 0
}
