import moment from "moment-timezone"

export default function getOpenCloseMarketHour(data: InstrumentData) {
  const marketHoursByDay: MarketHours[] = []
  const clientTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date().toISOString().split("T")[0].replace(/-/g, "/")
  const dayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "sunday"]
  const spanishDays: SpanishDays = {
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    sunday: "Domingo",
  }

  dayNames.forEach((day: string) => {
    const newArrayHour: string[] = []
    if (data !== undefined) {
      if (day in data && data[day]) {
        const rangeHour = data[day].split(",")
        for (const range of rangeHour) {
          const time = range.trim().replace(/24:00/g, "23:59")
          const [firstHourRange, secondHourRange] = time.split("-")
          const firstHourRangeFilter = firstHourRange.trim()
          const secondHourRangeFilter = secondHourRange.trim()
          const newDateRangeA = date + " " + firstHourRangeFilter
          const newDateRangeB = date + " " + secondHourRangeFilter
          const newDateMomentA = moment(newDateRangeA)
          const newDateMomentB = moment(newDateRangeB)
          const originTimeZone = "America/Santiago"

          const hourFormatedRangeA = moment
            .tz(newDateMomentA.format("HH:mm"), "HH:mm", originTimeZone)
            .tz(clientTimeZone)
            .format("HH:mm")
          const hourFormatedRangeaB = moment
            .tz(newDateMomentB.format("HH:mm"), "HH:mm", originTimeZone)
            .tz(clientTimeZone)
            .format("HH:mm")

          newArrayHour.push(`${hourFormatedRangeA}-${hourFormatedRangeaB} `)
        }
        const dayObject = {
          day: spanishDays[day],
          hours: newArrayHour,
        }
        marketHoursByDay.push(dayObject)
      }
    }
  })
  return { marketHoursByDay }
}
