import React, { useState } from "react"
import styled from "styled-components"

const TabsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  width: 509px;
  padding-top: 42px;
  //padding-top: 22px;
  background-color: #ffffff;
  @media (max-width: 424px) {
    width: 100%;
    padding-top: 15px;
  }
`

const TabContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 48px;
  cursor: pointer;
  background-color: #c4cdd5;
`

const TabText = styled.span`
  position: relative;
  z-index: 1;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  line-height: 1em;
  color: #f5f6fa;
  display: grid;
  justify-content: center;
  justify-items: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  &:hover {
    color: #ffffff;
  }

  &.active-tab {
    color: #ffffff;
    font-weight: 600;
  }
`

type Props = {
  tabs: Tabs[]
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>
  tabSliderClassName: string
}

const TabsComponent = ({ tabs, setSelectedTab, tabSliderClassName }: Props) => {
  const [showSection, setShowSection] = useState(tabs[0]["key"])

  const onClickTab = (tab: string) => () => {
    setShowSection(tab)
    setSelectedTab(tab)
  }

  return (
    <TabsContainer>
      {tabs.map(({ key, name }) => (
        <TabContainer
          key={`idx-tabs-${key}`}
          className={showSection === key ? "active" : undefined}
          onClick={onClickTab(key)}
        >
          <TabText className={showSection === key ? "active-tab" : undefined}>{name}</TabText>
        </TabContainer>
      ))}
      <span className={tabSliderClassName}></span>
    </TabsContainer>
  )
}

export default TabsComponent
