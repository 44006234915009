import styled from "styled-components"
import WarningIcon from "../../../assets/warningIcon"

const TextWrapper = styled.span`
  display: flex;
  gap: 5px;
  justify-content: center;
`

const Text = styled.span`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &.warning-text {
    display: flex;
    line-height: 1.2em;
    font-weight: 700;
  }
`
export default function renderStopOutText(
  marginLevel: number,
  supportedLoss: number,
  amountToDeposit: number
) {
  const baseText = (
    <>
      <strong>Puedes aguantar una pérdida de hasta ${supportedLoss}</strong>, con esa pérdida te
      irías en stop out y se podrian cerrar tus operaciones abiertas automáticamente.
    </>
  )
  const warningText = (
    <p>
      <TextWrapper>
        <WarningIcon />
        <Text className="warning-text">No se aconseja rescatar dinero a este nivel.</Text>
      </TextWrapper>
    </p>
  )

  if (marginLevel >= 500) {
    return (
      <Text>
        {baseText}
        <p>
          <strong>Con este nivel de margen, estás en un nivel saludable</strong>, puedes realizar
          mas operaciones sin problema (Con tamaño adecuado a tu Equidad).
        </p>
      </Text>
    )
  }

  if (marginLevel < 500 && marginLevel >= 300) {
    return (
      <Text>
        {baseText}
        <p>
          <strong>Con este nivel de margen, estás en un nivel saludable</strong>, puedes realizar
          mas operaciones pero con cautela.
        </p>
      </Text>
    )
  }
  if (marginLevel < 300 && marginLevel >= 150) {
    return (
      <Text>
        {baseText}
        <p>
          <strong>Con este nivel de margen, estás en un nivel inferior al 300% recomendado</strong>,
          se recomienda gestionar operaciones o depositar. Atento a la volatilidad del Mercado.
        </p>
        {warningText}
      </Text>
    )
  }
  if (marginLevel < 150 && marginLevel >= 90) {
    return (
      <Text>
        {baseText}
        <p>
          <strong>
            Con este nivel de margen, estás cercano a Margin call, se recomienda gestionar
            operaciones o depositar.
          </strong>{" "}
          Atento a la volatilidad del Mercado. Si depositas mas de ${amountToDeposit} llegarías a un
          nivel de margen de 300% lo que se considera saludable.
        </p>
        {warningText}
      </Text>
    )
  }
  if (marginLevel < 90 && marginLevel >= 50) {
    return (
      <Text>
        {baseText}
        <p>
          <strong>
            Con este nivel de margen, llegarías a Margin call, se recomienda gestionar operaciones o
            depositar.
          </strong>{" "}
          Si depositas más de ${amountToDeposit} llegarías a un nivel de margen de 300% lo que se
          considera saludable.
        </p>
        {warningText}
      </Text>
    )
  }
  if (Object.is(marginLevel, NaN)) {
    return (
      <Text>
        <strong>
          Nivel de margen indeterminado. Por favor, ingresa valores válidos para calcular el nivel
          de margen adecuado.
        </strong>
      </Text>
    )
  }
  return (
    <Text>
      <strong>
        Con este nivel de margen, te irías en stop out y se cerrarían todas tus operaciones abiertas
        simultáneamente.
      </strong>{" "}
      Si depositas ${amountToDeposit} llegarías a un nivel de margen de 300% lo que se considera
      saludable.
      {warningText}
    </Text>
  )
}
