import currentHourIsBetweenHoursRange from "./currentHourIsBetweenHoursRange"
import getDayName from "./getDayName"
import getMarketRanges from "./getMarketRanges"

export default function isMarketOpen(data: InstrumentData | any) {
  const today = getDayName()
  const timeZone = "America/Santiago"
  const actualTimestamp = Date.parse(new Date().toLocaleString("en-US", { timeZone }))
  const hoursRange: Array<number> = []
  let openMarketHours = ""
  let rangeOpenMarket: Array<string> = []

  if (data !== undefined) {
    if (today in data) {
      openMarketHours = data[today]
      if (openMarketHours !== null || openMarketHours !== "") {
        rangeOpenMarket = openMarketHours.split(",")
        getMarketRanges(rangeOpenMarket, hoursRange)
        return currentHourIsBetweenHoursRange(hoursRange, actualTimestamp)
      }
    }
    return false
  }
}
