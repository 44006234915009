import getInstrumentData from "./getInstrumentData"
import getInstrumentWhenMarginBaseCurrencyAreEqualsButDistinctOfUsd from "./getInstrumentWhenMarginBaseCurrencyAreEqualsButDistinctOfUsd"
import getInstrumentWhenBaseMarginCurrencyAreDistinctOfUsd from "./getInstrumentWhenBaseMarginCurrencyAreDistinctOfUsd"

export default async function getUsdValueForContractValue(
  selectedOperation: string,
  data: InstrumentData
) {
  let instrument: InstrumentData | undefined = undefined

  if (
    data.base_currency === data.margin_currency &&
    data.base_currency !== "USD" &&
    data.margin_currency !== "USD"
  ) {
    instrument = await getInstrumentWhenMarginBaseCurrencyAreEqualsButDistinctOfUsd(data)
  } else if (!data.base_currency.includes("USD") && data.margin_currency !== "USD") {
    instrument = await getInstrumentWhenBaseMarginCurrencyAreDistinctOfUsd(data)
  } else if (data.base_currency === "USD") {
    instrument = await getInstrumentData(`${data.base_currency}USD`)
  } else if (data.margin_currency === "USD") {
    instrument = await getInstrumentData(`USD${data.base_currency}`)
  }

  if (instrument !== undefined) {
    if (selectedOperation === "Compra") {
      return instrument.sell_price
    }
    return instrument.buy_price
  }
  return ""
}
