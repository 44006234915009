import getWinLostUsdValue from "./getWinLostUsdValue"

export default function getWinLostClpValue(
  data: InstrumentData,
  inputCurrencyValue: string | undefined,
  contractSize: string | undefined,
  contractValueUSD: string,
  instrumentPrice: string,
  values: ContextValues
) {
  const { clpValue } = values
  const floatUsdClpValue = parseFloat(clpValue)
  let winLoseValue: number | string = 0
  let roundWinLoseValue = 0

  if (inputCurrencyValue !== undefined && contractSize !== undefined && data !== undefined) {
    winLoseValue = getWinLostUsdValue(
      data,
      inputCurrencyValue,
      contractSize,
      contractValueUSD,
      instrumentPrice,
      values
    )
    //eslint-disable-next-line
    const floatWinLoseValue = parseFloat(String(winLoseValue).replace(/\,/g, ""))
    roundWinLoseValue = Math.round(floatWinLoseValue * floatUsdClpValue)
    if (winLoseValue < 0.001) {
      roundWinLoseValue = floatWinLoseValue * floatUsdClpValue
    }

    if (data.margin_currency === "USD" && data.base_currency === "CLP") {
      inputCurrencyValue = inputCurrencyValue.replace(/,/g, "").replace("$", "")
      const floatInputCurrencyValue = parseFloat(inputCurrencyValue)
      contractSize = contractSize.replace(/,/g, "")
      const floatContractSize = parseFloat(contractSize)
      roundWinLoseValue = floatContractSize * floatInputCurrencyValue
    }
  }

  if (Object.is(roundWinLoseValue, NaN)) {
    return roundWinLoseValue
  }

  return Intl.NumberFormat("en-US").format(roundWinLoseValue)
}
