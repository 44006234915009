import calculateSpreadByCfd from "./calculateSpreadByCfd"
import getPipValue from "./getPipValue"
import roundToAny from "./roundToAny"

export default function getSpreadByCfdValidations(
  data: InstrumentData | undefined,
  floatSpread: number,
  floatProSpread: number,
  floatPremiumSpread: number,
  floatUsdClpValue: number
) {
  let spread: number | string = 0
  let proSpread: number | string = 0
  let premiumSpread: number | string = 0
  let spreadClp: number | string = 0
  let proSpreadClp: number | string = 0
  let premiumSpreadClp: number | string = 0
  if (data !== undefined) {
    const specialCases = ["Flotante", "Solo Cierre"]

    const pipValue = getPipValue(data)
    data.volumen_in_currency = data.volumen_in_currency.replace(".", "")
    const volumenInCurrency = parseInt(data.volumen_in_currency, 10)

    if (pipValue !== undefined) {
      spread = calculateSpreadByCfd(pipValue, volumenInCurrency, floatSpread, 8)
      proSpread = calculateSpreadByCfd(pipValue, volumenInCurrency, floatProSpread, 8)
      premiumSpread = calculateSpreadByCfd(pipValue, volumenInCurrency, floatPremiumSpread, 8)
      spreadClp = Math.round(spread * floatUsdClpValue)
      proSpreadClp = Math.round(proSpread * floatUsdClpValue)
      premiumSpreadClp = Math.round(premiumSpread * floatUsdClpValue)

      if (proSpread * floatUsdClpValue < 1) {
        proSpreadClp = roundToAny(proSpread * floatUsdClpValue, 2)
        premiumSpreadClp = roundToAny(premiumSpread * floatUsdClpValue, 2)
      }
      if (spread * floatUsdClpValue < 1) {
        spreadClp = roundToAny(spread * floatUsdClpValue, 2)
      }
    }

    if (specialCases.includes(data.pro_spread)) {
      proSpread = data.pro_spread
      premiumSpread = data.premium_spread
      proSpreadClp = data.pro_spread
      premiumSpreadClp = data.premium_spread
    }
    if (specialCases.includes(data.spread)) {
      spread = data.spread
      spreadClp = data.spread
    }
  }
  return { spread, spreadClp, proSpread, proSpreadClp, premiumSpread, premiumSpreadClp }
}
