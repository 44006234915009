import { useEffect, useState } from "react"
import styled from "styled-components"

import CustomToolTip from "../ui/CustomToolTip"

const MarketContainer = styled.div`
  display: flex;
  align-items: center;
`

const MarketButton = styled.div`
  width: 169px;
  height: 28px;
  background: #dfe3e8;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.market-is-open {
    background: #2ba770;
  }
  @media (max-width: 424px) {
    width: 80%;
    text-align: right;
  }
`

const MarketText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #637381;

  &.market-is-open {
    color: #f9fafb;
  }
`

const ToolTipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1px;
  cursor: pointer;

  @media (max-width: 424px) {
    visibility: hidden;
  }
`

type Props = {
  marketIsOpen: boolean | undefined
}

const MarketComponent = ({ marketIsOpen }: Props) => {
  const componentsProps = {
    popper: { style: { width: "140px" } },
    arrow: { style: { height: "8px", width: "12px", color: "#2ba770" } },
    tooltip: { style: { color: "#F9FAFB", backgroundColor: "#2ba770", opacity: "0.9" } },
  }

  const [marketData, setMarketData] = useState<MarketData>({
    title: "",
    marketClass: "",
    toolTipText: "",
    iconProps: {
      width: "",
      height: "",
      color: "",
      marginLeft: "",
    },
  })

  useEffect(() => {
    if (marketIsOpen) {
      setMarketData({
        title: "Mercado Abierto",
        marketClass: "market-is-open",
        toolTipText: "Con el mercado abierto, el precio del instrumento estará en movimiento.",
        iconProps: {
          width: "20px",
          height: "20px",
          color: "#F9FAFB",
          marginLeft: "2px",
        },
      })
    } else {
      setMarketData({
        title: "Mercado Cerrado",
        marketClass: "market-is-closed",
        toolTipText: "Con el mercado cerrado, el precio del instrumento no tendrá variaciones.",
        iconProps: {
          width: "20px",
          height: "20px",
          color: "#637381",
          marginLeft: "2px",
        },
      })
    }
  }, [marketIsOpen])

  return (
    <MarketContainer>
      <MarketButton className={marketData.marketClass}>
        <MarketText className={marketData.marketClass}>{marketData.title}</MarketText>
        <ToolTipContainer>
          <CustomToolTip
            title={marketData.toolTipText}
            componentsProps={componentsProps}
            placement="top-start"
            iconProps={marketData.iconProps}
            iconClass={marketData.marketClass}
          />
        </ToolTipContainer>
      </MarketButton>
    </MarketContainer>
  )
}

export default MarketComponent
