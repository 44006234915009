import roundToAny from "../../helpers/roundToAny"

export default function calculateRiskPercentByChangeAmount(
  riskAmount: string | number,
  balance: string | undefined
) {
  /**
   * @param  {string | number} riskAmount Stop Loss.
   * @param  {string | undefined} balance Balance.
   * @return {number} Porcentaje nivel de riesgo.
   *
   * Formula: Monto Riesgo/Balance * 100
   *
   * Calcula el porcentaje del nivel de riesgo.
   */

  const floatBalance = parseFloat(balance || "0")

  if (typeof riskAmount === "string") {
    riskAmount = parseFloat(riskAmount)
  }

  const riskLevel = roundToAny((riskAmount / floatBalance) * 100, 2)
  return riskLevel
}
