const PinkLineVector = () => (
  <svg
    width="24"
    height="2"
    viewBox="0 0 24 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="PinkLineVector"
      d="M0.338867 1.58334H23.8627V0H0.338867V1.58334Z"
      fill="#B42006"
    />
  </svg>
)

export default PinkLineVector
