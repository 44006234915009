import { useState } from "react"
import styled from "styled-components"

import CustomSimulationInformation from "../shared/CustomSimulationInformation"
import CustomSimulator from "../shared/CustomSimulator"
import DepositHere from "../shared/DepositHere"
import SimulationMarginText from "./SimulationMarginText"
import { heroOptions } from "../helpers/heroOptions"
import CustomHero from "../ui/CustomHero"
import Footer from "../ui/Footer"

const DepositContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
`

const MarginCalculator = () => {
  const [formState, setFormState] = useState<FormValues>({
    equity: "",
    usedMargin: "",
    deposit: "",
    withdrawal: "",
  })
  const [showSimulationInformation, setShowSimulationInformation] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const title = "Simular niveles de Margen"
  const subText = `Para hacer el cálculo necesitas ingresar tu Equidad disponible en la MetaTrader 5.`
  const buttonText = "Simular Nivel de Margen"
  const knowMoreUrl = "https://url-video-pendiente.com"
  const depositText = "¿Bajo en Balance?"

  return (
    <>
      <CustomHero
        heroTitle={heroOptions().heroTitle}
        text={heroOptions().heroText}
        img={heroOptions().heroImg()}
      />
      <CustomSimulator
        title={title}
        subText={subText}
        buttonText={buttonText}
        knowMoreUrl={knowMoreUrl}
        formState={formState}
        setFormState={setFormState}
        setShowSimulationInformation={setShowSimulationInformation}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        hasErrors={hasErrors}
        setHasErrors={setHasErrors}
      />
      <CustomSimulationInformation
        showSimulationInformation={showSimulationInformation}
        formState={formState}
        hasErrors={hasErrors}
      >
        <SimulationMarginText formState={formState} />
      </CustomSimulationInformation>
      <DepositContainer>
        <DepositHere text={depositText} />
      </DepositContainer>
      <Footer />
    </>
  )
}

export default MarginCalculator
