export default function getSpreadExplanationText(
  spreadTypes: SpreadTypes,
  startSpread: number | string,
  proSpread: number | string,
  premiumSpread: number | string,
  currency: string
) {
  let startSpreadText = ""
  let proSpreadText = ""
  let premiumSpreadText = ""

  proSpreadText = `El spread de la operación es $${proSpread} ${currency} si eres cliente PRO`
  premiumSpreadText = `o $${premiumSpread} ${currency} si eres cliente PREMIUM.`

  if (spreadTypes.proSpread === "Flotante" && spreadTypes.proSpread === spreadTypes.premiumSpread) {
    proSpreadText = `El spread de la operación es ${proSpread}, si eres cliente PRO/PREMIUM.`
    premiumSpreadText = `Esto implica que el spread varía constantemente dependiendo de las
    variaciones en el mercado. Si quieres saber el valor actual,
    puedes revisarlo en la MetaTrader 5.`
  }
  if (
    spreadTypes.proSpread === "Solo Cierre" &&
    spreadTypes.proSpread === spreadTypes.premiumSpread
  ) {
    proSpreadText = `El spread de la operación es ${proSpread}, si eres cliente PRO/PREMIUM.`
    premiumSpreadText = `Esto implica que el instrumento solo permite cerrar órdenes que ya estén
    abiertas, sin considerar órdenes nuevas. Para mas información, contacta a uno de nuestros ejecutivos.`
  }
  if (spreadTypes.proSpread === "Flotante" && spreadTypes.premiumSpread === "Solo Cierre") {
    proSpreadText = `El spread de la operación es ${proSpread}, si eres cliente PRO.
    Este tipo de spread varía constantemente dependiendo de las
    variaciones en el mercado. Si quieres saber el valor actual,
    puedes revisarlo en la MetaTrader 5.`
    premiumSpreadText = `Si eres cliente PREMIUM, el spread es ${premiumSpread}.
    Esto implica que el instrumento solo permite cerrar órdenes que ya estén abiertas, sin
    considerar órdenes nuevas. Para mas información, contacta a uno de nuestros ejecutivos.`
  }
  if (spreadTypes.proSpread === "Solo Cierre" && spreadTypes.premiumSpread === "Flotante") {
    proSpreadText = `El spread de la operación es ${proSpread}, si eres cliente PRO.
    Esto implica que el instrumento solo permite cerrar órdenes que ya estén abiertas, sin
    considerar órdenes nuevas. Para mas información, contacta a uno de nuestros ejecutivos.`
    premiumSpreadText = `Si eres cliente PREMIUM, el spread es ${premiumSpread}.
    Este tipo de spread varía constantemente dependiendo de las
    variaciones en el mercado. Si quieres saber el valor actual,
    puedes revisarlo en la MetaTrader 5.`
  }

  startSpreadText = `El spread de la operación es $${startSpread} ${currency} si eres cliente START.`

  if (spreadTypes.spread === "Flotante") {
    startSpreadText = `El spread de la operación es ${startSpread}, si eres cliente START. Esto
    implica que el spread varía constantemente dependiendo de las variaciones en el
    mercado. Si quieres saber el valor actual, puedes revisarlo en la MetaTrader 5.`
  }

  if (spreadTypes.spread === "Solo Cierre") {
    startSpreadText = `El spread de la operación es ${startSpread}, si eres cliente START. Esto
    implica que el instrumento solo permite cerrar órdenes que ya estén abiertas, sin considerar órdenes nuevas.
    Para mas información, contacta a uno de nuestros ejecutivos.`
  }
  return { startSpreadText, proSpreadText, premiumSpreadText }
}
