import calculateContractSize from "./calculateContractSize"

export default function getContractSize(
  lotSize: number | undefined,
  data: InstrumentData | undefined
) {
  let volumenInCurrency = 0
  if (data !== undefined) {
    if (data.volumen_in_currency !== undefined) {
      data.volumen_in_currency = data.volumen_in_currency.replace(".", "")
      volumenInCurrency = parseInt(data.volumen_in_currency, 10)
    }
  }
  if (lotSize !== undefined) {
    const contractSize = calculateContractSize(volumenInCurrency, lotSize)
    return Intl.NumberFormat("en-US").format(contractSize)
  }
}
