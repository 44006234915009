import styled from "styled-components"
import getSpreadExplanationText from "../helpers/getSpreadExplanationText"

const DisclaimerText = styled.p`
  font-size: 12px;
`

type Props = {
  lotSize?: number
  calculatedOperationValues: CalculatedValues
  data: InstrumentData
  margin: string
  leverage: string | number
  contractValue: string
  currency: string
  currencyShowed?: string
  inputCurrencyValue: string
  instrumentPrice: string
  upPrice: number
  downPrice: number
  winLostValue: string | number | void
  spreadTypes: SpreadTypes
  startSpread: number | string
  proSpread: number | string
  premiumSpread: number | string
  sellSwap: number | string
  buySwap: number | string
  descountTextSellSwap: string
  descountTextBuySwap: string
  selectedOperation: string
}

const ExplanationText = ({
  lotSize,
  calculatedOperationValues,
  data,
  margin,
  leverage,
  contractValue,
  currency,
  currencyShowed,
  inputCurrencyValue,
  instrumentPrice,
  upPrice,
  downPrice,
  winLostValue,
  spreadTypes,
  startSpread,
  proSpread,
  premiumSpread,
  descountTextBuySwap,
  descountTextSellSwap,
  sellSwap,
  buySwap,
  selectedOperation,
}: Props) => {
  const winText = selectedOperation === "Compra" ? "ganas" : "pierdes"
  const loseText = selectedOperation === "Compra" ? "pierdes" : "ganas"
  const { contractSize } = calculatedOperationValues
  const { account_type } = data
  const { startSpreadText, proSpreadText, premiumSpreadText } = getSpreadExplanationText(
    spreadTypes,
    startSpread,
    proSpread,
    premiumSpread,
    currency
  )

  return (
    <>
      <div>
        El tamaño de la operación introducida equivale a {lotSize} lotes o {contractSize} contratos.
        Para abrir una operación en {data.name} de este tamaño,{" "}
        <strong>{`necesitas usar $${margin} de tu margen libre. `}</strong>
        Gracias a un apalancamiento x{leverage}, el tamaño de tu operación es de ${contractValue}{" "}
        {currency}.
        <p>
          Tu movimiento introducido equivale a {currencyShowed} {inputCurrencyValue} por contrato en{" "}
          {data.nemo}. Actualmente el precio del instrumento es de ${instrumentPrice}, por lo que el
          movimiento de {currencyShowed} {inputCurrencyValue} lo llevaría a un precio de $
          {downPrice} a la baja o de ${upPrice} al alza. Como tu operación seleccionada es{" "}
          {selectedOperation}, su tamaño es de {contractSize} contratos y su variación es de{" "}
          {currencyShowed} {inputCurrencyValue} por contrato, {winText} {currency} ${winLostValue}{" "}
          si el precio sube, o {loseText} {currency} ${winLostValue} si el precio baja.
        </p>
        <p>
          {account_type === "start" ? (
            <strong>{startSpreadText}</strong>
          ) : (
            <strong>
              {proSpreadText} {premiumSpreadText}
            </strong>
          )}
        </p>
        <p>
          El proceso SWAP de venta {descountTextSellSwap} ${sellSwap} {currency} diario promedio en
          tu balance.
        </p>
        <p>
          El proceso SWAP de compra {descountTextBuySwap} ${buySwap} {currency} diario promedio en
          tu balance.
        </p>
        <DisclaimerText>
          *Futuros sin SWAP, ya que va incluido en contrato. Operaciones con éstos, se cierran en la
          fecha de expiración del contrato.
        </DisclaimerText>
      </div>
    </>
  )
}

export default ExplanationText
