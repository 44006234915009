import { useRef, useEffect } from "react"

export default function useEffectAfterFirstRender(fn: any, inputs: Array<any>) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      return fn()
    }
    didMountRef.current = true
  }, inputs)
}
