import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import InputAdornment from "@mui/material/InputAdornment"

export default function renderPropsInput(isEmpty: boolean, isFocused: boolean) {
  const emptyClass = "empty-field"
  const cssTextFieldInputProps = {
    endAdornment: (
      <InputAdornment position="start">
        <AttachMoneyOutlinedIcon className={isFocused ? "focused-input" : ""} />
      </InputAdornment>
    ),
    type: "text",
    className: "",
  }
  if (isEmpty) {
    cssTextFieldInputProps.className = emptyClass
    cssTextFieldInputProps.endAdornment = (
      <InputAdornment position="start">
        <AttachMoneyOutlinedIcon className={isFocused ? emptyClass : ""} />
      </InputAdornment>
    )
  }
  return cssTextFieldInputProps
}
