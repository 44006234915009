import roundToTwo from "./roundToTwo"

export default function winLoseUSDCLPCase(
  instrumentPrice: number,
  inputCurrencyValue: number,
  contractSize: number,
  contractValueUSD: number
) {
  /**
   * @param  {number} instrumentPrice El precio del instrumento.
   * @param  {number} inputCurrencyValue Variación del precio al alza o baja.
   * @param  {number} contractSize Tamaño del contrato.
   * @param  {number} contractValueUSD Valor del contrato en USD.
   * @return {number} Ganancia o perdida de la operación redondeada a 2 digitos.
   *
   * Formula: (Precio del instrumento + Variación de precio) * Tamaño del contrato -
   * (Valor del contrato en USD * Precio del instrumento) / Precio del instrumento
   *
   * Calcula la ganancia o perdida de la operación cuando la divisa seleccionada es el USDCLP y
   * la retorna redondeada a 2 digitos.
   */

  const winLoseValue = roundToTwo(
    ((instrumentPrice + inputCurrencyValue) * contractSize - contractValueUSD * instrumentPrice) /
      instrumentPrice
  )

  return winLoseValue
}
