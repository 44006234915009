import roundToTwo from "../../helpers/roundToTwo"

export default function calculateRatio(
  gainOnTakeProfit: number | undefined,
  LossOnStopLoss: number | undefined
) {
  /**
   * @param  {number | undefined} gainOnTakeProfit Ganancia en Take Profit.
   * @param  {number | undefined} LossOnStopLoss Pérdida en Stop Loss.
   * @return {number | undefined} Ratio.
   *
   * Formula: Ganancia en TP / Perdida en SL
   *
   * Calcula el ratio
   */

  const roundCases = [1.97, 1.98, 1.99, 2.01]
  let ratio = 0
  if (gainOnTakeProfit && LossOnStopLoss) {
    ratio = roundToTwo(gainOnTakeProfit / LossOnStopLoss)
    if (roundCases.includes(ratio)) {
      ratio = 2
    }
  }
  return ratio
}
