import roundToTwo from "../../helpers/roundToTwo"

export default function calculateMarginLevel(marginVars: MarginVars): number {
  /**
   * @param  {number} equity Equidad.
   * @param  {number} depositAmount Monto de deposito.
   * @param  {number} withdrawalAmount Monto de rescate.
   * @param  {number} usedMargin Margen utilizado.
   * @return {number} Nivel de margen redondeado a 2 digitos.
   *
   * Formula: ((Equidad + deposito - rescate) / Margen usado)*100
   *
   * Calcula el nivel de margen y lo retorna redondeado.
   */
  const { equity, depositAmount, withdrawalAmount, usedMargin } = marginVars
  const marginLevel = roundToTwo(((equity + depositAmount - withdrawalAmount) / usedMargin) * 100)

  return marginLevel
}
