import styled from "@emotion/styled"

const Wrapper = styled.div`
  display: flex;
  margin-top: 15px;
`

const Container = styled.div`
  width: 1041px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 424px) {
    display: none;
  }
`

const ContainerLeft = styled.div`
  display: flex;
  background: #f9fafb;
  border-radius: 10px;
  width: 510px;
  flex-direction: column;
  padding-top: 58px;
  padding-bottom: 93px;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    max-width: 379px;
  }
`

const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  background: #f9fafb;
  border-radius: 10px;
  width: 510px;
  padding-top: 58px;
  padding-bottom: 93px;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    margin-top: 20px;
    max-width: 379px;
  }
`

const TitleContainer = styled.div`
  display: grid;
`

const Title = styled.div`
  font-weight: 500;
  color: #008060;
  font-size: 24px;
  line-height: 36px;
  font-family: "Roboto";
  letter-spacing: -0.28px;
  padding-bottom: 5px;
  flex: 0 0 510px;
  @media (max-width: 768px) {
    max-width: 379px;
    padding-top: 40px;
    padding-bottom: 14px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    flex: 0 0 379px;
    align-items: center;
    text-align: center;
  }
`

const SubText = styled.div`
  font-family: "Roboto";
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  padding-top: 42px;
  color: #637381;
  padding-left: 55px;
  padding-right: 55px;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

type props = {
  titleLeft: string
  titleRight: string
  textLeft: string
  textRight: string
  iconLeft: JSX.Element
  iconRight: JSX.Element
}

const CustomDataBox = ({
  titleLeft,
  titleRight,
  textLeft,
  textRight,
  iconLeft,
  iconRight,
}: props) => (
  <Wrapper>
    <Container>
      <TitleContainer>
        <Title>{titleLeft}</Title>
      </TitleContainer>
      <Title>{titleRight}</Title>
      <ContainerLeft>
        {iconLeft}
        <SubText>{textLeft}</SubText>
      </ContainerLeft>
      <ContainerRight>
        {iconRight}
        <SubText>{textRight}</SubText>
      </ContainerRight>
    </Container>
  </Wrapper>
)

export default CustomDataBox
