export default function isInputValid(inputValue: string, minLotSizeText: string) {
  const letters = /[a-zA-Z]/
  const startsWithSpecialChar = /^\W/
  const containsDots = /\./g
  const startsWithZeroAndNumber = /^0\d/
  //eslint-disable-next-line
  const containSpecialChars = /[^0-9\.]/g
  const startWithZero = /^0/
  const numberDot = /\d\./
  const moreThanTwoDecimals = /\.\d{3}/g
  const moreThanOneDecimals = /\.\d{2}/g

  if (inputValue.match(letters)) return false
  if (inputValue.match(startsWithSpecialChar)) return false
  if (inputValue.match(startsWithZeroAndNumber)) return false
  if (inputValue.match(containSpecialChars)) return false
  const inputContainsDots = inputValue.match(containsDots)
  if (inputContainsDots !== null && inputContainsDots.length > 1) return false

  if (minLotSizeText === "Volumen mínimo 1") {
    if (inputValue.match(startWithZero)) return false
    if (inputValue.match(numberDot)) return false
  }

  if (minLotSizeText === "Volumen mínimo 0.01" && inputValue.match(moreThanTwoDecimals)) {
    return false
  }
  if (minLotSizeText === "Volumen mínimo 0.1" && inputValue.match(moreThanOneDecimals)) {
    return false
  }

  return true
}
