export default function calculateAmountToWithdrawal(
  equity: number,
  depositAmount: number,
  usedMargin: number
) {
  /**
   * @param  {number} equity Equidad.
   * @param  {number} depositAmount Monto de deposito.
   * @param  {number} usedMargin Margen utilizado.
   * @constant {number} desiredMargin Margen deseado.
   * @return {number} Cantidad maxima a rescatar redondeada.
   *
   * Formula: Equidad + Deposito - (margen deseado * margen usado)
   *
   * Calcula la cantidad maxima a rescatar manteniendo un nivel de margen saludable (300%)
   */
  const desiredMargin = 3
  const amountToWithdraw = equity + depositAmount - usedMargin * desiredMargin

  return amountToWithdraw
}
