import { API, graphqlOperation } from "aws-amplify"

import { getInstrument } from "../../graphql/queries"

export default async function getInstrumentData(nemo: string) {
  try {
    const result: any = await API.graphql(
      graphqlOperation(getInstrument, {
        nemo,
      })
    )

    return result.data?.getInstrument
  } catch (e) {
    console.error(e)
    const error = "Error al cargar la información del instrumento."
    return error
  }
}
