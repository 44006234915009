import Autocomplete from "@mui/material/Autocomplete"
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete"
import helperTextNemo from "../helpers/helperTextNemo"
import InputAdornment from "@mui/material/InputAdornment"
import { returnedIcon } from "../helpers/returnedIcon"
import { ChangeEvent, useEffect } from "react"
import { TextFieldProps } from "@mui/material/TextField"
import { SxProps } from "@mui/system"
import { Theme } from "@emotion/react"
import { StyledComponent } from "@emotion/styled"
import getInstrumentName from "../helpers/getInstrumentName"
import { useContextInstrumentList } from "../../context/InstrumentListContext"

type props = {
  id: string
  CssTextField: StyledComponent<
    TextFieldProps & {
      theme?: Theme
    }
  >
  cssTextFieldSx: SxProps<Theme> | undefined
  setSelectedNemo: React.Dispatch<React.SetStateAction<string>>
  setNemoInput: React.Dispatch<React.SetStateAction<string>>
  nemoInput: string
  selectedNemo: string
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessage>>
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>
  errors?: string[]
  setErrors?: React.Dispatch<React.SetStateAction<string[]>>
}

const AutoCompleteNemoInput = ({
  id,
  CssTextField,
  cssTextFieldSx,
  setSelectedNemo,
  setNemoInput,
  nemoInput,
  selectedNemo,
  setErrorMessage,
  setHasErrors,
  errors,
  setErrors,
}: props) => {
  const { instrumentList, setInstrumentList } = useContextInstrumentList()
  const emptyfieldClassName = `${
    nemoInput === "" || nemoInput !== selectedNemo ? "empty-field" : undefined
  }`
  const inputLabelPropsNemo = {
    className: emptyfieldClassName,
  }

  const onChangeNemoInput = (event: any, title: string) => {
    setSelectedNemo(title)
    setNemoInput(title)
  }

  const onChangeInputText = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNemoInput(event.target.value)
    if (event.target.value === "") {
      setSelectedNemo(" ")
    }
    if (errors && setErrors) {
      if (nemoInput !== selectedNemo) {
        !errors.includes(event.target.name) && setErrors([...errors, event.target.name])
      }
    }
  }

  const textFieldInputProps = (params: AutocompleteRenderInputParams) => {
    const cssTextFieldInputProps = {
      ...params.InputProps,
      endAdornment: (
        <InputAdornment position="start">{returnedIcon(nemoInput, selectedNemo)}</InputAdornment>
      ),
      type: "search",
      className: emptyfieldClassName,
      name: "nemo",
    }
    return cssTextFieldInputProps
  }

  const loadInstrumentList = async () => {
    const result = await getInstrumentName()
    if (typeof result === "string") {
      setErrorMessage({ serverError: result })
      setHasErrors(true)
    } else {
      setInstrumentList(result)
      setHasErrors(false)
    }
  }

  useEffect(() => {
    if (instrumentList.length === 0) {
      loadInstrumentList()
    }
  }, [])

  useEffect(() => {
    if (nemoInput === selectedNemo) {
      if (errors && setErrors) {
        setErrors(errors.filter((error: string) => error !== "nemo"))
      }
    }
  }, [selectedNemo, nemoInput])

  return (
    <Autocomplete
      freeSolo
      id={id}
      disableClearable
      options={
        instrumentList.length > 0
          ? instrumentList.map((option: any) => option.name + " - " + option.nemo)
          : []
      }
      onChange={(event, value) => onChangeNemoInput(event, value)}
      renderInput={(params) => (
        <CssTextField
          {...params}
          label="Nombre o #Nemo"
          helperText={helperTextNemo(nemoInput, selectedNemo)}
          sx={cssTextFieldSx}
          InputProps={textFieldInputProps(params)}
          InputLabelProps={inputLabelPropsNemo}
          onChange={(event) => onChangeInputText(event)}
        />
      )}
      autoHighlight={true}
    />
  )
}

export default AutoCompleteNemoInput
