import styled from "styled-components"

const KnowMore = styled.div`
  display: unset;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #008060;
  cursor: pointer;

  &:hover {
    color: #2ba770;
  }
`

type props = {
  url: string
}

const CustomKnowMore = ({ url }: props) => {
  const clickKnowMore = () => {
    window.open(url, "_blank", "noopener")
  }
  return (
    <KnowMore onClick={clickKnowMore}>
      <u>Saber más.</u>
    </KnowMore>
  )
}

export default CustomKnowMore
