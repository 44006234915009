import { useEffect, useMemo, useState } from "react"
import styled from "@emotion/styled"

import "./index.css"
import CustomDataBox from "../shared/CustomDataBox"
import CustomHero from "../ui/CustomHero"
import DepositHere from "../shared/DepositHere"
import Footer from "../ui/Footer"
import SelectCurrency from "../shared/SelectCurrency"
import { dataBoxOptions } from "../helpers/dataBoxOptions"
import { heroOptions } from "../helpers/heroOptions"
import getUsdclpValue from "../helpers/getUsdclpValue"
import InformationAdvancedCalculator from "./InformationAdvancedCalculator"
import getUsdValueForWinLoseValue from "../helpers/getUsdValueForWinLoseValue"
import UpdateText from "../shared/UpdateText"
import { contextValues } from "../../context/ContextValues"
import getUsdValueForContractValue from "../helpers/getUsdValueForContractValue"
import ConfigureOperation from "./ConfigureOperation"

const ContainerCurrency = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 51px;
  @media (max-width: 560px) {
    flex-direction: column;
  }
`

const DepositContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 46px;
`

const AdvancedCalculator = () => {
  const [currency, setCurrency] = useState<string>("CLP")
  const [balance, setBalance] = useState<string | undefined>("")
  const [selectedNemo, setSelectedNemo] = useState<string>(" ")
  const [data, setData] = useState<InstrumentData | undefined>(undefined)
  const [previousData, setPreviousData] = useState<InstrumentData | undefined>(undefined)
  const [showSimulationInformation, setShowSimulationInformation] = useState<boolean>(false)
  const [clpValue, setClpValue] = useState<string>("")
  const [usdValueForWinLose, setUsdValueForWinLose] = useState<string>("")
  const [usdValueForContract, setUsdValueForContract] = useState<string>("")
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    serverError: undefined,
    onClickError: undefined,
  })
  const [hasErrors, setHasErrors] = useState<boolean>(false)

  const IconLeftRender: React.FunctionComponent<any> = dataBoxOptions().iconLeft
  const IconRightRender: React.FunctionComponent<any> = dataBoxOptions().iconRight
  const HeroImg: React.FunctionComponent<any> = heroOptions().heroImg
  const depositText = "¿Bajo en Balance?"

  const setSelectedCurrency = (selectedCurrency: string) => {
    setCurrency(selectedCurrency)
  }

  const loadClpValue = async () => {
    if (clpValue.length === 0) {
      const resultClp = await getUsdclpValue("Compra")
      if (resultClp !== undefined) {
        setClpValue(resultClp)
      }
    }
  }

  const loadUsdValues = async () => {
    if (data !== undefined) {
      // if (data.margin_currency === "USD" || data.base_currency === "USD") {
      //   return
      // }
      if (
        data.margin_currency === previousData?.margin_currency &&
        data.base_currency === previousData.base_currency
      ) {
        return
      }
      const resultUsd = await getUsdValueForWinLoseValue("Compra", data)
      const resultUsdContract = await getUsdValueForContractValue("Compra", data)

      setUsdValueForWinLose(resultUsd)
      setUsdValueForContract(resultUsdContract)
    }
  }

  useEffect(() => {
    loadClpValue()
  }, [])

  useEffect(() => {
    loadUsdValues()
  }, [data])

  useEffect(() => {
    setPreviousData(data)
  }, [selectedNemo])

  const value: ContextValues = useMemo(
    () => ({ usdValueForWinLose, usdValueForContract, clpValue }),
    [usdValueForWinLose, usdValueForContract, clpValue]
  )

  return (
    <contextValues.Provider value={value}>
      <>
        <CustomHero
          heroTitle={heroOptions().heroTitle}
          text={heroOptions().heroText}
          img={<HeroImg />}
        />
        <ContainerCurrency>
          <SelectCurrency setCurrency={setSelectedCurrency} />
        </ContainerCurrency>
        <ConfigureOperation
          {...{
            balance,
            setBalance,
            setSelectedNemo,
            selectedNemo,
            setErrorMessage,
            setHasErrors,
            hasErrors,
            setShowSimulationInformation,
            setData,
            currency,
          }}
        />
        <UpdateText
          data={data}
          errorMessage={errorMessage}
        />
        {showSimulationInformation ? (
          <InformationAdvancedCalculator
            data={data}
            currency={currency}
            hasErrors={hasErrors}
            selectedNemo={selectedNemo}
            balance={balance}
          />
        ) : (
          <CustomDataBox
            titleLeft={dataBoxOptions().titleLeft}
            titleRight={dataBoxOptions().titleRight}
            textLeft={dataBoxOptions().textLeft}
            textRight={dataBoxOptions().textRight}
            iconLeft={<IconLeftRender />}
            iconRight={<IconRightRender />}
          />
        )}

        <DepositContainer>
          <DepositHere text={depositText} />
        </DepositContainer>
        <Footer />
      </>
    </contextValues.Provider>
  )
}

export default AdvancedCalculator
