import winLoseMarginBaseCurrencyDisctincOfUsd from "./winLoseMarginBaseCurrencyDisctincOfUsd"
import winLoseMarginBaseCurrencyEqualAndDistinctOfUsd from "./winLoseMarginBaseCurrencyEqualAndDistinctOfUsd"
import winLoseMarginCurrencyUsd from "./winLoseMarginCurrencyUsd"
import winLoseUSDCLP from "./winLoseUSDCLP"

export default function getTakeProfitStopLossUsdValue(
  data: InstrumentData,
  values: ContextValues,
  takeProfitAmount: number,
  stopLossAmount: number
) {
  let lossByStopLoss: number | string = 0
  let winByTakeProfit: number | string = 0
  const { usdValueForWinLose, clpValue, usdValueForContract } = values
  const floatClpValue = parseFloat(clpValue)
  const floatUsdValue = parseFloat(usdValueForWinLose)
  const floatUsdValueEquivalent = parseFloat(usdValueForContract)

  if (data.margin_currency.trim() === "USD" && data.base_currency.trim() === "CLP") {
    ;({ winByTakeProfit, lossByStopLoss } = winLoseUSDCLP(
      takeProfitAmount,
      stopLossAmount,
      floatClpValue
    ))
  } else if (data.base_currency.trim() === "USD") {
    lossByStopLoss = stopLossAmount
    winByTakeProfit = takeProfitAmount
  } else if (data.margin_currency.trim() === "USD") {
    ;({ winByTakeProfit, lossByStopLoss } = winLoseMarginCurrencyUsd(
      takeProfitAmount,
      stopLossAmount,
      floatUsdValue
    ))
  } else if (data.base_currency.trim() !== "USD" && data.margin_currency.trim() !== "USD") {
    const floatUsdValue = parseFloat(usdValueForWinLose)
    ;({ winByTakeProfit, lossByStopLoss } = winLoseMarginBaseCurrencyDisctincOfUsd(
      takeProfitAmount,
      stopLossAmount,
      floatUsdValue,
      floatUsdValueEquivalent,
      data
    ))
    if (data.base_currency.trim() === data.margin_currency.trim()) {
      ;({ winByTakeProfit, lossByStopLoss } = winLoseMarginBaseCurrencyEqualAndDistinctOfUsd(
        takeProfitAmount,
        stopLossAmount,
        floatUsdValue
      ))
    }
  }

  if (Object.is(lossByStopLoss, NaN) || Object.is(winByTakeProfit, NaN)) {
    return { winByTakeProfit, lossByStopLoss }
  }

  winByTakeProfit = Intl.NumberFormat("en-US").format(winByTakeProfit)
  lossByStopLoss = Intl.NumberFormat("en-US").format(lossByStopLoss)

  return { winByTakeProfit, lossByStopLoss }
}
