import styled from "styled-components"

const Span = styled.span`
  display: block;
`

type Props = {
  spreadTypes: SpreadTypes
  data: InstrumentData
}

const SpreadStringText = ({ spreadTypes, data }: Props) => {
  const { spread, proSpread, premiumSpread } = spreadTypes
  const { account_type } = data
  const spreadText: SpreadStringText = {
    start: {
      component: (
        <p>
          <span>
            <strong>Spread START:</strong>
          </span>
          <Span>{spread} (xCFD)</Span>
        </p>
      ),
    },
    pro: {
      component: (
        <>
          <p>
            <span>
              <strong>Spread PRO:</strong>
            </span>
            <Span>{proSpread} (xCFD)</Span>
          </p>
          <p>
            <span>
              <strong>Spread Premium:</strong>
            </span>
            <Span>{premiumSpread} (xCFD)</Span>
          </p>
        </>
      ),
    },
  }

  return spreadText[account_type as keyof SpreadStringText].component
}

export default SpreadStringText
