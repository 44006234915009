import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import Tooltip, { TooltipProps } from "@mui/material/Tooltip"
import { SxProps } from "@mui/system"
import { Theme } from "@mui/material/styles"
import { CommonProps } from "@mui/material/OverridableComponent"

type Props = {
  title: string
  placement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
  iconProps?: SxProps<Theme>
  componentsProps?: TooltipProps["componentsProps"]
  className?: TooltipProps["classes"]
  iconClass?: CommonProps["className"]
}

const CustomToolTip = ({
  title,
  placement,
  iconProps,
  componentsProps,
  className,
  iconClass,
}: Props) => (
  <Tooltip
    title={title}
    placement={placement}
    arrow={true}
    componentsProps={componentsProps}
    classes={className}
  >
    <HelpOutlineOutlinedIcon
      sx={iconProps}
      className={iconClass}
    />
  </Tooltip>
)

export default CustomToolTip
