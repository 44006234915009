export default function returnedSpreadTexts(spreadTypes: SpreadTypes, totalSpreads: TotalSpreads) {
  let startSpreadText = spreadTypes.spread
  let proSpreadText = spreadTypes.proSpread
  let premiumSpreadText = spreadTypes.premiumSpread

  if (spreadTypes.proSpread !== "Flotante" && spreadTypes.proSpread !== "Solo Cierre") {
    proSpreadText = `$${totalSpreads.totalProSpread} USD = $${totalSpreads.totalProSpreadClp} CLP`
    premiumSpreadText = `$${totalSpreads.totalPremiumSpread} USD = $${totalSpreads.totalPremiumSpreadClp} CLP`
  }
  if (spreadTypes.spreadClp !== "Flotante" && spreadTypes.spreadClp !== "Solo Cierre") {
    startSpreadText = `$${totalSpreads.totalSpread} USD = $${totalSpreads.totalSpreadClp} CLP`
  }

  return { startSpreadText, proSpreadText, premiumSpreadText }
}
