import styled from "styled-components"

const Span = styled.span`
  display: block;
`

type Props = {
  spreadTypes: SpreadTypes
  data: InstrumentData
}

const SpreadNumberText = ({ spreadTypes, data }: Props) => {
  const { spread, spreadClp, proSpread, proSpreadClp, premiumSpread, premiumSpreadClp } =
    spreadTypes
  const { account_type } = data
  return (
    <>
      {account_type === "start" ? (
        <p>
          <span>
            <strong>Spread START:</strong>
          </span>
          <Span>
            ${spread} USD / ${spreadClp} CLP (xCFD)
          </Span>
        </p>
      ) : (
        <>
          <p>
            <span>
              <strong>Spread PRO:</strong>
            </span>
            <Span>
              ${proSpread} USD / ${proSpreadClp} CLP (xCFD)
            </Span>
          </p>
          <p>
            <span>
              <strong>Spread Premium:</strong>
            </span>
            <Span>
              ${premiumSpread} USD = ${premiumSpreadClp} CLP (xCFD)
            </Span>
          </p>
        </>
      )}
    </>
  )
}

export default SpreadNumberText
