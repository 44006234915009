import getTakeProfitStopLossUsdValue from "./getTakeProfitStopLossUsdValue"

export default function getTakeProfitStopLossClpValue(
  data: InstrumentData,
  values: ContextValues,
  takeProfitAmount: number,
  stopLossAmount: number
) {
  let lossByStopLoss: number | string = 0
  let winByTakeProfit: number | string = 0
  const { clpValue } = values
  const floatClpValue = parseFloat(clpValue)

  ;({ winByTakeProfit, lossByStopLoss } = getTakeProfitStopLossUsdValue(
    data,
    values,
    takeProfitAmount,
    stopLossAmount
  ))
  const floatWinByTakeProfit = parseFloat(String(winByTakeProfit).replace(/,/g, ""))
  const floatLossByStopLoss = parseFloat(String(lossByStopLoss).replace(/,/g, ""))

  winByTakeProfit = Math.round(floatWinByTakeProfit * floatClpValue)
  lossByStopLoss = Math.round(floatLossByStopLoss * floatClpValue)

  if (data.margin_currency === "USD" && data.base_currency === "CLP") {
    winByTakeProfit = floatWinByTakeProfit * floatClpValue
    lossByStopLoss = floatLossByStopLoss * floatClpValue
  }

  if (Object.is(lossByStopLoss, NaN) || Object.is(winByTakeProfit, NaN)) {
    return { winByTakeProfit, lossByStopLoss }
  }

  winByTakeProfit = Intl.NumberFormat("en-US").format(winByTakeProfit)
  lossByStopLoss = Intl.NumberFormat("en-US").format(lossByStopLoss)

  return { winByTakeProfit, lossByStopLoss }
}
